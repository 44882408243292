import { useNavigate } from "react-router-dom";
import "./RoleChanger.scss";
import { useTranslation } from "react-i18next";
type RoleChangerProps = {
  isStaff?: boolean;
  onRoleChange?: (role: "STAFF" | "CLIENT") => void;
};

const RoleChanger = ({ isStaff, onRoleChange }: RoleChangerProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const translate = (key: string) => t(`translations:userPage.${key}`);
  return (
    <div className="role-changer">
      <div
        className={`choice waves-effect waves-dark ${
          isStaff ? "" : "nonActive"
        }`}
        onClick={() => {
          if (onRoleChange) {
            onRoleChange("STAFF");
          } else {
            navigate("/anvandare/personal");
          }
        }}
      >
        {translate("navigationStaff")}
      </div>
      <div
        className={`choice waves-effect waves-dark ${
          !isStaff ? "" : "nonActive"
        }`}
        onClick={() => {
          if (onRoleChange) {
            onRoleChange("CLIENT");
          } else {
            navigate("/anvandare/kunder");
          }
        }}
      >
        {translate("navigationClient")}
      </div>
    </div>
  );
};

export default RoleChanger;
