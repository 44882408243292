import { FaWindowClose } from "react-icons/fa";
const ModalContainer = ({
  customStyles = "",
  onClose,
  children,
}: any): JSX.Element => (
  <div className="w-full h-full bg-[#00000090] fixed top-0 left-0 z-50">
    <div
      className={`card-container fixed w-[40%]
     flex flex-col items-center p-8 shadow-lg 
     translate-x-[-50%] translate-y-[-50%] 
     top-[50%] left-[50%] ${customStyles}`}
    >
      <FaWindowClose
        className="self-end hover:cursor-pointer fixed"
        onClick={onClose}
      />
      {children}
    </div>
  </div>
);

export default ModalContainer;
