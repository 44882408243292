import { Outlet } from "react-router-dom";

import NavigationItems from "./NavigationItems";

const GeneralSettings = (): JSX.Element => {
  return (
    <div className="flex gap-4 mt-8 w-auto">
      <NavigationItems />
      <Outlet />
    </div>
  );
};

export default GeneralSettings;
