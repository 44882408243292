/* eslint-disable react-hooks/exhaustive-deps */
import { connect } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import axios from "../../axios";
import { IUser, IUserAllergen, IUserLanguage } from "../../types/user";
import "./SettingsPage.scss";
import { ReduxState } from "../../shared/Types";
import UserSelector from "../../components/UserSelector/UserSelector";
import StaffSettings from "../StaffSettings/StaffSettings";
import ClientSettings from "../ClientSettings/ClientSettings";
import { createUser, getAllUsers } from "../../api/user";
import { IOrganizationUser } from "../../types/organization";
import AddUser from "../../components/Settings/AddUser/AddUser";
import Popup from "../../hoc/Popup/Popup";
import { useNavigate } from "react-router-dom";

type SettingsPageProps = {
  token: string | null;
  organizationId: string;
};

const SettingsPage = ({ token, organizationId }: SettingsPageProps) => {
  //states reloaded on shouldReloadData
  const [users, setUsers] = useState<IOrganizationUser[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<IOrganizationUser[]>([]);
  const [selectedUser, setSelectedUser] = useState<IUser | null>(null);
  const isStaff = !window.location.href.includes("/anvandare/kunder");
  const [selectedUserId, setSelectedUserId] = useState("");
  const [allergens, setAllergens] = useState<IUserAllergen[]>([]);
  const [languages, setLanguages] = useState<IUserLanguage[]>([]);
  const [shouldReloadData, setShouldReloadData] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [hasDeleted, setHasDeleted] = useState(false);
  const [hasAddError, setHasAddError] = useState(false);

  const navigate = useNavigate();

  const search = useCallback(
    (searchQuery: string) => {
      if (users.length === 0) return;
      setFilteredUsers(
        searchQuery
          ? users.filter(
              (user) =>
                user.user.firstName
                  .toLowerCase()
                  .startsWith(searchQuery.toLowerCase()) ||
                user.user.lastName
                  .toLowerCase()
                  .startsWith(searchQuery.toLowerCase())
            )
          : users
      );
    },
    [users]
  );

  useEffect(() => {
    const userId = window.location.search?.split("?=")[1];
    getAllUsers(organizationId).then((res) => {
      setUsers(res.data);
      setFilteredUsers(res.data);
      if (userId) {
        setSelectedUserId(userId);
      } else {
        setSelectedUserId(
          res.data.filter((u) => u.role === (isStaff ? "STAFF" : "CLIENT"))[0]
            .user.id
        );
      }
    });
    axios
      .get<IUserAllergen[]>("allergen")
      .then((res) => setAllergens(res.data));
    axios
      .get<IUserLanguage[]>("language")
      .then((res) => setLanguages(res.data));
  }, []);

  useEffect(() => {
    if (!selectedUserId) {
      setSelectedUser(null);
      return;
    }
    const params = new URLSearchParams();
    params.append("populate", "details");
    params.append("populate", "restrictions");
    axios
      .get<IUser>(`user/${selectedUserId}`, {
        params: params,
      })
      .then((res) => {
        setSelectedUser(res.data);
      });
    navigate({
      pathname: window.location.pathname,
      search: `?selectedUserId?=${selectedUserId}`,
    });
  }, [selectedUserId]);

  useEffect(() => {
    if (users.length === 0) return;
    if (hasDeleted) {
      const filteredUserResults = users.filter(
        (u) => u.role === (isStaff ? "STAFF" : "CLIENT")
      );
      if (!filteredUserResults.length) return;
      setSelectedUserId(filteredUserResults[0].user.id);
      setHasDeleted(false);
    }
  }, [users]);

  useEffect(() => {
    if (!users.length) return;
    const filteredUserResults = users.filter(
      (u) => u.role === (isStaff ? "STAFF" : "CLIENT")
    );
    if (!filteredUserResults.length) return;
    setSelectedUserId(filteredUserResults[0].user.id);
  }, [isStaff]);

  useEffect(() => {
    if (!shouldReloadData) {
      return;
    }
    getAllUsers(organizationId).then((res) => {
      setUsers(res.data);
      setShouldReloadData(false);
    });
  }, [shouldReloadData]);

  const addUserHandler = (
    firstName: string,
    lastName: string,
    email: string,
    phonenNumber: string,
    gender: string,
    address: string,
    postalcode: string
  ) => {
    createUser(
      firstName,
      lastName,
      email,
      phonenNumber,
      gender,
      address,
      postalcode,
      organizationId,
      isStaff
    )
      .then(() => {
        setIsAdding(false);
        setShouldReloadData(true);
        setHasAddError(false);
      })
      .catch(() => setHasAddError(true));
  };

  return (
    <div className="settings-page">
      <UserSelector
        users={filteredUsers}
        isStaff={isStaff}
        selectedUserId={selectedUserId}
        onSelectUser={(s) => setSelectedUserId(s)}
        onPopupShow={(shouldShow) => setIsAdding(shouldShow)}
        search={search}
      />
      <Popup showPopup={isAdding} onClose={() => setIsAdding(false)}>
        <AddUser onAdd={addUserHandler} addError={hasAddError} />
      </Popup>
      {isStaff
        ? selectedUser && (
            <StaffSettings
              staff={selectedUser}
              setStaff={setSelectedUser}
              allergies={allergens}
              languages={languages}
              onSave={() => {
                setShouldReloadData(true);
              }}
              onDelete={() => {
                setHasDeleted(true);
                setShouldReloadData(true);
              }}
              organizationId={organizationId}
              staffGroups={(function () {
                const user = users.find((u) => u.user.id === selectedUserId);
                if (user && user.group && user.group) {
                  return user.group;
                } else {
                  return null;
                }
              })()}
            />
          )
        : selectedUser && (
            <ClientSettings
              client={selectedUser}
              setClient={setSelectedUser}
              staffs={users.filter((user) => user.role === "STAFF")}
              allergies={allergens}
              languages={languages}
              onSave={() => {
                setShouldReloadData(true);
              }}
              onDelete={() => {
                setHasDeleted(true);
                setShouldReloadData(true);
              }}
              organizationId={organizationId}
              clientGroup={(function () {
                const user = users.find((u) => u.user.id === selectedUserId);
                if (user && user.group && user.group[0]) {
                  return user.group;
                } else {
                  return null;
                }
              })()}
            />
          )}
    </div>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  token: state.auth.token,
  organizationId: state.auth.organizationId,
});

export default connect(mapStateToProps)(SettingsPage);
