import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import {
  deleteWorkTimeById,
  getWorkTimesByUserId,
} from "../../../../api/organization";
import Popup from "../../../../hoc/Popup/Popup";
import { ReduxState } from "../../../../shared/Types";
import { useTranslatedDates } from "../../../../shared/utility";
import { IWorkTime } from "../../../../types/organization";
import AddWorkTime from "./AddWorkTime/AddWorkTime";
import WorkTime from "./WorkTime/WorkTime";
import "./WorkTimeSettings.scss";

type WorkTimeSettingsProps = {
  userId: string;
  isWorkShift?: boolean;
  organizationId: string;
};

const WorkTimeSettings = (props: WorkTimeSettingsProps) => {
  const { days } = useTranslatedDates();

  const [showAddPopup, setShowAddPopup] = useState(false);
  const [chosenDay, setChosen] = useState("");
  const [workTimes, setWorkTimes] = useState<IWorkTime[]>([]);
  const [editingWorkTime, setEditingWorkTime] = useState<IWorkTime | null>(
    null
  );

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getWorkTimesByUserId(props.userId, props.organizationId).then((res) => {
      setWorkTimes(
        res.data.filter(
          (wt) => wt.type === (props.isWorkShift ? "WORK_SHIFT" : "BREAK")
        )
      );
    });
  }, [props.isWorkShift, props.organizationId, props.userId]);

  const addWorkTime = (day: string) => {
    setChosen(day);
    setShowAddPopup(true);
  };

  const editWorkTime = (day: string, wt: IWorkTime) => {
    setChosen(day);
    setEditingWorkTime(wt);
    setShowAddPopup(true);
  };

  const deleteWorkTime = (deleteId: string) => {
    if (window.confirm("Vill du verkligen ta bort denna tid?")) {
      deleteWorkTimeById(props.userId, deleteId, props.organizationId).then(
        () => {
          getWorkTimesByUserId(props.userId, props.organizationId)
            .then((res) => {
              enqueueSnackbar("Tid borttagen.", { variant: "success" });
              setWorkTimes(
                res.data.filter(
                  (wt) =>
                    wt.type === (props.isWorkShift ? "WORK_SHIFT" : "BREAK")
                )
              );
            })
            .catch(() => {
              enqueueSnackbar("Tidsborttagning misslyckades", {
                variant: "error",
              });
            });
        }
      );
    }
  };

  return (
    <>
      <div className="work-time-settings">
        {days.map((day) => (
          <WorkTime
            key={day.value}
            day={day.label}
            workTimes={workTimes.filter(
              (wt) => wt.timeSpan.weekday === day.value
            )}
            onAdd={() => addWorkTime(day.value)}
            onEdit={(wt) => editWorkTime(day.value, wt)}
            onRemove={deleteWorkTime}
          />
        ))}
      </div>
      <Popup
        showPopup={showAddPopup}
        onClose={() => {
          setShowAddPopup(false);
          setEditingWorkTime(null);
        }}
        overflow="unset"
      >
        <AddWorkTime
          editWorkTime={editingWorkTime}
          isEditing={!!editingWorkTime}
          onEditSave={() => {
            setShowAddPopup(false);
            setEditingWorkTime(null);
            getWorkTimesByUserId(props.userId, props.organizationId).then(
              (res) =>
                setWorkTimes(
                  res.data.filter(
                    (wt) =>
                      wt.type === (props.isWorkShift ? "WORK_SHIFT" : "BREAK")
                  )
                )
            );
          }}
          day={chosenDay}
          userId={props.userId}
          onSaved={() => {
            setShowAddPopup(false);
            getWorkTimesByUserId(props.userId, props.organizationId).then(
              (res) =>
                setWorkTimes(
                  res.data.filter(
                    (wt) =>
                      wt.type === (props.isWorkShift ? "WORK_SHIFT" : "BREAK")
                  )
                )
            );
          }}
          isWorkShift={props.isWorkShift}
        />
      </Popup>
    </>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  organizationId: state.auth.organizationId,
});

export default connect(mapStateToProps)(WorkTimeSettings);
