import { NavigationGroup } from "./types";
import { useTranslation } from "react-i18next";
import { ROUTES } from "../../../constants/settings";

const useNavConfig = () => {
  const { t } = useTranslation();
  const translate = (key: string) => t(`translations:settingsPage.${key}`);

  const navigationConfig: NavigationGroup[] = [
    {
      title: translate("schedule"),
      routes: [
        {
          title: translate("howDoIUse"),
          to: ROUTES.settings.scheduleHowToUse,
        },
        {
          title: translate("generatorSettings"),
          to: ROUTES.settings.schedulePreSettings,
        },
      ],
    },
    {
      title: translate("statistics"),
      routes: [
        {
          title: translate("howDoIUse"),
          to: ROUTES.settings.statisticsHowToUse,
        },
        {
          title: translate("handlingOfYourData"),
          to: ROUTES.settings.statisticsHandlingYourData,
        },
      ],
    },
    {
      title: translate("users"),
      routes: [
        {
          title: translate("howDoIUse"),
          to: ROUTES.settings.usersHowToUse,
        },
        {
          title: translate("groups"),
          to: ROUTES.settings.usersGroups,
        },
        {
          title: translate("eventType"),
          to: ROUTES.settings.usersEventType,
        },
      ],
    },
    {
      title: translate("myAccount"),
      routes: [
        {
          title: translate("mySettings"),
          to: ROUTES.settings.myAccountSettings,
        },
      ],
    },
    {
      title: translate("yourCompany"),
      routes: [
        {
          title: translate("adminAccount"),
          to: ROUTES.settings.ourCompanyAdminAccounts,
        },
        {
          title: translate("yourPrenumeration"),
          to: ROUTES.settings.ourCompanyPrenumertaion,
        },
        {
          title: translate("customization"),
          to: ROUTES.settings.ourCompanyCostumization,
        },
      ],
    },
  ];

  return navigationConfig;
};

export default useNavConfig;
