import CreatableSelect from "react-select/creatable";

const DropDownCreate = ({
  onCreateOption,
  styles = {},
  ...rest
}: SelectProps & DefaultFieldProps): JSX.Element => (
  <CreatableSelect
    styles={styles}
    onCreateOption={(value) => onCreateOption(value)}
    {...rest}
  />
);

export default DropDownCreate;
