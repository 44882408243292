import "./Input.scss";

type InputProps = {
  width?: string;
  placeHolder?: string;
  label?: string;
  errorMessage?: string;
  invalid?: boolean;
  disabled?: boolean;
  shouldValidate?: boolean;
  lightLabel?: boolean;
  className?: string;
  value?: string | number;
  defaultValue?: string | number;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  type: "text" | "email" | "numeric" | "date" | "password" | "time" | "number";
  textCentered?: boolean;
  white?: boolean;
  min?: number;
  max?: number;
  autoComplete?: string;
  style?: React.CSSProperties | undefined;
};

const Input = (props: InputProps) => {
  const inputClasses = ["input"];
  if (props.invalid && props.shouldValidate) {
    inputClasses.push("invalid");
  }
  const inputWrapperClasses = ["input-wrapper"];
  if (props.className) {
    inputWrapperClasses.push(props.className);
  }
  if (props.textCentered) {
    inputClasses.push("centered-text");
  }
  if (props.white) {
    inputClasses.push("white");
  }

  let inputElement = null;
  switch (props.type) {
    case "text":
      inputElement = (
        <input
          type="text"
          className={inputClasses.join(" ")}
          placeholder={props.placeHolder}
          disabled={props.disabled}
          onChange={props.onChange}
          value={props.value}
          autoComplete={props.autoComplete}
        />
      );
      break;
    case "email":
      inputElement = (
        <input
          type="email"
          className={inputClasses.join(" ")}
          placeholder={props.placeHolder}
          disabled={props.disabled}
          onChange={props.onChange}
          value={props.value}
          autoComplete={props.autoComplete}
        />
      );
      break;
    case "numeric":
      inputElement = (
        <input
          type="tel"
          className={inputClasses.join(" ")}
          placeholder={props.placeHolder}
          disabled={props.disabled}
          onChange={props.onChange}
          value={props.value}
          autoComplete={props.autoComplete}
        />
      );
      break;
    case "date":
      inputElement = (
        <input
          type="date"
          className={inputClasses.join(" ")}
          placeholder={props.placeHolder}
          disabled={props.disabled}
          onChange={props.onChange}
          value={props.value}
          autoComplete={props.autoComplete}
        />
      );
      break;
    case "password":
      inputElement = (
        <input
          type="password"
          className={inputClasses.join(" ")}
          placeholder={props.placeHolder}
          disabled={props.disabled}
          onChange={props.onChange}
          value={props.value}
          autoComplete={props.autoComplete}
        />
      );
      break;
    case "time":
      inputElement = (
        <input
          type="time"
          defaultValue={props.defaultValue}
          className={inputClasses.join(" ")}
          placeholder={props.placeHolder}
          disabled={props.disabled}
          onChange={props.onChange}
          value={props.value}
          autoComplete={props.autoComplete}
        />
      );
      break;
    case "number":
      inputElement = (
        <input
          type="number"
          className={inputClasses.join(" ")}
          placeholder={props.placeHolder}
          disabled={props.disabled}
          onChange={props.onChange}
          value={props.value}
          min={props.min}
          max={props.max}
          autoComplete={props.autoComplete}
        />
      );
      break;
  }

  return (
    <div
      className="input-wrapper"
      style={{ ...props.style, width: props.width }}
    >
      {props.label && (
        <div
          className="input-label"
          style={{
            color: props.lightLabel ? "white" : "black",
            display: props.textCentered ? "block" : "flex",
            textAlign: props.textCentered ? "center" : "start",
            marginLeft: props.textCentered ? "0" : "15px",
          }}
        >
          {props.label}
          <div className="input-label--error-message">
            {props.invalid && props.shouldValidate && props.errorMessage}
          </div>
        </div>
      )}
      {inputElement}
    </div>
  );
};

export default Input;
