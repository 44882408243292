import { useState } from "react";
import { GrFormPrevious, GrFormNext } from "react-icons/gr";
import { toIsoString } from "../../../shared/utility";

import Spinner from "../NewSpinner";

type TimeSelectorProps = {
  //setTimeRange component is used when Timeselector is used as a standalone component, and will have it's own state and return the selected dates
  setTimerange?: (from: string, to: string) => void;
  constainerCustomStyles?: string;
  loading?: boolean;
  //if the react big calendar props are received, this component will serve as a custom toolbar for the react calendar and will not perform any date calculation by itself
  label?: () => JSX.Element;
  onBack?: () => void;
  onNext?: () => void;
  onToday?: () => void;
  onExactDate?: (date: Date) => void;
  onDailyView?: () => void;
  onWeeklyView?: () => void;
  onMonthlyView?: () => void;
  onAgendaView?: () => void;
};

const TimeSelector = ({
  setTimerange,
  loading,
  constainerCustomStyles = "",
  ...props
}: TimeSelectorProps): JSX.Element => {
  const selections = ["Monthly", "Weekly", "Daily", "Agenda"] as const;
  type SelectionsType = typeof selections[number];

  const [selectionType, setSelectionType] = useState<SelectionsType>("Daily");
  const [date, setDate] = useState(new Date());

  const getFormattedDate = () => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const month = monthNames[date.getMonth()];
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    switch (selectionType) {
      case "Monthly":
        return `${month} ${year}`;
      case "Weekly":
        let currentWeekDay = date.getDay();
        let daysDifference = (currentWeekDay = 0 ? 6 : currentWeekDay - 1);
        let fromDate = new Date(
          new Date(date).setDate(date.getDate() - daysDifference)
        );
        let toDate = new Date(
          new Date(fromDate).setDate(fromDate.getDate() + 6)
        );
        const m1 = monthNames[fromDate.getMonth()];
        const d1 = String(fromDate.getDate()).padStart(2, "0");
        const m2 = monthNames[toDate.getMonth()];
        const d2 = String(toDate.getDate()).padStart(2, "0");
        return `${m1} ${d1} - ${m2} ${d2}`;
      case "Daily":
        return `${month} ${day} ${year}`;
      case "Agenda":
        return "Coming soon";
      default:
        return `${month} ${day} ${year}`;
    }
  };

  const renderSelectionTypes = () => (
    <div className="flex flex-row flex-1 items-center relative">
      {selections.map((selection) => (
        <div
          onClick={() => {
            setSelectionType(selection);
            switch (selection) {
              case "Daily":
                if (props.onDailyView) {
                  props.onDailyView();
                  return;
                }
                const { fromDate: dailyFrom, toDate: dailyTo } =
                  calculateDailyDates(date);
                setTimerange &&
                  setTimerange(formatDate(dailyFrom), formatDate(dailyTo));
                break;
              case "Monthly":
                if (props.onMonthlyView) {
                  props.onMonthlyView();
                  return;
                }
                const { fromDate: monthlyFrom, toDate: monthlyto } =
                  calculateMonthlyDates(date);
                setTimerange &&
                  setTimerange(formatDate(monthlyFrom), formatDate(monthlyto));
                break;
              case "Weekly":
                //only trigger the calendar change, don't do any date calculation
                if (props?.onWeeklyView) {
                  props.onWeeklyView();
                  return;
                }
                const { fromDate: weeklyFrom, toDate: weeklyto } =
                  calculateWeeklyDates(date);
                setTimerange &&
                  setTimerange(formatDate(weeklyFrom), formatDate(weeklyto));
                break;
              case "Agenda":
                if (props?.onAgendaView) {
                  props.onAgendaView();
                  return;
                }
                break;

              default:
                break;
            }
          }}
          className={`bold-text flex-1 hover:cursor-pointer text-center`}
          key={selection}
        >
          {selection}
        </div>
      ))}
      <div
        className={`absolute top-8 left-0 h-1 w-[25%] transform transition-all duration-200 ease-in bg-mint ${(function setIndicatorPosition() {
          switch (selectionType) {
            case "Monthly":
              return "left-0";
            case "Weekly":
              return "left-[25%]";
            case "Daily":
              return "left-[50%]";
            case "Agenda":
              return "left-[76%]";
            default:
              return "left-0";
          }
        })()}`}
      />
    </div>
  );

  const formatDate = (dt: Date) => {
    const fullIsoStringDate = toIsoString(dt);
    const formattedDate = fullIsoStringDate.split("T")[0];
    return formattedDate;
  };

  const calculateDailyDates = (currentDate: Date) => {
    let fromDate = new Date(currentDate.toDateString());
    let toDate = new Date(currentDate.toDateString());
    return {
      fromDate,
      toDate,
    };
  };

  const calculateMonthlyDates = (currentDate: Date) => {
    let fromDate = new Date(
      currentDate.getUTCFullYear(),
      currentDate.getUTCMonth(),
      1
    );

    let toDate = new Date(
      currentDate.getUTCFullYear(),
      currentDate.getUTCMonth() + 1,
      0
    );

    if (fromDate.getUTCMonth() !== toDate.getUTCMonth()) {
      fromDate.setUTCDate(fromDate.getUTCDate() + 1);
      toDate.setUTCDate(toDate.getUTCDate() + 1);
    }
    return {
      fromDate,
      toDate,
    };
  };

  const calculateWeeklyDates = (currentDate: Date) => {
    let currentWeekDay = currentDate.getUTCDay();
    let daysDifference = currentWeekDay === 0 ? 6 : currentWeekDay - 1;
    let fromDate = new Date(
      new Date(currentDate).setDate(currentDate.getDate() - daysDifference)
    );
    let toDate = new Date(new Date(fromDate).setDate(fromDate.getDate() + 6));
    return {
      fromDate,
      toDate,
    };
  };

  const onDateChanged = (direction: "prev" | "next") => {
    if (props.onNext && props.onBack) {
      direction === "prev" && props.onBack();
      direction === "next" && props.onNext();
      return;
    }
    let newDate = new Date(date.toDateString());
    let from = new Date(date.toDateString());
    let to = new Date(date.toDateString());
    switch (selectionType) {
      case "Monthly":
        if (direction === "next") {
          newDate.setMonth(newDate.getMonth() + 1);
        } else {
          newDate.setMonth(newDate.getMonth() - 1);
        }
        const { fromDate: monthlyFrom, toDate: monthlyto } =
          calculateMonthlyDates(newDate);
        from = monthlyFrom;
        to = monthlyto;
        break;
      case "Weekly":
        if (direction === "next") {
          newDate.setDate(newDate.getDate() + 7);
        } else {
          newDate.setDate(newDate.getDate() - 7);
        }
        const { fromDate: weeklyFrom, toDate: weeklyTo } =
          calculateWeeklyDates(newDate);
        from = weeklyFrom;
        to = weeklyTo;
        break;
      case "Daily":
        if (direction === "next") {
          newDate.setDate(newDate.getDate() + 1);
        } else {
          newDate.setDate(newDate.getDate() - 1);
        }
        const { fromDate: dailyFrom, toDate: dailyTo } =
          calculateDailyDates(newDate);
        from = dailyFrom;
        to = dailyTo;
        break;
      case "Agenda":
        return;
      default:
        break;
    }
    setDate(() => newDate);
    setTimerange && setTimerange(formatDate(from), formatDate(to));
  };

  const onExactDateSelected = (dateStr: string) => {
    const selectedDate = new Date(dateStr);
    setSelectionType("Daily");
    if (props.onExactDate) {
      props.onExactDate(selectedDate);
      return;
    }
    setTimerange &&
      setTimerange(formatDate(selectedDate), formatDate(selectedDate));
    setDate(selectedDate);
  };

  return (
    <div
      className={`flex flex-row justify-between w-full ${constainerCustomStyles}`}
    >
      <div className="flex flex-row self-start flex-1 items-center gap-4">
        <div
          onClick={() => {
            if (props.onToday) {
              props.onToday();
              return;
            }
            const today = new Date();
            setDate(() => today);
            setSelectionType("Daily");
            setTimerange && setTimerange(formatDate(today), formatDate(today));
          }}
          className="text-sm font-medium py-2 px-3 rounded-2xl bg-grey hover:cursor-pointer z-10"
        >
          Today
        </div>
        <span className="flex gap-2 bg-white z-10">
          <button
            disabled={loading}
            onClick={() => onDateChanged("prev")}
            className="w-6 h-6 rounded-xl bg-grey flex justify-center items-center disabled:cursor-default"
          >
            <GrFormPrevious size={20} />
          </button>
          <button
            disabled={loading}
            onClick={() => onDateChanged("next")}
            className="w-6 h-6 rounded-xl bg-grey flex justify-center items-center disabled:cursor-default"
          >
            <GrFormNext size={20} />
          </button>
        </span>
        <input
          className="outline-none border-none bg-transparent text-[transparent] ml-[-150px] z-0"
          type="date"
          onChange={(e) => onExactDateSelected(e.target.value)}
        />
      </div>
      <div className="text-base self-center flex-1 font-medium justify-center">
        {loading ? (
          <div className="flex flex-row">
            <Spinner /> <>Loading</>
          </div>
        ) : props.label ? (
          props.label()
        ) : (
          getFormattedDate()
        )}
      </div>

      {renderSelectionTypes()}
    </div>
  );
};

export default TimeSelector;
