import { DateTime } from "luxon";
import Input from "../../../../../UI/Input/Input";
import "./WorkTimeInputs.scss";

type WorkTimeInputsProps = {
  isPartTime: boolean;
  onChange: (times: { start: string; end: string; max: number }) => void;
  startTime: string;
  endTime: string;
  max: number;
  isWorkShift?: boolean;
};

const WorkTimeInputs = (props: WorkTimeInputsProps) => {
  const inputChangedHandler = (
    inputType: "start" | "end" | "max",
    value: string
  ) => {
    switch (inputType) {
      case "start":
        props.onChange({
          start: value,
          end: props.endTime.slice(0, 5),
          max: props.max / 60,
        });
        break;
      case "end":
        props.onChange({
          start: props.startTime.slice(0, 5),
          end: value,
          max: props.max / 60,
        });
        break;
      case "max":
        props.onChange({
          start: props.startTime.slice(0, 5),
          end: props.endTime.slice(0, 5),
          max: +value,
        });
        break;
    }
  };

  return (
    <div className="work-time-inputs">
      <Input
        type="time"
        label={props.isWorkShift ? "Start arbetspass" : "Start rast"}
        defaultValue={props.startTime.slice(0, 5)}
        onChange={(e) => inputChangedHandler("start", e.target.value)}
        textCentered
      />
      <Input
        type="time"
        label={props.isWorkShift ? "Slut arbetspass" : "Slut rast"}
        defaultValue={props.endTime.slice(0, 5)}
        onChange={(e) => inputChangedHandler("end", e.target.value)}
        textCentered
      />
      {props.isPartTime && (
        <Input
          type="number"
          label="Max tidsåtgång (timmar)"
          value={props.max / 60}
          min={0}
          max={(function getMaxDuration() {
            const duration = DateTime.fromISO(props.endTime).diff(
              DateTime.fromISO(props.startTime),
              ["hours"]
            );
            return duration.hours;
          })()}
          onChange={(e) => inputChangedHandler("max", e.target.value)}
          textCentered
        />
      )}
    </div>
  );
};

export default WorkTimeInputs;
