import { useState } from "react";
import { useFormik } from "formik";
import { object, string } from "yup";
import { DateTime } from "luxon";

import { compareHours, useTranslatedDates } from "../../../shared/utility";
import ModalContainer from "../../../components/UI/ModalContainer";
import Switch from "../../../components/UI/Switch/Switch";
import Select from "../../../components/Form/Select";
import Input from "../../../components/Form/Input";
import Button from "../../../components/UI/NewButton";
import { useTranslation } from "react-i18next";

type AvailabilityModalProps = {
  name: string;
  date: DateTime;
  onClose: () => void;
  id: string;
  available: boolean;
  notAvailableReason?: string;
  comment?: string;
  start?: string;
  end?: string;
  overrideId: string | null;
  onSubmit: (values: any, type: string) => void;
  loading: boolean;
  type: "user" | "event";
};

const AvailabilityModal = ({
  name,
  date,
  id,
  available,
  notAvailableReason,
  start,
  end,
  comment,
  overrideId,
  onSubmit,
  onClose,
  loading,
  type,
}: AvailabilityModalProps): JSX.Element => {
  const [internalAvailable, setInernalAvailable] = useState(available);
  const { t } = useTranslation();
  const translate = (key: string) => t(`translations:schedulePage.${key}`);
  const formatISODate = () => {
    return `${date.day} ${date.monthLong} ${date.year}`;
  };

  const notAvailableReasonOptions = [
    {
      label: translate("sick"),
      value: "SICK",
    },
    {
      label: translate("vacation"),
      value: "VACATION",
    },
    {
      label: translate("otherReason"),
      value: "OTHER",
    },
  ];

  const schema = object().shape({
    notAvailableReason: object()
      .required(translate("reasonIsReuired"))
      .typeError(translate("pleaseSelectAReason")),
    start: string().required(translate("startTimeIsRequired")),
    end: string()
      .required(translate("endTimeIsRequired"))
      .test(
        "is greater than start",
        "End time should be after start time",
        function (end) {
          const { start } = this.parent;
          if (!end || !start) {
            return false;
          }
          return compareHours({ startHour: start, endHour: end });
        }
      ),
    comment: string().optional(),
  });

  const getFormInitialValues = () => {
    let formattedStartTime = "";
    if (start) {
      formattedStartTime = start?.split("T")[1]?.slice(0, 5) || "";
    }
    let formattedEndTime = "";
    if (end) {
      formattedEndTime = end?.split("T")[1]?.slice(0, 5) || "";
    }
    return {
      notAvailableReason: !!notAvailableReason
        ? notAvailableReasonOptions.filter(
            (option) => option.value === notAvailableReason
          )[0]
        : notAvailableReasonOptions[0],
      start: formattedStartTime,
      end: formattedEndTime,
      comment: comment || "",
    };
  };

  const {
    handleBlur,
    handleSubmit,
    setFieldValue,
    values,
    errors = {},
    touched,
  } = useFormik({
    validationSchema: schema,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true, //IMPORTANT: this allows to reset the form data when another presetting is selected
    initialValues: getFormInitialValues(),
    onSubmit: (values) => {
      const jsDate = date.toJSDate();
      const dmy = jsDate.toISOString().split("T")[0];
      const { start, end, notAvailableReason, comment } = values;
      const formattedStart = `${dmy}T${start}:00Z`;
      const formattedEnd = `${dmy}T${end}:00Z`;
      const valuesToSubmit = {
        userId: id,
        overrideId,
        available: false,
        timeSpanToOverride: {
          start: formattedStart,
          end: formattedEnd,
        },
        notAvailableReason: notAvailableReason.value,
        comment,
      };
      onSubmit(valuesToSubmit, type);
    },
  });

  const renderForm = (): JSX.Element => {
    return (
      <div className="flex flex-col items-center w-full">
        <div className="flex-[2] w-full">
          <Select
            labelAbove={translate("reason")}
            id="notAvailableReason"
            selectType="normal"
            touched={!!touched.notAvailableReason}
            error={String(errors.notAvailableReason || "")}
            options={notAvailableReasonOptions}
            value={values.notAvailableReason as any}
            onChange={(value: any) =>
              setFieldValue("notAvailableReason", value)
            }
          />
          <div className="flex flex-row gap-11 mt-4 w-full">
            <Input
              labelAbove={translate("start")}
              id="start"
              value={values.start}
              type="time"
              touched={!!touched.start}
              error={String(errors.start || "")}
              onBlur={handleBlur("start")}
              onChange={(e: any) => setFieldValue("start", e.target.value)}
            />
            <Input
              labelAbove={translate("end")}
              id="end"
              value={values.end}
              type="time"
              touched={!!touched.end}
              error={String(errors.end || "")}
              onBlur={handleBlur("end")}
              onChange={(e: any) => setFieldValue("end", e.target.value)}
            />
          </div>
        </div>
        <div className="flex-1 flex mt-6 flex-col items-center w-full">
          <Input
            isTextArea={true}
            labelAbove={translate("comment")}
            id="comment"
            value={values.comment}
            type="text"
            touched={!!touched.comment}
            error={String(errors.comment || "")}
            onBlur={handleBlur("comment")}
            onChange={(e: any) => setFieldValue("comment", e.target.value)}
          />
          <Button
            type="selected"
            customStyle="mt-4"
            isLoading={loading}
            title={translate("save")}
            onClick={handleSubmit}
          />
        </div>
      </div>
    );
  };

  return (
    <ModalContainer onClose={onClose}>
      <p className="bold-text text-lg mb-4">{`${name} ${translate("for")} ${formatISODate()}`}</p>
      <Switch
        label={internalAvailable ? translate("available") : translate("unavailable")}
        value={internalAvailable}
        onClick={() => {
          if (!internalAvailable) {
            onSubmit(
              {
                userId: id,
                available: true,
              },
              type
            );
          }
          setInernalAvailable(!internalAvailable);
        }}
      />
      {!internalAvailable && renderForm()}
    </ModalContainer>
  );
};

export default AvailabilityModal;
