import { useEffect, useRef } from "react";
import { FaCalendarMinus, FaClock, FaStopwatch } from "react-icons/fa";

type MissedEventProps = {
  eventName: string;
  clientName: string;
  color: string;
  start: string;
  end: string;
  duration: number;
  draggableEvents: boolean;
  event: NewEvent;
  onDragStart: (ev: NewEvent) => void;
};

const MissedEvent = (props: MissedEventProps) => {
  const container = useRef<HTMLDivElement>(null);
  const cardDescription = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!container.current) return;
    const containerRef = container.current;
    const update = ({ offsetX, offsetY }: any) => {
      if (!cardDescription.current) return;
      cardDescription.current.style.left = `${offsetX}px`;
      cardDescription.current.style.top = `${offsetY + 8}px`;
    };
    container.current.addEventListener("mousemove", update, { passive: true });
    container.current.addEventListener("touchmove", update, { passive: true });

    return () => {
      containerRef.removeEventListener("mousemove", update);
      containerRef.removeEventListener("touchmove", update);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="group relative">
      <div
        draggable={props.draggableEvents}
        onDragStart={() => props.onDragStart(props.event)}
        ref={container}
        style={{
          backgroundColor: props.color + "80",
          width: `${props.duration * 10}px`,
        }}
        className="rounded-sm h-6 flex items-center pl-2 bold-text text-xs min-w-[100px] max-w-[400px] select-none"
      >
        {props.clientName}
      </div>
      <div
        ref={cardDescription}
        className={`flex flex-col gap-2 p-2 bold-text
          w-40 rounded-md min-h-30 bg-white shadow absolute 
          transform transition-all duration-100 ease-in 
          scale-0 group-hover:scale-100 group-hover:z-[1000]`}
      >
        <span className="flex flex-row gap-1">
          <FaCalendarMinus fill={props.color} /> {props.eventName}
        </span>
        <span className="flex flex-row gap-1">
          <FaClock /> {props.start} - {props.end}
        </span>
        <span className="flex flex-row gap-1">
          <FaStopwatch />
          {props.duration > 60
            ? Math.round(props.duration) / 10 + " Hours"
            : props.duration + " Minutes"}
        </span>
      </div>
    </div>
  );
};

export default MissedEvent;
