import "./TextButton.scss";

type TextButtonProps = {
  label: string;
  active?: boolean;
  onClick?: () => void;
};

const TextButton = (props: TextButtonProps) => {
  return (
    <div className={`text-button${props.active ? " active" : ""}`} onClick={props.onClick}>
      {props.label}
    </div>
  );
};

export default TextButton;
