import Spinner from "../NewSpinner";

type ButtonType = "selected" | "normal" | "danger" | "neutral";

type ButtonProps = {
  type?: ButtonType;
  children?: JSX.Element;
  title?: string;
  onClick: () => void;
  isLoading?: boolean;
  disabled?: boolean;
  customStyle?: string;
};

const classNames = {
  button: (type: ButtonType, customStyle: string) =>
    `min-w-[150px] h-[46px] rounded-[23px] flex items-center justify-center ${(function determineBackgorundColor() {
      switch (type) {
        case "normal":
          return "bg-lightMint";
        case "selected":
          return "bg-mint";
        case "danger":
          return "bg-danger";
        case "neutral":
          return "bg-grey";
        default:
          return "bg-white";
      }
    })()} ${customStyle}`,
};

const Button = ({
  type = "normal",
  title = "Click me",
  children,
  isLoading = false,
  disabled = false,
  onClick = () => {},
  customStyle = "",
}: ButtonProps): JSX.Element => {
  return (
    <button
      disabled={disabled}
      onClick={() => onClick()}
      className={classNames.button(type, customStyle)}
    >
      {(function renderButtonContent() {
        if (isLoading) {
          return <Spinner />;
        }
        if (!!children) {
          return children;
        } else {
          return <div className="bold-text">{title}</div>;
        }
      })()}
    </button>
  );
};

export default Button;
