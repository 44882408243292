import { Fragment } from "react";
import "./OverviewTimes.scss"
import { useTranslation } from "react-i18next";;

type OverviewTimesProps = {
  times: Array<string>;
  lineLength: number;
  isStaff: boolean;
  onIsStaffChange: (isStaff: boolean) => void;
};

const OverviewTimes = (props: OverviewTimesProps) => {
  const { t } = useTranslation();
  const translate = (key: string) => t(`translations:userPage.${key}`);
  return (
    <div className="overview-times">
      <div className="overview-times--is-staff">
        <div
          className="overview-times--is-staff__option"
          style={{ color: props.isStaff ? "#071125" : "#838892" }}
          onClick={() => props.onIsStaffChange(true)}
        >
          {translate("Staff")}
        </div>
        <div
          className="overview-times--is-staff__option"
          style={{ color: props.isStaff ? "#838892" : "#071125" }}
          onClick={() => props.onIsStaffChange(false)}
        >
          {translate("Clients")}
        </div>
      </div>
      {props.times.map((time, i) => (
        <Fragment key={i}>
          <div className="overview-times--time">{time}</div>
          <div
            className="overview-times--line"
            style={{
              left: i === 0 ? 200 : i * 60 + 200,
              height: props.lineLength,
            }}
          />
        </Fragment>
      ))}
    </div>
  );
};

export default OverviewTimes;
