import axios from "../axios";

export const getTokenUsernamePassword = (
  username: string,
  password: string
) => {
  return axios.post("oauth/token", {
    grant_type: "password",
    username: username,
    password: password,
  });
};

export const refreshToken = (refreshToken: string) => {
  return axios.post("oauth/token", {
    grant_type: "refresh_token",
    refresh_token: refreshToken,
  });
};

export const revokeRefreshToken = (refreshToken: string) => {
  return axios.post("oauth/revoke", {
    token: refreshToken,
  });
};
