import { FaEdit } from "react-icons/fa";
import Checkbox from "../../../components/UI/Checkbox";
import { useTranslation } from "react-i18next";

const renderNameWithGroupColor = (
  name: string,
  groupColor?: string
): JSX.Element => {
  return (
    <div className="flex flex-row gap-3 items-center">
      <div
        className={`w-2 h-2 rounded-[4px]`}
        style={{ backgroundColor: groupColor ? groupColor : "white" }}
      />
      <>{name}</>
    </div>
  );
};

const renderAvailablilityCell = ({
  cellProps,
  onCheck,
  onEdit,
}: {
  cellProps: any;
  onCheck: (rowValues: any) => void;
  onEdit: (rowValues: any) => void;
}): JSX.Element => {
  const row = cellProps.cell.row.original;
  return (
    <div className="h-[100%] w-[100%] items-center justify-center flex flex-row gap-4 rounded-xl">
      <Checkbox checked={cellProps.value} onCheck={() => onCheck(row)} />
      <FaEdit onClick={() => onEdit(row)} className="hover:cursor-pointer" />
    </div>
  );
};

const useClientColumns = ({
  onAvailabilityCheck,
  onAvailabilityEdit,
}: {
  onAvailabilityCheck: (row: any) => void;
  onAvailabilityEdit: (row: any) => void;
}): any => {
  const { t } = useTranslation();
  const translate = (key: string) => t(`translations:schedulePage.${key}`);

  return {
    available: {
      Header: translate("available"),
      accessor: "available",
      Cell: (props: any) =>
        renderAvailablilityCell({
          cellProps: props,
          onCheck: onAvailabilityCheck,
          onEdit: onAvailabilityEdit,
        }),
    },
    groupName: {
      Header: translate("group"),
      accessor: "groupName",
      Cell: (props: any) => {
        const { groupColor } = props.cell.row.original;
        return renderNameWithGroupColor(props.value, groupColor);
      },
    },
    email: {
      Header: translate("email"),
      accessor: "email",
    },
    name: {
      Header: translate("name"),
      accessor: "name",
    },
    phoneNumber: {
      Header: translate("phoneNumber"),
      accessor: "phoneNumber",
    },
    languages: {
      Header: translate("language"),
      accessor: "languages",
    },
    allergies: {
      Header: translate("allergens"),
      accessor: "allergies",
    },
    address: {
      Header: translate("address"),
      accessor: "address",
    },
    postalCode: {
      Header: translate("postalCode"),
      accessor: "postalCode",
    },
    healthCenter: {
      Header: translate("healthCenter"),
      accessor: "healthCenter",
    },
    priorities: {
      Header: translate("priorityList"),
      accessor: "priorities",
    },
    dislikes: {
      Header: translate("dislikes"),
      accessor: "dislikes",
    },
  };
};

export default useClientColumns;
