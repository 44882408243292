import { FaEdit } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import Checkbox from "../../../components/UI/Checkbox";

const renderNameWithGroupColor = (
  name: string,
  groupColor?: string
): JSX.Element => {
  return (
    <div className="flex flex-row gap-3 items-center">
      <div
        className={`w-2 h-2 rounded-[4px]`}
        style={{ backgroundColor: groupColor ? groupColor : "white" }}
      />
      <>{name}</>
    </div>
  );
};

const renderAvailablilityCell = ({
  cellProps,
  onCheck,
  onEdit,
}: {
  cellProps: any;
  onCheck: (rowValues: any) => void;
  onEdit: (rowValues: any) => void;
}): JSX.Element => {
  const row = cellProps.cell.row.original;
  return (
    <div className="h-[100%] w-[100%] items-center justify-center flex flex-row gap-4 rounded-xl">
      <Checkbox checked={cellProps.value} onCheck={() => onCheck(row)} />

      <FaEdit onClick={() => onEdit(row)} className="hover:cursor-pointer" />
    </div>
  );
};

const useStaffColumns = ({
  onAvailabilityCheck,
  onAvailabilityEdit,
}: {
  onAvailabilityCheck: (row: any) => void;
  onAvailabilityEdit: (row: any) => void;
}): any => {
  const { t } = useTranslation();
  const translate = (key: string) => t(`translations:schedulePage.${key}`);
  return {
    available: {
      Header: translate("available"),
      accessor: "available",
      Cell: (props: any) =>
        renderAvailablilityCell({
          cellProps: props,
          onCheck: onAvailabilityCheck,
          onEdit: onAvailabilityEdit,
        }),
    },
    groupName: {
      Header: translate("groups"),
      accessor: "groupName",
      Cell: (props: any) => {
        const { groupColor } = props.cell.row.original;
        return renderNameWithGroupColor(props.value, groupColor);
      },
    },
    email: {
      Header: translate("email"),
      accessor: "email",
    },
    name: {
      Header: translate("name"),
      accessor: "name",
    },

    startWeek: {
      Header: translate("startWeek"),
      accessor: "startWeek",
    },
    repetiton: {
      Header: translate("repetition"),
      accessor: "repetiton",
    },

    start: {
      Header: translate("start"),
      accessor: "start",
    },
    end: {
      Header: translate("end"),
      accessor: "end",
    },
    duration: {
      Header: translate("duration"),
      accessor: "duration",
      Cell: (props: any) => {
        if (props.value) {
          return props.value;
        } else return "-";
      },
    },
    availableForDualStaffing: {
      Header: translate("dualStaffing"),
      accessor: "availableForDualStaffing",
      Cell: (props: any) => (props.value ? translate("Yes") : "-"),
    },
    connectedTo: {
      Header: translate("connectedTo"),
      accessor: "connectedTo",
      Cell: (props: any) => {
        if (props.value) {
          return props.value;
        } else {
          return "-";
        }
      },
    },
    hasWorkshiftThisDate: {
      Header: translate("hasWorkshift"),
      accessor: "hasWorkshiftThisDate",
      Cell: (props: any) => (!!props.value ? translate("Yes") : translate("No")),
    },

    phoneNumber: {
      Header: translate("phoneNumber"),
      accessor: "phoneNumber",
    },
    languages: {
      Header: translate("language"),
      accessor: "languages",
    },

    transportMethods: {
      Header: translate("transportMethods"),
      accessor: "transportMethods",
    },
    employmentType: {
      Header: translate("employement"),
      accessor: "employmentType",
    },
    allergies: {
      Header: translate("allergies"),
      accessor: "allergies",
    },
  };
};

export default useStaffColumns;
