import { NavLink } from "react-router-dom";
import "./Logo.scss";

type LogoProps = {};

const Logo = (props: LogoProps) => {
  return (
    <NavLink to="/schema/redigera">
      <div className="logo"></div>
    </NavLink>
  );
};

export default Logo;
