import { useEffect, useState } from "react";
import { updateScheduleEvent } from "../../../api/schedule";
import Button from "../../../components/UI/Button/Button";
import Input from "../../../components/UI/Input/Input";
import Popup from "../../../hoc/Popup/Popup";
import {
  CancelledReason,
  ClientEntity,
  StaffEntity,
} from "../../../types/schedule";
import "./ScheduleInfoBox.scss";

type ScheduleInfoBoxProps = {
  eventId: string;
  title: string;
  startTime: string;
  endTime: string;
  address: string;
  otherUser: ClientEntity | StaffEntity | string;
  description: string;
  isStaff: boolean;
  mapsLink: string;
  isMobile?: boolean;
  cancelReason?: CancelledReason;
  onPopupClose: () => void;
  onRebook?: () => void;
};

const ScheduleInfoBox = (props: ScheduleInfoBoxProps) => {
  const [cancelReason, setCancelReason] = useState<CancelledReason | "">("");
  const [newDate, setNewDate] = useState("");

  useEffect(() => {
    setCancelReason("");
  }, [props.eventId]);

  const otherUserString = `${
    props.isStaff
      ? `Kund: ${`${(props.otherUser as ClientEntity).firstName || ""} ${
          (props.otherUser as ClientEntity).lastName || ""
        }`}`
      : `Vådare: ${`${(props.otherUser as StaffEntity).user?.firstName || ""} ${
          (props.otherUser as StaffEntity).user?.lastName || ""
        }`}`
  }`;

  const rebookHandler = (shouldNotReebook?: boolean) => {
    if (cancelReason === "") return;
    updateScheduleEvent(props.eventId, {
      cancelled: cancelReason,
      failed: false,
      rebookAt: shouldNotReebook
        ? undefined
        : {
            start: new Date(newDate + " 09:00:00").toISOString(),
            end: new Date(newDate + " 24:00:00").toISOString(),
          },
    }).then(() => {
      setCancelReason("");
      if (props.onRebook) {
        props.onRebook();
      }
    });
  };

  const scheduleEventBoxClasses = ["schedule-event-box"];
  if (props.isMobile) {
    scheduleEventBoxClasses.push("is-mobile");
  }

  let cancelReasonText = "";
  switch (props.cancelReason) {
    case "AT_DOOR":
      cancelReasonText = "Avbokad vid dörren";
      break;
    case "IN_ADVANCE":
      cancelReasonText = "Avbokad i förväg";
      break;
    case "OTHER_REASON":
      cancelReasonText = "Avbokad av annan orsak";
      break;
  }

  const component = (
    <div className={scheduleEventBoxClasses.join(" ")}>
      <div className="schedule-event-box--title">
        {props.title}
        {props.mapsLink.length > 0 && (
          <a
            href={props.mapsLink}
            target="_blank"
            rel="noreferrer"
            className="schedule-event-box--title__map waves-effect"
          >
            <i className="far fa-map" />
          </a>
        )}
      </div>
      <p className="schedule-event-box--small-title">
        Information <i>{cancelReasonText}</i>
      </p>
      <div className="schedule-event-box--line" />
      <div className="schedule-event-box--info">
        <div className="schedule-event-box--info__text">{`Tid: ${props.startTime} - ${props.endTime}`}</div>
        <div className="schedule-event-box--info__text">{`Adress: ${props.address}`}</div>
        <div className="schedule-event-box--info__text">{otherUserString}</div>
      </div>
      <p className="schedule-event-box--small-title">Beskrivning</p>
      <p className="schedule-event-box--text">{props.description}</p>
      {props.isStaff && (
        <div className="schedule-event-box--rebook">
          <p>Om instasten inte genomfördes</p>
          <div className="schedule-event-box--rebook__buttons">
            <Button
              label="Avbokad vid dörr"
              onClick={() => setCancelReason("AT_DOOR")}
              color={cancelReason === "AT_DOOR" ? "green" : "grey"}
              width="150px"
            />
            <Button
              label="Avbokad i förväg"
              onClick={() => setCancelReason("IN_ADVANCE")}
              color={cancelReason === "IN_ADVANCE" ? "green" : "grey"}
              width="150px"
            />
            <Button
              label="Annan anledning"
              onClick={() => setCancelReason("OTHER_REASON")}
              color={cancelReason === "OTHER_REASON" ? "green" : "grey"}
              width="150px"
            />
          </div>
          {cancelReason && (
            <>
              <p>Välj dag insatsen ska ombokas på</p>
              <div className="schedule-event-box--rebook__date">
                <Input
                  value={newDate}
                  onChange={(e) => setNewDate(e.target.value)}
                  type="date"
                />
                <Button
                  label="Ska inte ombokas"
                  onClick={() => rebookHandler(true)}
                  color="grey"
                  width="150px"
                />
              </div>
              {cancelReason && newDate && (
                <Button
                  label="Spara"
                  onClick={() => rebookHandler()}
                  color="grey"
                />
              )}
            </>
          )}
        </div>
      )}
    </div>
  );

  if (props.isMobile) {
    return (
      <Popup showPopup={true} onClose={props.onPopupClose}>
        {component}
      </Popup>
    );
  }

  return component;
};

export default ScheduleInfoBox;
