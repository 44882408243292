import { Stack, Tab, Tabs } from "@mui/material";
import { DateTime } from "luxon";
import { useEffect, useRef, useState, useContext } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { GrPowerReset } from "react-icons/gr";
import { FaWindowClose } from "react-icons/fa";
import {
  downloadScheduleOverview,
  getScheduleOverview,
} from "../../api/schedule";
import { SocketContext } from "../../socket";

import Button from "../../components/UI/Button/Button";
import Input from "../../components/UI/Input/Input";
import Spinner from "../../components/UI/Spinner/Spinner";
import Popup from "../../hoc/Popup/Popup";
import { dateToString, useTranslatedDates } from "../../shared/utility";
import { IScheduledEvent } from "../../types/event";
import { IScheduleUser } from "../../types/schedule";
import ScheduleOverride from "./ScheduleOverride";
import PresettingsModal from "./PresettingsModal";
import MissedEvents from "./MissedEvents";
import OverviewRow from "./OverviewRow/OverviewRow";
import OverviewTimes from "./OverviewTimes/OverviewTimes";
import { useTranslation } from "react-i18next";
import PublishDraft from "./PublishDraft";
import "./ScheduleOverview.scss";

type ScheduleOverviewProps = {
  organizationId: string;
  showEdit?: boolean;
};

const times: string[] = [];

for (let i = 0; i <= 23; i++) {
  times.push(`${i.toString().padStart(2, "0")}:00`);
}

const scheduleTypes = ["LIVE", "STAGING"];

const ScheduleOverview = (props: ScheduleOverviewProps) => {
  const { t } = useTranslation();
  const translate = (key: string) => t(`translations:schedulePage.${key}`);
  const { days } = useTranslatedDates();

  const {
    runningEvents,
    completedEvents,
    failedEvents,
    cancelledEvents,
    cancel,
    deleteSavedEvent,
  } = useContext(SocketContext);

  const [showPublishDraft, setShowPublishDraft] = useState(false);
  const [presettingsModalShown, setPressettingsModalShown] = useState(false);
  const [scheduleType, setScheduleType] = useState<number>(0);
  const [scheduleOverviewEvents, setScheduleOverviewEvents] = useState<
    { user: IScheduleUser; events: IScheduledEvent[] }[]
  >([]);
  const [fromDate, setFromDate] = useState<DateTime>();
  const [isStaff, setIsStaff] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const scheduleRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const urlFromDate = searchParams.get("date");
    if (urlFromDate) {
      setFromDate(DateTime.fromISO(urlFromDate).set({ hour: 12 }));
    } else {
      setFromDate(DateTime.now());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (scheduleRef.current) {
      scheduleRef.current.scrollLeft = 410;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleRef.current]);

  useEffect(() => {
    if (fromDate) {
      navigate({ search: `?date=${fromDate.toISODate()}` });
    }
  }, [fromDate, navigate]);

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate, isStaff, props.organizationId, scheduleType]);

  const fetch = () => {
    if (!props.organizationId) return;
    if (!fromDate) return;

    setIsLoading(true);
    getScheduleOverview(
      props.organizationId,
      dateToString(fromDate.toJSDate()),
      isStaff ? "STAFF" : "CLIENT",
      scheduleTypes[scheduleType] as "LIVE" | "STAGING"
    )
      .then((res) => {
        setScheduleOverviewEvents([
          ...res.data.filter((event) => event.events.length > 0),
          ...res.data.filter((event) => event.events.length === 0),
        ]);
        if (!fromDate) {
          const event = res.data.find((e) => e.events.length > 0);
          setFromDate(
            event?.events[0].timeSpan.start
              ? DateTime.fromISO(event?.events[0].timeSpan.start)
              : DateTime.now()
          );
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const backwardHandler = () => {
    if (fromDate) setFromDate(fromDate.minus({ weeks: 1 }));
  };

  const forwardHandler = () => {
    if (fromDate) setFromDate(fromDate.plus({ weeks: 1 }));
  };

  const changeDayHandler = (dayIndex: number) => {
    if (fromDate) setFromDate(fromDate.set({ weekday: dayIndex }));
  };

  type ScheduleGeneratorItemProps = {
    type: "running" | "cancelled" | "failed" | "done";
    event: ScheduleGeneratorEvent;
    onButtonClick?: (event: any) => void;
    onTopButtonClick?: (event: any) => void;
  };

  type ScheduleGeneratorEvent = {
    estimatedTime?: number;
    title?: string;
    from: string;
    to: string;
    requestID: string;
    started: string;
    finished?: string;
    userID: string;
  };

  const ScheduleGeneratorItem = (
    props: ScheduleGeneratorItemProps
  ): JSX.Element => {
    const { event } = props;
    //default props, for a running item
    let title = event.title || event.from.split("T")[0];
    let buttonTitle = translate("cancel");
    let backgroundColor = "#fff";
    let buttonBackgroundColor = "#FFCFCF";

    let startDate = "";
    if (event.from && event.from.split("T") && event.from.split("T")[0]) {
      startDate = event.from.split("T")[0];
    }
    let endDate = "";
    if (event.to && event.to.split("T") && event.to.split("T")[0]) {
      endDate = event.to.split("T")[0];
    }
    let started = "";
    if (
      event.started &&
      event.started.split("T") &&
      event.started.split("T")[1]
    ) {
      started = event.started.split("T")[1].slice(0, 5);
    }

    switch (props.type) {
      case "running":
        buttonTitle = translate("cancel");
        backgroundColor = "#fff";
        buttonBackgroundColor = "#FFCFCF";
        break;
      case "done":
        title = title + " " + translate("Done");
        buttonTitle = translate("toSchedule");
        backgroundColor = "#DDFFF6";
        buttonBackgroundColor = "#11FFBD";
        break;
      case "cancelled":
        title = title + " " + translate("Cancelled");
        buttonTitle = translate("retry");
        backgroundColor = "#FFCFCF";
        buttonBackgroundColor = "#F3F3F3";
        break;
      case "failed":
        title = title + " " + translate("Failed");
        buttonTitle = translate("retry");
        backgroundColor = "#FFCFCF";
        buttonBackgroundColor = "#F3F3F3";
        break;
      default:
        break;
    }

    return (
      <div
        style={{ backgroundColor }}
        className="card-container m-0 items-start mt-2"
      >
        <div className="flex flex-row justify-between self-stretch items-center gap-2">
          <p className="bold-text text-sm">{title}</p>
          {props.type === "done" && (
            <FaWindowClose
              className="hover:cursor-pointer"
              onClick={() =>
                props.onTopButtonClick
                  ? props.onTopButtonClick(props.event)
                  : () => {}
              }
            />
          )}
          {props.type === "cancelled" && (
            <GrPowerReset
              onClick={() =>
                props.onTopButtonClick
                  ? props.onTopButtonClick(props.event)
                  : () => {}
              }
              className="hover:cursor-pointer"
            />
          )}
        </div>
        <div className="divider-line mb-2" />

        <p className="text-sm">{translate("From")}: {startDate}</p>
        <p className="text-sm">{translate("To")}: {endDate}</p>
        <p className="text-sm">{translate("Started")}: {started}</p>
        {props.type === "running" && (
          <p className="text-sm">{translate("estimatedTime")}: {event.estimatedTime}</p>
        )}

        <div
          onClick={() =>
            props.onButtonClick ? props.onButtonClick(props.event) : () => {}
          }
          style={{ backgroundColor: buttonBackgroundColor }}
          className="h-[30px] w-full ml-1 mr-2 mt-4 flex items-center justify-center rounded-full hover:cursor-pointer select-none"
        >
          <p className="bold-text">{buttonTitle}</p>
        </div>
      </div>
    );
  };

  return (
    <div className="schedule-overview-page">
      <div className="schedule-overview-container flex flex-row ">
        <div className="card-container flex-2 overflow-scroll items-start ">
          <div className="schedule-overview">
            <Stack direction="row" marginBottom={1}>
              <Input
                type="date"
                value={fromDate?.toISODate()!}
                onChange={(e) => {
                  setFromDate(DateTime.fromISO(e.target.value));
                }}
              />
              <Tabs
                value={scheduleType}
                onChange={(event, newValue: number) => {
                  setScheduleType(newValue);
                }}
                aria-label="schedule types"
                textColor="inherit"
                centered
                sx={{ marginLeft: { sm: "unset", lg: "19.25rem" } }}
              >
                <Tab
                  label={translate("navigationLive")}
                  style={{ textTransform: "none" }}
                />
                <Tab
                  label={translate("navigationStaging")}
                  style={{ textTransform: "none" }}
                />
              </Tabs>

              <Stack spacing={1} direction="row" marginLeft="auto">
                <Button
                  label={translate("publishDraft")}
                  color="grey"
                  onClick={() => {
                    setShowPublishDraft(true);
                  }}
                  style={{
                    visibility: scheduleType === 1 ? "visible" : "hidden",
                  }}
                />

                <button
                  className="download-file-btn fas fa-download"
                  onClick={() => {
                    if (fromDate) {
                      downloadScheduleOverview(
                        dateToString(fromDate.toJSDate()),
                        isStaff ? "STAFF" : "CLIENT",
                        props.organizationId,
                        scheduleTypes[scheduleType] as "LIVE" | "STAGING"
                      );
                    }
                  }}
                ></button>

                <Popup
                  showPopup={showPublishDraft}
                  onClose={() => setShowPublishDraft(false)}
                >
                  <PublishDraft
                    organizationId={props.organizationId}
                    initialFromDate={fromDate!}
                  />
                </Popup>
              </Stack>
            </Stack>
            <div className="schedule-overview--top">
              <div className="schedule-overview--top__title">
                <div>
                  <div
                    className="strong"
                    style={{ minWidth: "100px", textAlign: "left" }}
                  >
                    {translate("Week")} {fromDate?.weekNumber}
                  </div>
                </div>
                <div className="arrows">
                  <div className="arrow waves-effect" onClick={backwardHandler}>
                    <i className="far fa-chevron-left" />
                  </div>
                  <div className="arrow waves-effect" onClick={forwardHandler}>
                    <i className="far fa-chevron-right" />
                  </div>
                </div>
                <div style={{ position: "relative" }}>
                  <div style={{ position: "absolute", left: 0, top: "-1rem" }}>
                    {isLoading && <Spinner small blue />}
                  </div>
                </div>
              </div>
              <div className="schedule-overview--top__days">
                {days.map((day, i) => (
                  <div
                    key={day.value}
                    className="schedule-overview--top__days__day"
                    style={{
                      color:
                        fromDate?.weekday === i + 1 ? "#071125" : "#838892",
                    }}
                    onClick={() => changeDayHandler(i + 1)}
                  >
                    {day.label}
                  </div>
                ))}
              </div>
              <div className="schedule-overview--top__right"></div>
            </div>
            <div
              style={{
                color: "#838892",
                marginBottom: "1rem",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              {fromDate
                ?.set({ weekday: 1 })
                .toLocaleString(DateTime.DATE_SHORT)}{" "}
              -{" "}
              {fromDate
                ?.set({ weekday: 7 })
                .toLocaleString(DateTime.DATE_SHORT)}
            </div>
            {!!scheduleOverviewEvents.length && (
              <div
                className="schedule-overview--schedule max-h-[350px]"
                id="schedule"
                ref={scheduleRef}
              >
                <OverviewTimes
                  isStaff={isStaff}
                  onIsStaffChange={setIsStaff}
                  times={times}
                  lineLength={(scheduleOverviewEvents.length + 1) * 31}
                />
                {scheduleOverviewEvents.map((event, i) => {
                  return (
                    <OverviewRow
                      key={event.user.id}
                      index={i}
                      onUpdated={() => fetch()}
                      personName={`${event.user.firstName} ${event.user.lastName}`}
                      events={event.events}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </div>
        <div className="card-container schedule-control-panel min-w-[200px] max-h-[560px] h-full flex-shrink-1 box-border flex-grow-0 overflow-y-scroll pr-2 pl-2">
          <strong className="text-l text-align: center;">{translate("scheduleGenerator")}</strong>
          <div className="divider-line" />
          <Button
            style={{ width: "100%" }}
            color="green"
            className="button panel-button green"
            label={translate("createScheduleButton")}
            onClick={() => {
              setPressettingsModalShown(true);
            }}
          />
          {runningEvents.map((event: ScheduleGeneratorEvent) => (
            <ScheduleGeneratorItem
              key={event.requestID}
              event={event}
              type="running"
              onButtonClick={(event: ScheduleGeneratorEvent) => {
                cancel(event.requestID);
              }}
            />
          ))}
          {completedEvents.map((event: ScheduleGeneratorEvent) => (
            <ScheduleGeneratorItem
              key={event.requestID}
              event={event}
              type="done"
              onTopButtonClick={(event: ScheduleGeneratorEvent) => {
                deleteSavedEvent(event.requestID);
              }}
              onButtonClick={(event: ScheduleGeneratorEvent) => {
                setScheduleType(1);
                const date = DateTime.fromISO(event.from);
                setFromDate(date);
              }}
            />
          ))}
          {cancelledEvents.map((event: ScheduleGeneratorEvent) => (
            <ScheduleGeneratorItem
              key={event.requestID}
              event={event}
              type="cancelled"
              onTopButtonClick={(event) => {
                console.log("event: ", event);
              }}
            />
          ))}
          {failedEvents.map((event: ScheduleGeneratorEvent) => (
            <ScheduleGeneratorItem
              key={event.requestID}
              event={event}
              type="failed"
              onButtonClick={(event) => {
                console.log("event: ", event);
              }}
            />
          ))}
        </div>
      </div>
      {!isLoading && props.showEdit && fromDate && (
        <MissedEvents date={fromDate} scheduleType={scheduleType} />
      )}

      {!isLoading && props.showEdit && fromDate && (
        <ScheduleOverride date={fromDate} />
      )}
      {presettingsModalShown && fromDate && (
        <PresettingsModal
          onClose={() => setPressettingsModalShown(false)}
          initialFromDate={fromDate!}
        />
      )}
    </div>
  );
};

export default ScheduleOverview;
