export default {
  translations: {
    generalWords: {
      monday: "Monday",
      tuesday: "Tuesday",
      wednesday: "Wednesday",
      thursday: "Thursday",
      friday: "Friday",
      saturday: "Saturday",
      sunday: "Sunday",
      january: "January",
      february: "February",
      mars: "Mars",
      april: "April",
      may: "May",
      june: "June",
      july: "July",
      august: "August",
      september: "September",
      october: "October",
      november: "November",
      december: "December",
      schedule: "Schedule",
      statistic: "Statistics",
      users: "Users",
      settings: "Settings",
    },
    schedulePage: {
      title: "Schedule",
      navigationCreateSchedule: "Create schedule",
      navigationIndividualSchedule: "Individual schedule",
      navigaionEditSchedule: "Edit schedule",
      navigationLive: "Live",
      navigationStaging: "Draft",
      createScheduleButton: "Create schedule",
      navigationStaff: "Staff",
      navigationClient: "Clients",
      Week: "Week",
      fastAdjustment: "Fast adjustments",
      navigationEvent: "Events",
      publishDraft: "Publish draft",
      missedEvents: "Missed events",
      showMore: "Show more",
      showLess: "Show less",
      emptyStateText: "There's no data available",
      available : "Available",
      group: "Group",
      groups:  "Groups",
      email :  "E-mail",
      name : "Name",
      startWeek  : "Start week",
      repetition: "Repetition",
      start : "Start",
      end : "End",
      duration : "Duration",
      dualStaffing : "Dual staffing",
      connectedTo :  "Connected to",
      hasWorkshift: "Has workshift",
      phoneNumber : "Phone number",
      language : "Languages",
      transportMethods   :  "Transport method",
      employement : "Employement",
      allergies : "Allergies",
      allergens : "Allergens",
      address :  "Address",
      postalCode : "Postal code",
      healthCenter : "Health center",
      priorityList :  "Priority list",
      dislikes : "Dislikes",
      description : "Description",
      lock :  "Locked",
      numberOfStaff : "Number of staff",
      specificStaff :  "Specific  staff",
      Staff : "Staff",
      Clients : "Clients",
      couldNotFindClientAddress : "Could not find client's address",
      missingName : "Missing name",
      priorities : "Priorities",
      unexpectedError : "Unexpected error occured",
      groupUpdated : "County/Group updated",
      prioritylistOfStaff : "Priority list of staff",
      nameMissing  :  "Name missing",
      clientAddress : "Client's address",
      noNameFound : "No name found",
      cancel : "Cancel",
      Cancelled : "Cancelled",
      Done : "Done",
      Failed : "Failed",
      retry : "Retry",
      toSchedule : "To schedule",
      From : "From",
      To : "To",
      Started : "Started",
      estimatedTime : "Estimated time",
      scheduleGenerator : "Schedule generator",
      search : "Search",
      Yes : "Yes",
      No : "No",
      Add : "Add",
      sick : "Sick",
      vacation : "Vacation",
      reasonIsReuired : "Reason is required",
      pleaseSelectAReason : "Please select a reason",
      startTimeIsRequired : "Start time is required",
      endTimeIsRequired : "End time is required",
      reason : "Reason",
      comment : "Comment",
      unavailable : "Unavailable",
      for : "for",
      otherReason : "Other reason",
      save : "Save",

    },
    statisticsPage: {
      title: "Statistics",
      navigationOverview: "Overview",
      navigationIndividual: "Individual",
      navigationLive: "Live",
      navigationStaging: "Draft",
      today: "Today",
      monthly: "Monthly",
      weekly: "Weekly",
      daily: "Daily",
      agenda: "Agenda",
      name: "Name",
      scheduledEventsForStaff: "Scheduled events",
      efficiency: "Efficiency",
      navigationStaff: "Staff",
      navigationClient: "Client",
      navigationContinuity: "Continuity",
      scheduledEventsForClient: "Scheduled events",
      cancelledAtDoor: "Cancelled at door",
      cancelledInAdvanced: "Cancelled in advanced",
      cancelledOtherReason: "Other reason",
      emptyStateText: "There's no data available",
      continuity : "Continuity",
      numberOfEvents : "Number of events",
      nameMissing  :  "Name missing"
    },
    userPage: {
      title: "Users",
      navigationStaff: "Staff",
      navigationClient: "Client",
      search: "Search",
      name: "Name",
      add: "Add",
      addUser: "Add user",
      addStaff: "Add staff",
      addClient: "Add client",
      addEvent: "Add event",
      StaffInformation: "Person info",
      firstName: "First name",
      personalInformation: "Person info",
      lastName: "Last name",
      gender: "Gender",
      male: "Male",
      female: "Female",
      other: "Other",
      employementType: "Employement type",
      travelWayAndGeographicPlace: "Travelway and geographical place",
      travelWay: "Travelway",
      chose_county_or_groups: "Chose county/group",
      county_or_group: "County/group",
      avialableWorkTimes: "Available worktimes",
      breaks: "Breaks",
      unavailableDays: "Days staff can't work",
      generalConditions: "General conditions",
      allergies: "Allergies",
      language: "Languages",
      deleteStaff: "Delete staff",
      localHospital: "Local hospital",
      contactInfo: "Contact info",
      email: "E-mail",
      phoneNumber: "Phonenumber",
      password: "Password",
      livingplace: "Home info",
      address: "Address",
      postalCode: "Postal code",
      prioritize: "Priority",
      prioritizeList: "Priority list",
      prioritized: "Prioritized",
      notPrioritized: "Not prioritized",
      Staff: "Staff",
      acceptedTasks: "Planned events",
      dislikes: "Dislike",
      allergens: "Allergens",
      deleteClient: "Delete client",
      addPlannedEvent: "Add event",
      ifThePlannedEventOnlyOccursOnce: "If event only should occur once",
      nameOfPlannedEvent: "Name of event",
      startEarliest: "Start earliest",
      stopsLatest: "Finish latest",
      duration: "Duration (min)",
      ifLocked: "Press on lock if event info should be locked",
      plannedEventOccuringFollowingDays: "Event occurs following days",
      ifEventCanBeTakenAnytimeOfTheWeek:
        "If the event can be taken anytime of the week",
      ifSpecificOrMultipleStaffNeeded:
        "If the event needs a specific or multiple staffing",
      description: "Description",
      save: "Save",
      Bike: "Cykel",
      Car: "Bil",
      Walk: "Gång",
      yes: "yes",
      no: "no",
      deleting: "deleting",
      areYouSure: "Are you sure?",
      nameIsrequired: "Name is required",
      minimumLengthNameText: "Name can't be empty",
      notEmpty: "Not empty",
      startTimeCanNotBeEmpty: "Start time can not be empty",
      everyWeek: "Every week",
      everyTwoWeek: "Every two week",
      everyThreeWeek: "Every three week",
      everyFourWeek: "Every four week",
      endTimeMustBeAfterStartTime: "End time must be after start time",
      durationBiggerThan1Text:
        "Duration should at least be bigger than 1 minute",
      thisIsRequired: "This is required",
      succefullySavedPlannedEvent: "Succesfully saved event",
      failedSavingPlannedEvent: "Failed saving event",
      dualStaff: "Dual staff",
      anyone: "Anyone",
      timeIntervalIsWrong: "Timeinterval is wrong",
      giveAtLeastOneDayOccuring:
        "Give at least one day the event is occuring or chose anyday",
      mustGiveANameToEvent: "You have to give the event a name",
      updatedEvent: "Updated event",
      createdEvent: "Created event",
      pressIfEventOnlyOccursOnce: "Press if event only occurs once",
      choseSingleDayEventOccurs: "Chose single day event should occur",
      howDoesEventrepeat: "How does event repeat",
      startWeek: "Start week",
      eventType: "Event type",
      enterEndTime: "Enter end time",
      enterStartTime: "Enter start time",
      enterDuration: "EnterDuration",
      doYouReallyWantToRemoveThisevent:
        "Do you really want to remove this event?",
      eventRemoved: "Event removed",
      failedRemovingEvent: "Failed removing event",
      specificTimesOrHourBased: "Strict times or hourly based",
      canDoDualStaffing: "Can do dual staffing events",
      notCorrectFilledOutForm: "Fields are not correctly filled in",
      languange: "Language",
      client: "client",
      staff: "staff",
      somethingWentWrong : "Something went wrong",
      min : "min",
      isNowUnavailable : "is now unavailable",
      isNowAvailable : "is now available",
      missingName : "Missing name",
      priorities : "Priorities",
      unexpectedError : "An unexpected error occured",
      groupUpdated : "Group/County updated",
      prioritylistOfStaff : "Priority list of staff",
      nameInfoUpdated : "Name info updated",
      unvalidEmail : "Unvalid e-mail",
      unknownErrorOccured : "Unkown error occured",
      infoUpdated : "Information updated",
      conditionsUpdated : "General conditions updated",
      Clients : "Clients",
    






    },
    settingsPage: {
      title: "Settings",
      schedule: "Schedule",
      statistics: "Statistics",
      users: "Users",
      myAccount: "My account",
      yourCompany: "My company",
      howDoIUse: "How do I use?",
      generatorSettings: "Generator-settings",
      handlingOfYourData: "Handling of my data",
      groups: "County/groups",
      eventType: "Event type",
      mySettings: "My settings",
      adminAccount: "Admin account",
      yourPrenumeration: "My prenumeration",
      customization: "Customization",
      editGroup: "Edit group",
      addGroup: "Add group",
      name: "Name",
      color: "Color",
      deleteGroup: "Delete group",
      editGeneratorSettings: "Edit generator-settings",
      numberOfDays: "Number of days",
      addGeneratorSetting: "Add generator-setting",
      startTime: "Start time",
      endTime: "End time",
      conditionsForSchedule: "Conditions for schedule",
      meetConditions: "Conditions for a staff meeting a client",
      dualStaffingCondition: "Conditions for dual staffing",
      prioritizations: "Prioritizations",
      algorithmPrioritization: "Algorithm-prioritizations",
      deleteGeneratorSetting: "Delete generator-settings",
      nameIsRequired: "Name is required",
      minimumLengthNameText: "Name can not be empty",
      succesfullySavedGroup: "Group is saved",
      failedSavingGroup: "Failed saving group",
      succesfullyUpdatedGroup: "Group is updated",
      failedUpdatingGroup: "Failed updating group",
      succesfullyDeletedGroup: "Group is deleted",
      failedDeletingGroup: "Failed deleting group",
      groupListIsEmptyButYouCanCreateOne:
        "There are no groups, but you can create one",
      Yes: "Yes",
      No: "No",
      enterStartTime: "Enter start time",
      enterEndTime: "Enter end time",
      Filter: "Filter",
      staffFilter: "Staff filter",
      groupFilter: "Group filter",
      enterGeneratorSettingName: "Enter generator-setting name",
      enterNumberOfDays: "Enter number of days",
      continueDeletingGeneratorsetting: "Continue deleting generator-setting?",
      deleting: "Deleting",
      enterGroupName: "Enter group name",
      externalIDisRequired: "External id is required",
      externalIdCanNotBeEmpty: "External id can not be empty",
      canNotBeLessThan1Day : "Can not be less than 1 day",
      canNotBeMoreThan7Days : "Can not be more than 7 days",
      nameCanNotBeEmpty : "Name can not be empty",
      notEmpty : "Not empty",
      startTimeCanNotBeEmpty  :  "Start time can not be empty",
      enterTheStartTime : "Enter the start time",
      enterTheEndTime : "Enter the end time",
      minimumRequirementsForStaffAndClientToMeet : "Minimum requirements for staff and client to meet",
      requirementForDualStaffing : "Requirement for dual staffing",
      algorithmPrioritizations : "Algorithm prioritizations",
      filter  : "Filter",
      generateSchedule : "Generate schedule",
      selectADate :  "Select a date",
      selectAGeneratorsetting : "Select a generator-setting",
      thereAreNoGroupsPleaseSelectAGroup : "There are no groups, please select a group",
      anyone : "Anyone",
      specific :  "Specific",
      duaStaffing : "Dual staffing",
      onlyFemales : "Only females",
      onlyMales : "Only males",
      enterEventTypeName : "Enter event type name",
      eventID : "Event ID",
      enterDuration : "Enter duration",
      conditions : "Conditions",
      areYouSure : "Are you sure?",
      addEventType : "Add event type",
      deleteEventType : "Delete event type",
      thereAreNoEventTypesButYouCanCreateOne : "There are no event types, but you can create one",
      nameMissing  :  "Name missing",
      repetition : "Repetition",
      duration : "Duration",
      sameGroup : "Same group",
      noAllergies : "No allergies",
      sameLanguage : "Same language",
      specificStaff : "Specific staff",
      connectedWorkShifts : "Connected workshifts",
      availableForDualStaffing : "Available for dual staffing",
      continuity : "Continuity",
      efficiency : "Efficiency",
      distances : "Distances",
      keepSame : "Keep same",
      numberOfEvents : "Number of events"
    },
  },
};
