import { TwitterPicker } from "react-color";

type ColorPickerProps = {
  color: string;
  pickerVisible: boolean;
  onChangeComplete: (hex: string) => void;
  onPreviewClicked: () => void;
};

const ColorPicker = ({
  error,
  touched,
  pickerVisible,
  color,
  onChange,
  labelAbove,
  onChangeComplete,
  onPreviewClicked,
  ...rest
}: DefaultFieldProps & ColorPickerProps): JSX.Element => {
  return (
    <div className="mt-[-2px]">
      <label className="bold-text text-xs mb2">{labelAbove}</label>
      <div
        className="relative w-[40px] h-[40px] rounded-[50%]"
        style={{ backgroundColor: color || "black" }}
        onClick={onPreviewClicked}
      >
        {pickerVisible && (
          <TwitterPicker
            onChangeComplete={({ hex }) => onChangeComplete(hex)}
            className="absolute top-10"
            {...rest}
          />
        )}
      </div>
      {error && touched && (
        <label className="normal-text text-red-500 text-right mt-[11px]">
          {error}
        </label>
      )}
    </div>
  );
};
export default ColorPicker;
