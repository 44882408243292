import "./Spinner.scss";

type SpinnerProps = {
  small?: boolean;
  green?: boolean;
  blue?: boolean;
};

const Spinner = (props: SpinnerProps) => {
  const classes = ["lds-ring"];
  if (props.small) {
    classes.push("small");
  }
  if (props.green) {
    classes.push("green");
  }
  if (props.blue) {
    classes.push("blue");
  }

  return (
    <div className={classes.join(" ")}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default Spinner;
