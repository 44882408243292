import * as actionTypes from "./actionTypes";
import jwtDecode from "jwt-decode";
import {
  getTokenUsernamePassword,
  refreshToken,
  revokeRefreshToken,
} from "../../api/auth";
import { DecodedAuthToken } from "../../types/user";

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

type AuthSuccessArgs = {
  userId: string;
  token: string;
  organizationId: string;
  role: "STAFF" | "ADMIN" | "CLIENT";
};

export const authSuccess = ({
  userId,
  token,
  organizationId,
  role,
}: AuthSuccessArgs) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    idToken: token,
    organizationId,
    userId,
    role,
  };
};

export const authFail = (error: any) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};

export const logout = () => {
  revokeRefreshToken(localStorage.getItem("sro-refresh")!)
    .then(() => {
      localStorage.removeItem("sro-token");
      localStorage.removeItem("sro-expirationDate");
      localStorage.removeItem("sro-refresh");
      localStorage.removeItem("userId");
    })
    .catch((err) => console.error(err));
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const checkAuthTimeout = (expirationTime: number) => {
  return (dispatch: any) => {
    setTimeout(() => {
      refreshToken(localStorage.getItem("sro-refresh")!)
        .then(async (res) => {
          const decoded: DecodedAuthToken = jwtDecode(res.data.access_token);
          const expirationDate = new Date(0);
          expirationDate.setUTCSeconds(decoded.exp);
          localStorage.setItem("sro-token", res.data.access_token);
          localStorage.setItem("sro-expirationDate", expirationDate.toString());
          localStorage.setItem("sro-refresh", res.data.refresh_token);
          dispatch(
            authSuccess({
              userId: decoded.id,
              token: res.data.access_token,
              organizationId: decoded.organization.id,
              role: decoded.organization.role,
            })
          );
          dispatch(checkAuthTimeout(res.data.expires));
        })
        .catch(() => dispatch(logout()));
    }, expirationTime * 1000);
  };
};

export const authCheckState = () => {
  return async (dispatch: any) => {
    dispatch(authStart());
    const token = localStorage.getItem("sro-token");
    if (!token) {
      dispatch(logout());
    } else {
      const expirationDate = new Date(
        localStorage.getItem("sro-expirationDate") || ""
      );
      if (expirationDate <= new Date()) {
        dispatch(logout());
      } else {
        const decoded: DecodedAuthToken = jwtDecode(token);
        dispatch(
          authSuccess({
            userId: decoded.id,
            token,
            organizationId: decoded.organization.id,
            role: decoded.organization.role,
          })
        );
        dispatch(
          checkAuthTimeout(
            (expirationDate.getTime() - new Date().getTime()) / 1000
          )
        );
      }
    }
  };
};

export const login = (email: string, password: string) => {
  return (dispatch: any) => {
    dispatch(authStart());
    const authData = {
      username: email,
      password: password,
    };
    getTokenUsernamePassword(authData.username, authData.password)
      .then(async (res) => {
        const decoded: DecodedAuthToken = jwtDecode(res.data.access_token);
        const expirationDate = new Date(0);
        expirationDate.setUTCSeconds(decoded.exp);
        localStorage.setItem("sro-token", res.data.access_token);
        localStorage.setItem("sro-expirationDate", expirationDate.toString());
        localStorage.setItem("sro-refresh", res.data.refresh_token);
        dispatch(
          authSuccess({
            userId: decoded.id,
            token: res.data.access_token,
            organizationId: decoded.organization.id,
            role: decoded.organization.role,
          })
        );
        dispatch(checkAuthTimeout(res.data.expires));
      })
      .catch((err) => {
        dispatch(authFail(err));
      });
  };
};
