import { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { object, string, number, array, date } from "yup";
import { SocketContext } from "../../../socket";
import { getGroups } from "../../../api/organization";
import { getAllUsers } from "../../../api/user";
import { getSchedulePresettings } from "../../../api/schedule";

import { ReduxState } from "../../../shared/Types";
import { compareHours, toIsoString, useTranslatedDates } from "../../../shared/utility";

import Switch from "../../../components/UI/Switch/Switch";
import Input from "../../../components/Form/Input";
import Select from "../../../components/Form/Select";
import { IOrganizationUser } from "../../../types/organization";

import ModalContainer from "../../../components/UI/ModalContainer";
import Button from "../../../components/UI/NewButton";
import { useTranslation } from "react-i18next";
const PresettingsModal = ({ onClose, initialFromDate }: any): JSX.Element => {
  const { t } = useTranslation();
  const translate = (key: string) => t(`translations:settingsPage.${key}`);
  const { start } = useContext(SocketContext);

  const organizationId = useSelector(
    (state: ReduxState) => state.auth.organizationId
  );

  //presettings data
  const [loadingPresettings, setLoadingPresetings] = useState(true);
  const [presettings, setPresettings] = useState<SchedulePresetting[]>([]); //todo: use set presetting (when retrieving data from the API)
  const [selectedPresetting, setSelectedPresetting] =
    useState<SchedulePresetting | null>(null);

  const [staffOptions, setStaffOptions] = useState<any>([]);
  const [groupOptions, setGroupOptions] = useState<any>([]);
  const [filterItemsVisible, setFilterItemsVisible] = useState(false);

  const meetConditionOptions = [
    { label: translate("sameGroup"), value: "SAME_GROUP" },
    { label: translate("sameLanguage"), value: "SAME_LANGUAGE" },
    { label: translate("noAllergies"), value: "NO_ALLERGIES" },
    { label: translate("specificStaff"), value: "STRICT_SPECIFIC" },
  ];
  const meetConditionOptionsMapper = {
    SAME_GROUP: {
      label: translate("sameGroup"),
      value: "SAME_GROUP",
    },
    SAME_LANGUAGE: { label: translate("sameLanguage"), value: "SAME_LANGUAGE" },
    NO_ALLERGIES: { label: translate("noAllergies"), value: "NO_ALLERGIES" },
    STRICT_SPECIFIC: { label: translate("strictSpecific"), value: "STRICT_SPECIFIC" },
  };
  const dualStaffingConditionsOptions = [
    { label: translate("connectedWorkShifts"), value: "CONNECTED_WORK_SHIFTS" },
    {
      label: translate("availableForDualStaffing"),
      value: "AVAILABLE_FOR_DUAL_STAFFING",
    },
    { label: "Anyone", value: "ANYONE" },
  ];
  const algorithmPrioritizationOptions = [
    { label: translate("continuity"), value: "CONTINUITY" },
    { label: translate("sameGroup"), value: "SAME_GROUP" },
    { label: translate("efficiency"), value: "EFFICIENCY" },
    { label: translate("distances"), value: "DISTANCES" },
    { label: translate("keepSame"), value: "KEEP_SAME" },
    { label: translate("duration"), value: "DURATION" },
    { label: translate("numberOfEvents"), value: "EVENTS" },
  ];
  const algorithmPrioritizationOptionsMapper = {
    CONTINUITY: { label:  translate("continuity"), value: "CONTINUITY" },
    SAME_GROUP: { label: translate("sameGroup"), value: "SAME_GROUP" },
    EFFICIENCY: { label: translate("efficiency"), value: "EFFICIENCY" },
    DISTANCES: { label: translate("distances"), value: "DISTANCES" },
    KEEP_SAME: { label: translate("keepSame"), value: "KEEP_SAME" },
    DURATION: { label: translate("duration"), value: "DURATION" },
    EVENTS: { label: translate("numberOfEvents"), value: "EVENTS" },
  };

  const renderSectionName = (sectionName: string, customStyles?: string) => (
    <div className={customStyles}>
      <p className={`bold-text self-start`}>{sectionName}</p>
      <div className="divider-line" />
    </div>
  );

  const callGetPresettings = () => {
    setLoadingPresetings(true);
    getSchedulePresettings(organizationId)
      .then((res: any) => {
        setPresettings(res.data);
        if (res.data[0]) {
          setSelectedPresetting(res.data[0]);
        }
        setLoadingPresetings(false);
      })
      .catch(() => {
        setLoadingPresetings(false);
      });
  };

  const callGetAllUsers = () => {
    getAllUsers(organizationId)
      .then((res) => {
        // const clients = res.data.filter((user) => user.role === "CLIENT");
        const staff = res.data.filter((user) => user.role === "STAFF");
        // const newClientOptions = clients.map((client: IOrganizationUser) => {
        //   return {
        //     label: `${client.user.firstName} ${client.user.lastName}`,
        //     value: client.user.id,
        //   };
        // });
        // setClientOptions(newClientOptions);
        const newStaffOptions = staff.map((staff: IOrganizationUser) => {
          return {
            label: `${staff.user.firstName} ${staff.user.lastName}`,
            value: staff.user.id,
          };
        });
        setStaffOptions(newStaffOptions);
      })
      .catch(() => {});
  };

  const callGetAllGroups = () => {
    getGroups(organizationId)
      .then((res) => {
        const newGroupOptions = res.data.map((group) => {
          return {
            label: group.name,
            value: group.id,
          };
        });
        setGroupOptions(newGroupOptions);
      })
      .catch(() => {});
  };

  useEffect(() => {
    callGetPresettings();
    callGetAllUsers();
    callGetAllGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const emptyPresettings =
    !loadingPresettings && !presettings.length && !selectedPresetting;

  const schema = object().shape({
    name: string()
      .required(translate("nameIsRequired"))
      .min(1, translate("nameCanNotBeEmpty")),
    nDays: number()
      .min(1, translate("canNotBeLessThan1Day"))
      .max(7, translate("canNotBeMoreThan7Days")),
    color: string().optional(), //TODO: maybe add validation for the color, to be different from other presetting colors
    startTime: string().test(
      translate("notEmpty"),
      translate("startTimeCanNotBeEmpty"),
      function (value) {
        return !!value;
      }
    ),
    endTime: string().test(
      "start time test",
      "End time must be after start time",
      function (end) {
        const { startTime } = this.parent;
        if (!end || !startTime) {
          return false;
        }
        return compareHours({ startHour: startTime, endHour: end });
      }
    ),
    meetConditions: array().min(1, "You need to select at least 1 condition"),
    dualStaffCondition: object().required(),
    algorithmPrioritization: array().min(
      1,
      "You need to select at least 1 condition"
    ),
    userFilter: array().optional(),
    groupFilter: array().optional(),
    fromDate: date().required("From date is required"),
  });

  const getFormInitialValues = () => {
    return {
      fromDate: initialFromDate.toISODate(),
      name: selectedPresetting?.name || "",
      color: selectedPresetting?.color || "",
      userFilter: (function dynamicallySetInitialValues() {
        if (
          selectedPresetting?.userFilter &&
          selectedPresetting.userFilter.length
        ) {
          let keyedOptions: any = {};
          for (const option of staffOptions) {
            keyedOptions[option.value] = option;
          }
          return selectedPresetting!.userFilter.map((id) => keyedOptions[id]);
        } else {
          return [];
        }
      })(),
      groupFilter: (function dynamicallySetInitialValues() {
        if (
          selectedPresetting?.groupFilter &&
          selectedPresetting.groupFilter.length
        ) {
          let keyedOptions: any = {};
          for (const option of groupOptions) {
            keyedOptions[option.value] = option;
          }
          return selectedPresetting!.groupFilter.map((id) => keyedOptions[id]);
        } else {
          return [];
        }
      })(),
      startTime: selectedPresetting?.timeSpan.startTime || "",
      endTime: selectedPresetting?.timeSpan.endTime || "",
      meetConditions: selectedPresetting?.meetConditions
        ? selectedPresetting.meetConditions.map(
            (meetCondition) => meetConditionOptionsMapper[meetCondition]
          )
        : [],
      dualStaffCondition: selectedPresetting?.dualStaffCondition
        ? dualStaffingConditionsOptions.filter(
            (option: any) =>
              option.value === selectedPresetting.dualStaffCondition
          )[0]
        : null,
      algorithmPrioritization: selectedPresetting?.algorithmPrioritization
        ? selectedPresetting.algorithmPrioritization.map(
            (algPrior) => algorithmPrioritizationOptionsMapper[algPrior]
          )
        : [],
      nDays: selectedPresetting?.nDays || 0,
    };
  };

  const handleFormSubmit = (values: any) => {
    const {
      fromDate,
      algorithmPrioritization,
      dualStaffCondition,
      meetConditions,
      nDays,
      // name,
      startTime,
      endTime,
      groupFilter,
      userFilter,
    } = values;

    var toUTC = function (date: Date) {
      var newDate = new Date();
      newDate.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
      return newDate;
    };

    const tempDate = toUTC(new Date(fromDate));
    const toDateObj = new Date(
      tempDate.getTime() + (nDays - 1) * 24 * 60 * 60 * 1000
    );
    const toDateString = toIsoString(toDateObj).split("T")[0];
    const to = `${toDateString}T${endTime}Z`;
    const from = `${fromDate}T${startTime}Z`;
    const algPrioritizationList = algorithmPrioritization.map(
      ({ value }: any) => value
    );
    const body = {
      from,
      to,
      ...(algPrioritizationList.length && {
        algorithmPrioritization: algPrioritizationList[0],
      }),
      maxTimePerDay: 5,
      minimumTravelTime: 5,
      ...(userFilter.length && {
        userFilter: userFilter.map(({ value }: any) => value),
      }),
      ...(groupFilter.length && {
        groupFilter: groupFilter.map(({ value }: any) => value),
      }),
      meetConditions: meetConditions.map(({ value }: any) => value),
      dualStaffCondition: dualStaffCondition.value,
    };
    start({ ...body });
    onClose();
  };

  const {
    handleBlur,
    handleSubmit,
    setFieldValue,
    values,
    errors = {},
    touched,
  } = useFormik({
    validationSchema: schema,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true, //IMPORTANT: this allows to reset the form data when another presetting is selected
    initialValues: getFormInitialValues(),
    onSubmit: handleFormSubmit,
  });

  const renderFilterFields = (): JSX.Element => (
    <>
      {renderSectionName("Filter", "mb-4 mt-8")}

      <Select
        id={"userFilter"}
        error={String(errors.userFilter || "")}
        touched={!!touched.userFilter}
        labelAbove={"User filter"}
        options={staffOptions}
        value={values.userFilter}
        selectType={"multiple"}
        onChange={(value: any) => setFieldValue("userFilter", value)}
      />
      <div className="mb-4" />
      <Select
        id={"groupFilter"}
        error={String(errors.groupFilter || "")}
        touched={!!touched.groupFilter}
        labelAbove={"Group filter"}
        options={groupOptions}
        value={values.groupFilter}
        selectType={"multiple"}
        onChange={(value: any) => setFieldValue("groupFilter", value)}
      />
    </>
  );
  const renderEditPresseting = (): JSX.Element => (
    <>
      <div className="flex w-full flex-col self-start mt-4 ">
        <div className="flex flex-1 gap-10">
          <Input
            id="name"
            value={values.name}
            placeholder="Enter presetting name"
            labelAbove={translate("name")}
            type="text"
            autoCapitalize="none"
            onChange={(e: any) => setFieldValue("name", e.target.value)}
            onBlur={handleBlur("name")}
            error={errors.name || ""}
            touched={touched.name || false}
          />
          <Input
            id="nDays"
            placeholder="Enter the number of days"
            value={String(values.nDays || "")}
            labelAbove="Antal dagar"
            type="number"
            min={1}
            max={7}
            autoCapitalize="none"
            onChange={(e: any) => setFieldValue("nDays", e.target.value)}
            onBlur={handleBlur("nDays")}
            error={String(errors.nDays || "")}
            touched={!!touched.nDays || false}
          />
        </div>

        <div className="flex flex-1 gap-10 mt-4">
          <Input
            id="startTime"
            placeholder={translate("enterTheStartTime")}
            value={values.startTime}
            labelAbove={translate("startTime")}
            type="time"
            autoCapitalize="none"
            onChange={(e: any) => setFieldValue("startTime", e.target.value)}
            onBlur={handleBlur("startTime")}
            error={errors.startTime || ""}
            touched={touched.startTime || false}
          />
          <Input
            id="endTime"
            placeholder={translate("enterTheEndTime")}
            value={values.endTime}
            labelAbove={translate("endTime")}
            type="time"
            autoCapitalize="none"
            onChange={(e: any) => setFieldValue("endTime", e.target.value)}
            onBlur={handleBlur("endTime")}
            error={errors.endTime || ""}
            touched={touched.endTime || false}
          />
        </div>

        {renderSectionName("Krav", "mb-4 mt-8")}

        <>
          <Select
            id="meetConditions"
            error={String(errors.meetConditions || "")}
            touched={!!touched.meetConditions}
            labelAbove={translate("minimumRequirementsForStaffAndClientToMeet")}
            options={meetConditionOptions}
            value={values.meetConditions}
            selectType={"multiple"}
            onChange={(value: any) => setFieldValue("meetConditions", value)}
          />
          <div className="mb-4" />
          <Select
            error={String(errors.dualStaffCondition || "")}
            touched={!!touched.dualStaffCondition}
            labelAbove={translate("requirementForDualStaffing")}
            options={dualStaffingConditionsOptions}
            value={values.dualStaffCondition as any}
            selectType={"normal"}
            onChange={(value: any) =>
              setFieldValue("dualStaffCondition", value)
            }
          />
        </>
        {renderSectionName("Prioriteringar", "mb-4 mt-8")}
        <Select
          error={String(errors.algorithmPrioritization || "")}
          touched={!!touched.algorithmPrioritization}
          labelAbove={translate("algorithmPrioritizations")}
          options={algorithmPrioritizationOptions}
          value={values.algorithmPrioritization}
          selectType={"multiple"}
          onChange={(value: any) =>
            setFieldValue("algorithmPrioritization", value)
          }
        />

        <div className="mb-4" />

        <Switch
          label={translate("filter")}
          value={filterItemsVisible}
          onClick={() => setFilterItemsVisible(!filterItemsVisible)}
        />

        {filterItemsVisible && renderFilterFields()}
      </div>

      <Button
        title={translate("generateSchedule")}
        type="selected"
        customStyle="mt-20 self-center"
        onClick={handleSubmit}
      />
    </>
  );

  return (
    <ModalContainer
      onClose={onClose}
      customStyles="w-[60%]  h-[70%] overflow-scroll"
    >
      <div className="flex flex-col self-stretch">
        <p className={`bold-text self-start mt-4`}>Filter</p>
        <div className="divider-line mb-4" />
        <div className="flex justify-between items-center gap-6">
          <Input
            name={initialFromDate}
            value={values.fromDate}
            labelAbove={translate("selectADate")}
            type="date"
            onChange={(e: any) => setFieldValue("fromDate", e.target.value)}
            error={String(errors.fromDate || "")}
            touched={!!touched.fromDate}
          />

          <div className="flex self-stretch flex-col w-full relative">
            <div
              className="absolute w-6 h-6 rounded-xl top-[30px] right-14 z-10"
              style={{
                backgroundColor: selectedPresetting?.color || "#000",
              }}
            />
            <Select
              labelAbove={translate("selectAGeneratorsetting")}
              selectType="normal"
              error={""}
              touched={!!""}
              options={presettings.map((presetting) => ({
                label: presetting.name,
                value: presetting,
              }))}
              value={
                {
                  label: selectedPresetting?.name || "",
                  value: selectedPresetting?.id || "",
                } as any
              }
              onChange={({ value }: any) => {
                setSelectedPresetting(value);
              }}
            />
          </div>
        </div>

        {emptyPresettings && (
          <p className="normal-text mt-2">
           {translate("thereAreNoGroupsPleaseSelectAGroup")}
          </p>
        )}
        {!!selectedPresetting && renderEditPresseting()}
      </div>
    </ModalContainer>
  );
};

export default PresettingsModal;
