import Select from "react-select";
import MultiSelectSort from "./MultiSelect";
import CreatableSelect from "./CreatableSelect";

const styles = {
  input: (provided: any) => {
    return {
      ...provided,
      margin: 0,
      padding: 0,
      borderWidth: "0px",
    };
  },
  control: (provided: any) => ({
    ...provided,
    backgroundColor: "#f3f3f3",
    borderRadius: "20px",
    flexDirection: "row",
    padding: "4px",
    border: "none",
    boxShadow: "none",
  }),
  multiValue: (provided: any) => {
    return {
      ...provided,
      backgroundColor: "#11FFBD",
      borderRadius: "20px",
      paddingLeft: "8px",
      paddingRight: "0",
      fontWeight: "bold",
    };
  },
  multiValueRemove: (provided: any) => ({
    ...provided,
    borderTopRightRadius: "20px",
    borderBottomRightRadius: "20px",
    paddingRight: "4px",
    width: "24px",
  }),
};

const SelectMapper = (props: DefaultFieldProps & SelectProps): JSX.Element => {
  const { selectType, labelAbove, error, touched } = props;
  let SelectComponent;
  switch (selectType) {
    case "multiple":
      SelectComponent = MultiSelectSort;
      break;
    case "creatable":
      SelectComponent = CreatableSelect;
      break;
    case "normal":
      SelectComponent = Select;
      break;
    default:
      SelectComponent = Select;
  }

  return (
    <>
      <label className="bold-text text-xs mb-1">{labelAbove}</label>
      <SelectComponent styles={styles} {...props} />
      {error && touched && (
        <label className="normal-text text-red-500 text-right mt-[11px]">
          {error}
        </label>
      )}
    </>
  );
};

export default SelectMapper;
