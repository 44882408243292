import { Stack, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { publishDraftSchedule } from "../../api/schedule";
import Button from "../../components/UI/Button/Button";
import Input from "../../components/UI/Input/Input";

const PublishDraft = ({
  organizationId,
  initialFromDate,
}: {
  organizationId: string;
  initialFromDate: DateTime;
}) => {
  const [fromDate, setFromDate] = useState(initialFromDate);
  const [daysFromStartDate, setDaysFromStartDate] = useState("1");
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Stack alignItems="center" spacing={2}>
      <Typography>Publicera utkast</Typography>
      <Input
        type="date"
        label="Välj startdatum"
        value={fromDate.toISODate()}
        onChange={(e) => setFromDate(DateTime.fromISO(e.target.value))}
        textCentered
      />
      <Input
        type="number"
        label="Antal dagar (max 7)"
        min={1}
        max={7}
        value={daysFromStartDate}
        onChange={(e) => setDaysFromStartDate(e.target.value)}
        textCentered
      />
      <Button
        onClick={() => {
          publishDraftSchedule(
            organizationId,
            fromDate,
            fromDate.plus({ days: +daysFromStartDate - 1 })!
          )
            .then(() => {
              enqueueSnackbar("Utkast publicerat", { variant: "success" });
            })
            .catch(() => {
              enqueueSnackbar("Något gick fel när utkastet skulle publiceras", {
                variant: "error",
              });
            });
        }}
        label="Publicera"
        color="green"
      />
    </Stack>
  );
};

export default PublishDraft;
