import Dropdown from "../../UI/Dropdown/Dropdown";
import DropdownCreate from "../../UI/DropdownCreate/DropdownCreate";
import Input from "../../UI/Input/Input";
import "./NameSex.scss";
import { useTranslation } from "react-i18next";
type NameSexProps = {
  firstName: string;
  onFirstNameChange: (firstName: string) => void;
  lastName: string;
  onLastNameChange: (lastName: string) => void;
  gender: string;
  onGenderChange: (gender: string) => void;
  employmentType?: string;
  onEmploymentTypeChange?: (employmentType: string) => void;
  healthCenter?: string;
  onHealthCenterChanged?: (healthCenter: string) => void;
  onHealthCenterAdded?: (healthCenterName: string) => void;
  healthCenters?: { value: string; label: string }[];
};

const NameSex = (props: NameSexProps) => {
  const { t } = useTranslation();
  const translate = (key: string) => t(`translations:userPage.${key}`);
  const genderOptions = [
    {
      label: translate("male"),
      value: "MALE",
    },
    {
      label: translate("female"),
      value: "FEMALE",
    },
    {
      label: translate("other"),
      value: "OTHER",
    },
  ];

  const employmentOptions = [
    { label: "Fastanställd", value: "PERMANENT" },
    { label: "Timanställd", value: "HOURLY" },
  ];
  return (
    <div className="name-sex">
      <div className="name-sex--title">{translate("personalInformation")}</div>
      <div className="name-sex--inputs">
        <Input
          label={translate("firstName")}
          type="text"
          value={props.firstName}
          onChange={(e) => props.onFirstNameChange(e.target.value)}
        />
        <Input
          label={translate("lastName")}
          type="text"
          value={props.lastName}
          onChange={(e) => props.onLastNameChange(e.target.value)}
        />
        <Dropdown
          options={genderOptions}
          title={translate("gender")}
          value={props.gender}
          onSelect={props.onGenderChange}
          showTitle
        />
        {props.onEmploymentTypeChange && (
          <Dropdown
            options={employmentOptions}
            title={translate("employementType")}
            onSelect={props.onEmploymentTypeChange}
            value={props.employmentType || ""}
            showTitle
          />
        )}
        {props.onHealthCenterChanged && (
          <DropdownCreate
            options={props.healthCenters || []}
            title={translate("localHospital")}
            onSelect={props.onHealthCenterChanged}
            value={props.healthCenter || ""}
            showTitle
            isSearchable
            onCreateOption={props.onHealthCenterAdded}
          />
        )}
      </div>
    </div>
  );
};

export default NameSex;
