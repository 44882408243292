import "./Textarea.scss";

type TextareaProps = {
  label: string;
  value: string;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement>;
};

const Textarea = (props: TextareaProps) => {
  return (
    <div className="textarea">
      <div className="textarea--label">{props.label}</div>
      <textarea value={props.value} onChange={props.onChange} />
    </div>
  );
};

export default Textarea;
