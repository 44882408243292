import { useState } from "react";
import EditScheduledEvent from "../../../../components/Settings/Client/ScheduledEvents/EditScheduledEvent/EditScheduledEvent";
import Button from "../../../../components/UI/Button/Button";
import Popup from "../../../../hoc/Popup/Popup";
import { IScheduledEvent } from "../../../../types/event";
import "./OverviewEventPopup.scss";

type OverviewEventPopupProps = {
  description: string;
  time: string;
  address: string;
  person: string;
  under: boolean;
  show: boolean;
  scheduledEvent: IScheduledEvent;
  onUpdated: () => void;
};

const OverviewEventPopup = (props: OverviewEventPopupProps) => {
  const [showEditPopup, setShowEditPopup] = useState(false);
  const overviewPopup = ["overview-popup"];
  if (props.under) {
    overviewPopup.push("under");
  }
  if (props.show) {
    overviewPopup.push("show");
  }

  return (
    <div className={overviewPopup.join(" ")}>
      <p className="overview-popup--description">{props.description}</p>
      <div className="overview-popup--info">
        <i className="far fa-clock" />
        <p className="overview-popup--info__text">{props.time}</p>
      </div>
      <div className="overview-popup--info">
        <i className="far fa-map" />
        <p className="overview-popup--info__text">{props.address}</p>
      </div>
      <div className="overview-popup--info">
        <i className="far fa-user" />
        <p className="overview-popup--info__text">{props.person}</p>
      </div>
      <Button label="Ändra" color="green" style={{ width: '100%', marginTop: '1rem' }} onClick={() => setShowEditPopup(true)} />
      <Popup
        showPopup={showEditPopup}
        onClose={() => {
          setShowEditPopup(false);
        }}
      >
        <EditScheduledEvent onSaved={() => {
          setShowEditPopup(false);
          props.onUpdated();
        }} scheduledEvent={props.scheduledEvent} />
      </Popup>
    </div>
  );
};

export default OverviewEventPopup;
