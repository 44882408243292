import { DateTime } from "luxon";
import { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { Calendar, luxonLocalizer, Views } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.scss";
import { FaEdit } from "react-icons/fa";
import { useSnackbar } from "notistack";
import "./customstyles.css";
import { getDuration, toIsoString, dateAdd } from "../../shared/utility";
import { ReduxState } from "../../shared/Types";
import { getAllUsers } from "../../api/user";
import { getScheduledEvents } from "../../api/schedule";
import { updateScheduleEvent, createScheduledEvent } from "../../api/schedule";
import { transformEvents, transformUsers } from "./scheduleResourcesTransform";

import MissedEvents from "../ScheduleOverview/MissedEvents";

import ModalContainer from "../../components/UI/ModalContainer";
import TabSelector from "../../components/UI/TabSelector";
import CustomToolbar from "../../components/UI/TimeSelector/CustomReactBigCalendarToolbar";
import Select from "../../components/Form/Select";
const localizer = luxonLocalizer(DateTime);

const DragAndDropCalendar = withDragAndDrop(Calendar as any);

export type CalendarEvent = {
  id: string;
  title: string;
  description: string;
  allDay?: boolean;
  start: Date;
  end: Date;
  isSelected: boolean;
};

const EventComponent = ({ event }: any) => {
  const formatDate = (date: Date) =>
    date.toISOString().split("T")[1].slice(0, 5);
  const { start, end, title } = event;
  const bgColor = event.color !== "" ? event.color : "#0099e9";
  const stDate = formatDate(start);
  const enDate = formatDate(end);
  const formattedTitle = `${title}, ${stDate}-${enDate}`;

  return (
    <div
      className={`w-full h-full truncate rounded-md p-1 flex flex-col`}
      style={{ backgroundColor: `${bgColor}70` }}
    >
      <p className="text-black font-normal text-[12px]"> {formattedTitle}</p>
    </div>
  );
};

const ScheduleEdit = (): JSX.Element => {
  const { organizationId } = useSelector((state: ReduxState) => state.auth);
  const [scheduleType, setScheduleType] = useState<"LIVE" | "STAGING">("LIVE");
  const [events, setEvents] = useState<CalendarEvent[]>([]);
  const [allStaff, setAllStaff] = useState<any[]>([]);
  const [allClients, setAllClients] = useState<any[]>([]);
  const [calendarResources, setCalendarResources] = useState<any[]>(allStaff);
  const [staffEditShown, setStaffEditShown] = useState(false);
  const [tempEventToCreate, setTempEventToCreate] = useState<any>();
  const [eventCreateModalShown, setEventCreateModalShown] = useState(false);
  // const [loadingEvents, setLoadingEvents] = useState(false);
  // const [loadingUsers, setLoadingUsers] = useState(false);
  // const [loadingUpdatingEvent, setLoadingUpdatingEvent] = useState(false);
  const [draggableEvents, setDraggableEvents] =
    useState<CalendarEvent[]>(events);
  const [dates, setDates] = useState([new Date(), new Date()]);
  const [draggingMissedEvent, setDraggingMissedEvent] = useState<NewEvent>(
    null!
  );
  console.log("draggingMissedEvent: ", draggingMissedEvent);

  const { enqueueSnackbar } = useSnackbar();

  const callGetAllUsers = () => {
    // setLoadingUsers(true);
    getAllUsers(organizationId)
      .then((res) => {
        const { staff, clients } = transformUsers(res.data);
        setAllClients(clients);
        setAllStaff(staff);
        setCalendarResources(staff);
      })
      .catch(() => {
        // setLoadingUsers(false);
      });
  };

  const callGetEvents = () => {
    // setLoadingEvents(true);
    let fromDate = new Date(dates[0].toDateString());
    let toDate = new Date(dates[1].toDateString());
    fromDate.setHours(0, 0, 0, 0);
    toDate.setHours(23, 59, 59, 999);

    getScheduledEvents(
      organizationId,
      fromDate.toISOString(),
      toDate.toISOString(),
      scheduleType
    )
      .then((res) => {
        const calendarEvents = transformEvents(res.data);
        setEvents(calendarEvents);
        setDraggableEvents(calendarEvents);
        // setLoadingEvents(false);
      })
      .catch(() => {
        // setLoadingEvents(false);
      });
  };

  useEffect(() => {
    callGetAllUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    callGetEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dates]);

  const moveEvent = useCallback(
    ({
      event,
      start,
      end,
      resourceId,
      isAllDay: droppedOnAllDaySlot = false,
    }) => {
      //call the callback on success
      const callback = () =>
        setDraggableEvents((prev: any) => {
          const { allDay } = event;
          if (!allDay && droppedOnAllDaySlot) {
            event.allDay = false;
          }
          const existing =
            prev.find((ev: CalendarEvent) => ev.id === event.id) ?? {};
          const filtered = prev.filter(
            (ev: CalendarEvent) => ev.id !== event.id
          );
          return [...filtered, { ...existing, start, end, resourceId }];
        });
      //TODO: remove the staff with the old resource ID and add the new one
      const eventIDToUpdate = event.clientEventID || event.id;

      const formattedStart = toIsoString(start);
      const formattedEnd = toIsoString(end);

      const allOldStaff = event.staff.map((staff: any) => {
        return {
          userID: staff.userID,
          timeSpan: {
            start: formattedStart,
            end: formattedEnd,
          },
        };
      });
      const clearedStaff = allOldStaff.filter(
        (staff: any) => staff.userID !== event.user.id
      );
      updateScheduleEvent(eventIDToUpdate, {
        staff: [
          ...clearedStaff,
          {
            userID: resourceId,
            timeSpan: {
              start: formattedStart,
              end: formattedEnd,
            },
          },
        ],
        timeSpan: {
          start: formattedStart,
          end: formattedEnd,
        },
      })
        .then(() => {
          enqueueSnackbar("Successfully updated event", {
            variant: "success",
            autoHideDuration: 1000,
          });
          callback();
        })
        .catch(() => {
          enqueueSnackbar("Failed to update event", {
            variant: "error",
            autoHideDuration: 1000,
          });
        });
    },

    [setDraggableEvents, enqueueSnackbar]
  ); // eslint-disable-next-line react-hooks/exhaustive-deps

  const resizeEvent = useCallback(
    ({ event, start, end }) => {
      console.log("start: ", start);

      console.log("end: ", end);
      const callback = () =>
        setDraggableEvents((prev: any) => {
          const existing =
            prev.find((ev: CalendarEvent) => ev.id === event.id) ?? {};
          const filtered = prev.filter(
            (ev: CalendarEvent) => ev.id !== event.id
          );
          return [...filtered, { ...existing, start, end }];
        });
      const eventIDToUpdate = event.clientEventID || event.id;
      const formattedStart = toIsoString(start);
      const formattedEnd = toIsoString(end);
      const updatedStaff = event.staff.map((staff: any) => {
        return {
          userID: staff.userID,
          timeSpan: {
            start: formattedStart,
            end: formattedEnd,
          },
        };
      });

      updateScheduleEvent(eventIDToUpdate, {
        staff: updatedStaff,
        timeSpan: {
          start: start.toISOString(),
          end: end.toISOString(),
        },
      })
        .then(() => {
          enqueueSnackbar("Successfully updated event", {
            variant: "success",
            autoHideDuration: 1000,
          });
          callback();
        })
        .catch(() => {
          enqueueSnackbar("Failed to update event", {
            variant: "error",
            autoHideDuration: 1000,
          });
        });
    },
    [setDraggableEvents, enqueueSnackbar]
  ); // eslint-disable-next-line react-hooks/exhaustive-deps

  const handleSelectSlot = useCallback(
    ({ start, end, resourceId }) => {
      setTempEventToCreate({
        start,
        end,
        resourceId,
      });
      setEventCreateModalShown(true);
      const title = window.prompt("New Event name");
      const description = window.prompt("New Event Description");

      if (title && description) {
        const callback = () =>
          setDraggableEvents((prev: any) => [
            ...prev,
            { start, end, title, resourceId },
          ]);

        const timeSpan = {
          start: toIsoString(start) as string,
          end: toIsoString(end) as string,
        };

        const staff = [
          {
            userID: resourceId,
            timeSpan,
          },
        ];

        const userId = "the user id is the client's id";

        createScheduledEvent({
          userId,
          organizationId,
          name: title,
          description,
          scheduleType,
          timeSpan,
          staff,
          failed: false,
        })
          .then(() => {
            callback();
            enqueueSnackbar("Successfully created scheduled event", {
              variant: "success",
              autoHideDuration: 1000,
            });
            callGetEvents();
          })
          .catch(() => {
            enqueueSnackbar("Failed to create scheduled event", {
              variant: "error",
              autoHideDuration: 1000,
            });
          });
      }
    },
    [setDraggableEvents, enqueueSnackbar, organizationId]
  ); // eslint-disable-next-line react-hooks/exhaustive-deps

  const handleDropFromOutside = (object: any) => {
    const { start, resource } = object;
    console.log("draggingMissedEvent: ", draggingMissedEvent);

    if (!draggingMissedEvent) {
      return;
    }
    const {
      description,
      name,
      scheduleType,
      timeSpan: oldTimeSpan,
      user,
    } = draggingMissedEvent;
    let userId;
    if (typeof user === "object") {
      userId = user.id;
    } else {
      userId = user;
    }
    const startHour = oldTimeSpan.start.split("T")[1].slice(0, 5);
    const endHour = oldTimeSpan.end.split("T")[1].slice(0, 5);
    const duration = getDuration(startHour, endHour);
    const end = dateAdd(start, "minute", duration);
    const timeSpan: any = {
      start: toIsoString(start),
      end: toIsoString(end!),
    };

    const staff = [
      {
        userID: resource,
        timeSpan,
      },
    ];

    createScheduledEvent({
      userId,
      organizationId,
      name: name ?? "",
      description: description ?? "",
      staff,
      timeSpan,
      failed: false,
      scheduleType,
    })
      .then(() => {
        enqueueSnackbar("Sucessfully re-scheduled missed event", {
          variant: "success",
          autoHideDuration: 1000,
        });
        callGetEvents();
      })
      .catch(() =>
        enqueueSnackbar("Failed to re-schedule event", {
          variant: "error",
          autoHideDuration: 1000,
        })
      );
  };

  const renderStaffSelector = () => {
    return (
      <Select
        selectType="multiple"
        onChange={(selections: any[]) => {
          setCalendarResources(
            selections.map((selection) => ({
              staffName: selection.label,
              staffId: selection.value,
            }))
          );
        }}
        labelAbove="Select or arrange calendar's Staff"
        error=""
        touched={false}
        value={calendarResources.map((staff) => ({
          label: staff.staffName,
          value: staff.staffId,
        }))}
        options={allStaff.map((staff) => ({
          label: staff.staffName,
          value: staff.staffId,
        }))}
      />
    );
  };
  return (
    <>
      <div className="card-container items-start h-[50%] overflow-scroll">
        <p className="bold-text text-base mb-4">Edit schedule</p>
        <div className="flex flex-row self-stretch justify-between items-center">
          <TabSelector
            options={[
              { label: "Aktuell", value: "LIVE" },
              { label: "Utkast", value: "STAGING" },
            ]}
            onTabChange={(value) =>
              setScheduleType(value as "LIVE" | "STAGING")
            }
          />
          <div>
            <FaEdit
              className="hover:cursor-pointer float-right"
              onClick={() => setStaffEditShown(!staffEditShown)}
            />
            {staffEditShown && renderStaffSelector()}
          </div>
        </div>

        <DragAndDropCalendar
          className="mt-8 w-full"
          startAccessor="start"
          endAccessor="end"
          components={{
            toolbar: CustomToolbar,
            event: EventComponent,
          }}
          defaultDate={dates[0]}
          onRangeChange={(range: any) => {
            if (typeof range === "object") {
              //the selection is Monthly || Weekly || Agenda
              if (range.start) {
                //the range is an object and the selection is Monthly || Agenda
                setDates([range.start, range.end]);
              } else {
                //the range is an array and the selection is Weekly
                const rangeStart = range[0];
                const rangeEnd = range[range.length - 1];
                setDates([rangeStart, rangeEnd]);
              }
            } else {
              //the range is a date selection is Views.DAY
              setDates([range, range]);
            }
          }}
          defaultView={Views.DAY}
          onDropFromOutside={handleDropFromOutside}
          events={draggableEvents}
          onEventDrop={moveEvent}
          onSelectSlot={handleSelectSlot}
          onEventResize={resizeEvent}
          resourceIdAccessor={(resource: any) => resource.staffId}
          resourceTitleAccessor={(resource: any) => resource.staffName}
          resources={calendarResources}
          showMultiDayTimes={true}
          localizer={localizer}
          resizable={true}
          selectable={true}
          popup={true}
          step={45}
        />
      </div>
      <MissedEvents
        date={DateTime.fromJSDate(dates[0])}
        scheduleType={0}
        draggableEvents={true}
        onEventDrag={(event: NewEvent) => setDraggingMissedEvent(event)}
      />
      {/* {eventCreateModalShown && (
        <ModalContainer onClose={() => setEventCreateModalShown(false)}>
          <Select
            selectType="normal"
            value={}
            options={allClients.map(({ clientName, clientId }: any) => {
              return {
                label: clientName,
                value: clientId,
              };
            })}
          />
        </ModalContainer>
      )} */}
    </>
  );
};

export default ScheduleEdit;
