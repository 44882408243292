import { useRef } from "react";
import { FaSearch } from "react-icons/fa";

type SearchInputProps = {
  onChange: (term: string) => void;
  placeHolder: string;
};

const SearchInput = ({
  onChange,
  placeHolder,
}: SearchInputProps): JSX.Element => {
  const input = useRef<HTMLInputElement>(null);
  return (
    <div
      onClick={() => {
        input.current && input.current.focus();
      }}
      className="flex flex-row pl-4 pr-4 bg-grey rounded-3xl h-[40px] items-center"
    >
      <FaSearch className="mr-2" />
      <input
        placeholder={placeHolder}
        ref={input}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        className="normal-text text-md bg-grey outline-none self-stretch w-full"
      />
    </div>
  );
};

export default SearchInput;
