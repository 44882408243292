import { IOrganizationUser } from "../../types/organization";

const transformEvents = (events: NewEvent[]) => {
  console.log("events: ", events);
  const userObj: any = {};
  for (const event of events) {
    if (typeof event.user === "object") {
      userObj[event.user.id] = event.user;
    }
  }
  const eventsWithUser = events.map((event) => {
    return {
      ...event,
      user: typeof event.user === "object" ? event.user : userObj[event.user],
      staff: event.staff.map((staff) => {
        return {
          ...staff,
          user: userObj[staff.userID],
        };
      }),
    };
  });

  const calendarEvents = eventsWithUser.map((event) => {
    //this is for displaying @ calendar
    return {
      id: event.id,
      user: event.user,
      title: `${event.user.firstName}, ${event.user.lastName}`,
      resourceId: event.user.id,
      clientEventID: event.clientEventID,
      description: event.name || "",
      start: new Date(event.timeSpan.start),
      end: new Date(event.timeSpan.end),
      isSelected: false,
      staff: event.staff,
      color: (function getColorIfPossible() {
        if (
          event?.user?.group &&
          event.user.group[0] &&
          event.user.group[0].color
        ) {
          return event.user.group[0].color;
        } else return "";
      })(),
    };
  });
  return calendarEvents.filter(
    (event) => !!event.resourceId && !!event.id && !!event.start && !!event.end
  );
};

const transformUsers = (users: IOrganizationUser[]) => {
  const staff = users.filter((user) => user.role === "STAFF");
  const clients = users.filter((user) => user.role === "CLIENT");
  const formattedStaff = staff.map(({ user }) => {
    return {
      staffId: user.id,
      staffName: `${user.firstName} ${user.lastName}`,
    };
  });
  const formattedClients = clients.map(({ user }) => {
    return {
      clientId: user.id,
      clientName: `${user.firstName} ${user.lastName}`,
    };
  });

  return {
    staff: formattedStaff,
    clients: formattedClients,
  };
};

export { transformEvents, transformUsers };
