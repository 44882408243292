import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import { ReduxState } from "../../../shared/Types";
import { getDomainOverride } from "../../../api/organization";
import { getOrgainzationUser, updateOrganizationUser } from "../../../api/user";
import {
  createOverride,
  updateOverride,
  createOverrideForEvent,
  updateOverrideForEvent,
} from "../../../api/schedule";
import transformOverride from "./transform";

import Table from "../../../components/Table";
import TabSelector from "../../../components/UI/TabSelector";
import useClientColumns from "./useClientColumns";
import useStaffColumns from "./useStaffColumns";
import useEventColumns from "./useEventColumns";
import { Column } from "react-table";
import AvailabilityModal from "./AvailablilityModal";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

enum ScheduleType {
  personal = "personal",
  kunder = "kunder",
  insatser = "insatser",
}

const ScheduleOverride = ({ date }: { date: DateTime }) => {
  const { t } = useTranslation();
  const translate = (key: string) => t(`translations:schedulePage.${key}`);
  const { enqueueSnackbar } = useSnackbar();

  const tabOptions = [
    { label: translate("navigationStaff"), value: ScheduleType.personal },
    { label: translate("navigationClient"), value: ScheduleType.kunder },
    { label: translate("navigationEvent"), value: ScheduleType.insatser },
  ];

  const getDefaulTimespan = () => {
    const jsDate = date.toJSDate();
    const day = jsDate.toISOString().split("T")[0];

    return {
      start: `${day}T00:00:00Z`,
      end: `${day}T23:59:59Z`,
    };
  };

  const emptyAvaliabilityModalValues = {
    name: "",
    available: true,
    notAvailableReason: "",
    overrideStart: "-",
    overrideEnd: "-",
    comment: "",
    id: "",
    overrideId: null,
    type: "event",
  };

  const { organizationId, userId } = useSelector(
    (state: ReduxState) => state.auth
  );

  const [selectedScheduleType, setSelectedScheduleType] = useState(
    ScheduleType.personal
  );
  const [staffData, setStaffData] = useState<StaffRow[]>([]);
  const [clientData, setClientData] = useState<ClientRow[]>([]);
  const [eventData, setEventData] = useState<EventRow[]>([]);
  const [loadingDomain, setLoadingDomain] = useState(true);
  const [loadingUser, setLoadingUser] = useState(false);
  const [savedClientCols, setSavedClientCols] = useState<string[] | null>(null);
  const [savedStaffCols, setSavedStaffCols] = useState<string[] | null>(null);
  const [savedEventCols, setSavedEventCols] = useState<string[] | null>(null);
  const [availabilityModalShown, setAvaliabliltyModalShown] = useState(false);
  const [availabilityModalValues, setAvailabilityModalValues] = useState(
    emptyAvaliabilityModalValues
  );
  const [loadingEditOverride, setLoadingEditOverride] = useState(false);

  const dataMapper = {
    [ScheduleType.personal]: staffData,
    [ScheduleType.kunder]: clientData,
    [ScheduleType.insatser]: eventData,
  };

  const openModal = (row: any, type = "user") => {
    setAvailabilityModalValues({
      name: row.name,
      available: row.available,
      notAvailableReason: row.notAvailableReason,
      overrideStart: row.overrideStart,
      overrideEnd: row.overrideEnd,
      comment: row.comment,
      id: row.id,
      overrideId: row.overrideId,
      type,
    });
    setAvaliabliltyModalShown(true);
  };
  const closeModal = () => {
    setAvailabilityModalValues(emptyAvaliabilityModalValues);
    setAvaliabliltyModalShown(false);
  };

  const onAvailabilityModalSubmit = (values: any, type: string) => {
    const { overrideId, userId, available, timeSpanToOverride, ...rest } =
      values;
    let timeSpanToSend: any;
    if (!timeSpanToOverride) {
      const { start, end } = getDefaulTimespan();
      timeSpanToSend = {
        start,
        end,
      };
    } else {
      timeSpanToSend = timeSpanToOverride;
    }

    if (type === "user") {
      if (overrideId) {
        callUpdateOverride({
          userId,
          overrideId,
          available,
          timeSpanToOverride: timeSpanToSend,
          ...rest,
        });
      } else {
        callCreateOverride({
          userId,
          available,
          timeSpanToOverride: timeSpanToSend,
          ...rest,
        });
      }
    } else {
      if (overrideId) {
        callUpdateOverrideEvent({
          eventId: userId,
          overrideId,
          occur: available,
          timeSpanToOverride: timeSpanToSend,
          ...rest,
        });
      } else {
        callCreateOverrideForEvent({
          eventId: userId,
          occur: available,
          timeSpanToOverride: timeSpanToSend,
          ...rest,
        });
      }
    }
  };

  const switchAvailability = (row: any) => {
    const { id, available, overrideId } = row;
    const { start, end } = getDefaulTimespan();
    const body = {
      timeSpanToOverride: {
        start,
        end,
      },
      available: !available,
    };

    if (overrideId) {
      //update
      callUpdateOverride({
        userId: id,
        overrideId,
        ...body,
      });
    } else {
      callCreateOverride({
        userId: id,
        ...body,
      });
    }
  };

  const switchAvailabilityForEvent = (row: any) => {
    const { id, available, overrideId } = row;
    const { start, end } = getDefaulTimespan();
    const body = {
      timeSpanToOverride: {
        start,
        end,
      },
      occur: !available,
    };

    if (overrideId) {
      //update
      callUpdateOverrideEvent({
        eventId: id,
        overrideId,
        ...body,
      });
    } else {
      callCreateOverrideForEvent({
        eventId: id,
        ...body,
      });
    }
  };

  const staffColumnsMapper = useStaffColumns({
    onAvailabilityCheck: switchAvailability,
    onAvailabilityEdit: openModal,
  });
  const staffColumns = Object.values(staffColumnsMapper);

  const clientColumnsMapper = useClientColumns({
    onAvailabilityCheck: switchAvailability,
    onAvailabilityEdit: openModal,
  });
  const clientColumns = Object.values(clientColumnsMapper);

  const eventColumnsMapper = useEventColumns({
    onAvailabilityCheck: switchAvailabilityForEvent,
    onAvailabilityEdit: (row) => openModal(row, "event"),
  });
  const eventColumns = Object.values(eventColumnsMapper);

  const filterStaffColumns = () => {
    if (!savedStaffCols || savedStaffCols?.length === 0) return staffColumns;
    const columns = savedStaffCols.map(
      (colAccessor: string) => staffColumnsMapper[colAccessor]
    );
    return columns.filter((c) => !!c);
  };

  const filterClientColumns = () => {
    if (!savedClientCols || savedClientCols?.length === 0) return clientColumns;
    const columns = savedClientCols.map(
      (colAccessor: string) => clientColumnsMapper[colAccessor]
    );
    return columns.filter((c) => !!c);
  };

  const filterEventColumns = () => {
    if (!savedEventCols || savedEventCols?.length === 0) return eventColumns;
    const columns = savedEventCols.map(
      (colAccessor: string) => eventColumnsMapper[colAccessor]
    );
    return columns.filter((c) => !!c);
  };

  const columnMapper = {
    [ScheduleType.personal]: {
      filtered: filterStaffColumns(),
      all: staffColumns as Column<any>[],
    },
    [ScheduleType.kunder]: {
      filtered: filterClientColumns(),
      all: clientColumns as Column<any>[],
    },
    [ScheduleType.insatser]: {
      filtered: filterEventColumns(),
      all: eventColumns as Column<any>[],
    },
  };

  const patchColMapper = {
    [ScheduleType.personal]: "staff",
    [ScheduleType.kunder]: "clients",
    [ScheduleType.insatser]: "events",
  };

  const callCreateOverrideForEvent = ({ eventId, ...body }: any) => {
    setLoadingEditOverride(true);
    createOverrideForEvent({
      eventId,
      ...body,
    })
      .then(() => {
        setLoadingEditOverride(false);

        enqueueSnackbar("Successfully created override", {
          variant: "success",
          autoHideDuration: 1000,
        });
        callGetDomainOverride();
        closeModal();
      })
      .catch(() => {
        setLoadingEditOverride(false);
        enqueueSnackbar("Failed to create override", {
          variant: "error",
          autoHideDuration: 1000,
        });
      });
  };

  const callCreateOverride = ({ userId, ...body }: any) => {
    setLoadingEditOverride(true);
    createOverride({
      organizationId,
      userId,
      ...body,
    })
      .then(() => {
        setLoadingEditOverride(false);
        enqueueSnackbar("Successfully created override", {
          variant: "success",
          autoHideDuration: 1000,
        });
        callGetDomainOverride();
        closeModal();
      })
      .catch(() => {
        setLoadingEditOverride(false);
        enqueueSnackbar("Failed to create override", {
          variant: "error",
          autoHideDuration: 1000,
        });
      });
  };

  const callUpdateOverrideEvent = ({ eventId, overrideId, ...body }: any) => {
    setLoadingEditOverride(true);
    updateOverrideForEvent({
      eventId,
      plannedEventOverrideId: overrideId,
      ...body,
    })
      .then(() => {
        setLoadingEditOverride(false);
        enqueueSnackbar("Successfully updated override", {
          variant: "success",
          autoHideDuration: 1000,
        });
        callGetDomainOverride();
        closeModal();
      })
      .catch(() => {
        setLoadingEditOverride(false);
        enqueueSnackbar("Failed to update override", {
          variant: "error",
          autoHideDuration: 1000,
        });
      });
  };

  const callUpdateOverride = ({ userId, overrideId, ...body }: any) => {
    setLoadingEditOverride(true);
    updateOverride({
      organizationId,
      userId,
      organizationUserOverrideId: overrideId,
      ...body,
    })
      .then(() => {
        setLoadingEditOverride(false);
        enqueueSnackbar("Successfully updated override", {
          variant: "success",
          autoHideDuration: 1000,
        });
        callGetDomainOverride();
        closeModal();
      })
      .catch(() => {
        setLoadingEditOverride(false);
        enqueueSnackbar("Failed to update override", {
          variant: "error",
          autoHideDuration: 1000,
        });
      });
  };

  const callGetDomainOverride = () => {
    setLoadingDomain(true);
    const { start, end } = getDefaulTimespan();
    getDomainOverride(organizationId, start, end)
      .then((res) => {
        const { clientRows, staffRows, eventRows } = transformOverride(
          res.data,
          date.toJSDate()
        );
        setClientData(clientRows);
        setStaffData(staffRows);
        setEventData(eventRows);
        setLoadingDomain(false);
      })
      .catch(() => {
        setLoadingDomain(false);
      });
  };

  const callGetOrganizationUser = () => {
    setLoadingUser(true);
    getOrgainzationUser({ userId, organizationId })
      .then((res) => {
        setLoadingUser(false);
        if (res.data.settings.scheduleOverrideColumns) {
          const cols = res.data.settings.scheduleOverrideColumns;
          if (cols.staff && cols.staff.length > 0) {
            setSavedStaffCols(Array.from(new Set(cols.staff)));
          }
          if (cols.clients && cols.clients.length > 0) {
            setSavedClientCols(Array.from(new Set(cols.clients)));
          }
          if (cols.events && cols.events.length > 0) {
            setSavedEventCols(Array.from(new Set(cols.events)));
          }
        }
      })
      .catch(() => {
        setLoadingUser(false);
      });
  };

  const callUpdateOrgainzationuser = (values: string[]) => {
    const settings = {
      scheduleOverrideColumns: {
        [patchColMapper[`${selectedScheduleType}`]]: values,
      },
    };
    updateOrganizationUser({ organizationId, userId, settings })
      .then(() => {
        callGetOrganizationUser();
        enqueueSnackbar("Successfully saved preference", {
          variant: "success",
          autoHideDuration: 1000,
        });
      })
      .catch(() => {
        enqueueSnackbar("Failed to saved preference", {
          variant: "error",
          autoHideDuration: 1000,
        });
      });
  };

  // const debounceUpdateGr = useCallback(debounce(submitForm, 500), []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    callGetOrganizationUser();
    callGetDomainOverride();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  return (
    <div className="card-container mt-4 flex flex-col">
      <div className="flex flex-1 self-start flex-row items-start">
        <div className="bold-text text-2xl mr-8">
          {translate("fastAdjustment")}
        </div>
        <TabSelector
          options={tabOptions}
          onTabChange={(scheduleType: string) => {
            setSelectedScheduleType(
              ScheduleType[scheduleType as keyof typeof ScheduleType]
            );
          }}
        />
      </div>
      <Table
        canEditColumns={true}
        onColumnsChange={(columnAccessors: any) => {
          callUpdateOrgainzationuser(Array.from(new Set(columnAccessors))); //rm duplicates //TODO:debounce
        }}
        title=" "
        data={dataMapper[`${selectedScheduleType}`]}
        columns={columnMapper[`${selectedScheduleType}`].all}
        filteredColumns={columnMapper[`${selectedScheduleType}`].filtered}
        sortable
        sortableColumns={columnMapper[`${selectedScheduleType}`].filtered.map(
          (column) => column.Header
        )}
        customStyle="mt-6"
        canFilter={true}
        emptyStateText={translate("emptyStateText")}
        loadingData={loadingDomain || loadingUser}
      />
      {availabilityModalShown && (
        <AvailabilityModal
          onClose={closeModal}
          name={availabilityModalValues.name}
          id={availabilityModalValues.id}
          date={date}
          available={availabilityModalValues.available}
          notAvailableReason={availabilityModalValues.notAvailableReason}
          comment={availabilityModalValues.comment}
          overrideId={availabilityModalValues.overrideId}
          onSubmit={onAvailabilityModalSubmit}
          loading={loadingEditOverride}
          start={availabilityModalValues.overrideStart}
          end={availabilityModalValues.overrideEnd}
          type={availabilityModalValues.type as "event" | "user"}
        />
      )}
    </div>
  );
};

export default ScheduleOverride;
