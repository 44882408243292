import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import "./NavigationItem.scss";

type NavigationItemProps = {
  label: string;
  link: string;
  routes: string[];
  exact?: boolean;
};

const NavigationItem = (props: NavigationItemProps) => {
  const location = useLocation();
  const active = props.routes.includes(location.pathname);
  return (
    <Link
      to={props.link}
      className={`navigation-item-wrapper waves-effect waves-dark ${
        active && "active"
      }`}
    >
      <div className="navigation-item">{props.label}</div>
    </Link>
  );
};

export default NavigationItem;
