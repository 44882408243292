/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { useFormik } from "formik";
import { object, string, number } from "yup";
import { FaLock, FaUnlock } from "react-icons/fa";
import {
  createPlannedEvent,
  updatePlannedEvent,
} from "../../../../../api/organization";
import { listEventTypes } from "../../../../../api/schedule";
import { getDuration, compareHours, useTranslatedDates } from "../../../../../shared/utility";
import { ReduxState } from "../../../../../shared/Types";
import { getWeekFromDate } from "../../../../../shared/utility";
import { IPlannedEvent } from "../../../../../types/event";
import { IOrganizationUser } from "../../../../../types/organization";
import { ITimeSpan } from "../../../../../types/time";
import Button from "../../../../UI/Button/Button";
import DayPicker, { IDayPickerWeek } from "../../../../UI/DayPicker/DayPicker";
import Dropdown from "../../../../UI/Dropdown/Dropdown";
import Input from "../../../../UI/Input/Input";
import FormInput from "../../../../Form/Input";
import Switch from "../../../../UI/Switch/Switch";
import Textarea from "../../../../UI/Textarea/Textarea";
import "./AddPlannedEvent.scss";
import { useSnackbar } from "notistack";
import IconButton from "../../../../UI/NewIconButton";
import Select from "../../../../Form/Select";
import { useTranslation } from "react-i18next";

type AddPlannedEventProps = {
  userId: string;
  onSaved: () => void;
  isEditing: boolean;
  editPannedEvent: IPlannedEvent | null;
  organizationId: string;
  staffs: IOrganizationUser[];
};

const AddPlannedEvent = (props: AddPlannedEventProps) => {
  const { t } = useTranslation();
  const translate = (key: string) => t(`translations:userPage.${key}`);
  const [onlyOnce, setOnlyOnce] = useState(false);
  const [needsSpecific, setNeedsSpecific] = useState(false);
  const [specificStaffs, setSpecificStaffs] = useState<
    { userID: string; duration?: number }[]
  >([]);
  const [hasSpecific, setHasSpecific] = useState(false);
  const [eventName, setEventName] = useState("");
  const [earliest, setEarliest] = useState("");
  const [latest, setLatest] = useState("");
  const [repeated, setRepeated] = useState("1");
  const [startWeek, setStartWeek] = useState(
    `${getWeekFromDate(new Date().toISOString())}`
  );
  const [description, setDescription] = useState("");
  const [duration, setDuration] = useState("");
  const [specificDate, setSpecificDate] = useState("");
  const [isWheneverWeek, setIsWheneverWeek] = useState(false);
  const [days, setDays] = useState<IDayPickerWeek>({
    monday: "",
    tuesday: "",
    wednesday: "",
    thursday: "",
    friday: "",
    saturday: "",
    sunday: "",
  });
  const [eventTypes, setEventTypes] = useState<EventType[]>([]);
  const [selectedEventType, setSelectedEventType] = useState<EventType | null>(
    null
  );

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    listEventTypes(props.organizationId).then((res) => {
      if (res.data.length) {
        setEventTypes(res.data);
        setSelectedEventType(res.data[0]);
      }
    });
  }, []);

  const calculateFieldDuration = (
    start: string | undefined,
    end: string | undefined
  ) => {
    if (start && end) {
      if (compareHours({ endHour: end, startHour: start })) {
        const duration = getDuration(start, end);
        return duration;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };

  const repetitionOptions = [
    { label: translate("everyWeek"), value: "1" },
    { label: translate("everyTwoWeek"), value: "2" },
    { label: translate("everyThreeWeek"), value: "3" },
    { label: translate("everyFourWeek"), value: "4" },
  ];

  const emptyDays = {
    monday: "",
    tuesday: "",
    wednesday: "",
    thursday: "",
    friday: "",
    saturday: "",
    sunday: "",
  };

  const schema = object().shape({
    name: string()
      .required(translate("nameIsrequired"))
      .min(1, translate("minimumLengthNameText")),
    start: string().test(
      translate("notEmpty"),
      translate("startTimeCanNotBeEmpty"),
      function (value) {
        return !!value;
      }
    ),
    end: string().test(
      "start time test",
      translate("endTimeMustBeAfterStartTime"),
      function (end) {
        const { start } = this.parent;
        if (!end || !start) {
          return false;
        }
        return compareHours({ startHour: start, endHour: end });
      }
    ),

    duration: number().min(1, translate("durationBiggerThan1Text")),
    repeated: object().required(translate("thisIsRequired")),
  });

  const getFormInitialValues = () => {
    return {
      name: selectedEventType?.name || "",
      color: selectedEventType?.color || "",
      description: selectedEventType?.description || "",
      externalID: selectedEventType?.externalID || "",
      start: (function setStartIfPossible() {
        if (
          selectedEventType?.timeSpan &&
          selectedEventType.timeSpan[0] &&
          selectedEventType?.timeSpan[0].startTime
        ) {
          return selectedEventType?.timeSpan[0].startTime.slice(0, 5);
        } else return "";
      })(),
      end: (function setStartIfPossible() {
        if (
          selectedEventType?.timeSpan &&
          selectedEventType.timeSpan[0] &&
          selectedEventType?.timeSpan[0].endTime
        ) {
          return selectedEventType?.timeSpan[0].endTime.slice(0, 5);
        } else return "";
      })(),
      days: selectedEventType?.timeSpan
        ? (function getDays() {
            const eventDays = selectedEventType?.timeSpan.map(
              (ts) => ts.weekday
            );
            const daysObj: any = { ...emptyDays };
            for (const day of eventDays) {
              if (day) {
                daysObj[day.toLowerCase()] = day;
              }
            }
            return daysObj;
          })()
        : emptyDays,
      duration: (function calculateDuration() {
        if (selectedEventType?.duration) {
          return selectedEventType?.duration;
        } else {
          if (
            selectedEventType?.timeSpan &&
            selectedEventType?.timeSpan.length
          ) {
            const start = selectedEventType?.timeSpan[0].startTime;
            const end = selectedEventType?.timeSpan[0].endTime;
            return calculateFieldDuration(start, end);
          } else {
            return 0;
          }
        }
      })(),
      prioritized: !!selectedEventType?.prioritized,
      repeated: (function getRepeatedValue() {
        if (props.editPannedEvent?.timeSpan[0]?.repeatEveryNWeeks) {
          return repetitionOptions.filter(
            (option) =>
              option.value ===
              String(props.editPannedEvent!.timeSpan[0].repeatEveryNWeeks)
          )[0];
        } else return repetitionOptions[0];
      })(),
    };
  };

  const {
    // submitForm, this will be used to submit on any change
    handleBlur,
    handleSubmit,
    setFieldValue,
    values,
    errors = {},
    touched,
  } = useFormik({
    validationSchema: schema,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true, //IMPORTANT: this allows to reset the form data when another presetting is selected
    initialValues: getFormInitialValues(),
    onSubmit: (values) => {
      const {
        days,
        color,
        prioritized,
        duration,
        start,
        end,
        externalID,
        name,
        repeated,
      } = values;

      let durationToSend = duration;

      if (!durationToSend) {
        durationToSend = getDuration(start, end);
      }
      const selectedDays = Object.values(days).filter((day) => !!day);

      const timeSpan = selectedDays.map((day) => {
        return {
          startTime: `${start}:00Z`,
          endTime: `${end}:00Z`,
          // duration: durationToSend,
          type: "WEEKDAY" as "SPECIFIC" | "WEEKDAY",
          weekday: day as keyof IDayPickerWeek,
          repeatEveryNWeeks: parseInt(repeated.value),
          startWeek: +startWeek,
        };
      });

      // description: description,
      // timeSpan: timeSpans,
      const staff = needsSpecific
        ? specificStaffs.map((staff) => {
            let userID: string | undefined = staff.userID;
            let condition: string | undefined = undefined;
            if (staff.userID === "ANYONE" || staff.userID === "DUAL_STAFFING") {
              userID = undefined;
              condition = staff.userID;
            }
            return {
              ...staff,
              userID,
              ...(!!condition && { condition }),
            };
          })
        : [];
      const staffNeeded = specificStaffs.length ? specificStaffs.length : 1;

      const body = {
        organizationID: props.organizationId,
        staff,
        staffNeeded,
        description,
        externalID,
        color,
        name,
        timeSpan,
        duration: durationToSend,
        prioritized,
      };

      createPlannedEvent(props.userId, body, props.organizationId)
        .then(() => {
          props.onSaved();
          enqueueSnackbar(translate("succefullySavedPlannedEvent"), {
            variant: "success",
            autoHideDuration: 1000,
          });
        })
        .catch(() => {
          enqueueSnackbar(translate("failedSavingPlannedEvent"), {
            variant: "error",
            autoHideDuration: 1000,
          });
        });
    },
  });

  const mappedStaffs = props.staffs.map((staff) => ({
    label: `${staff.user.firstName} ${staff.user.lastName || ""}`,
    value: staff.user.id,
  }));

  const mappedStaffsWithAnyone = [
    { label: translate("dualStaff"), value: "DUAL_STAFFING" },
    { label: translate("anyone"), value: "ANYONE" },
    ...mappedStaffs,
  ];

  useEffect(() => {
    if (props.isEditing) {
      loadEditEvent(props.editPannedEvent!);
    }
  }, [props.editPannedEvent, props.isEditing]);

  const loadEditEvent = (pe: IPlannedEvent) => {
    setEventName(pe.name);
    setEarliest((pe.timeSpan[0]?.startTime || "").slice(0, 5));
    setLatest((pe.timeSpan[0]?.endTime || "").slice(0, 5));
    setDescription(pe.description);
    setDuration(pe.duration?.toString() || "");
    setDays(getDays(pe.timeSpan));

    setSpecificStaffs(
      pe.staff?.map((staff) => ({
        userID: staff.user?.id || "DUAL_STAFFING",
        duration: staff.duration,
      })) || []
    );
    setNeedsSpecific(!!pe.staff?.length);
    setHasSpecific(
      !!pe.prioritized || pe.timeSpan.find((ts) => ts.anyWeekday) !== undefined
    );
    setIsWheneverWeek(pe.timeSpan.find((ts) => ts.anyWeekday) !== undefined);
    setRepeated((pe.timeSpan[0].repeatEveryNWeeks || 1).toString());
    setStartWeek(
      (
        pe.timeSpan[0].startWeek || getWeekFromDate(new Date().toISOString())
      ).toString()
    );
  };

  const getDays = (timeSpans: ITimeSpan[]) => {
    const newDays = { ...days };
    Object.keys(newDays).forEach((key) => {
      timeSpans.forEach((ts) => {
        if (key.toUpperCase() === ts.weekday) {
          newDays[key as keyof IDayPickerWeek] = ts.weekday;
        }
      });
    });
    return newDays;
  };

  const savedHandler = (isEditing: boolean) => {
    if (!areTimesValid(earliest, latest, duration)) {
      alert(translate("timeIntervalIsWrong"));
      return;
    }
    if (!areDaysValid(days) && !isWheneverWeek) {
      alert(translate("giveAtLeastOneDayOccuring"));
      return;
    }
    if (!eventName) {
      alert(translate("mustGiveANameToEvent"));
      return;
    }

    let timeSpans: ITimeSpan[] = [];
    if (!onlyOnce) {
      if (isWheneverWeek) {
        timeSpans = [
          {
            type: "WEEKDAY",
            startTime: `${earliest}:00Z`,
            endTime: `${latest}:00Z`,
            repeatEveryNWeeks: +repeated,
            anyWeekday: isWheneverWeek,
            startWeek: +startWeek,
          },
        ];
      } else {
        timeSpans = Object.keys(days).flatMap((key) =>
          days[key as keyof IDayPickerWeek]
            ? {
                type: "WEEKDAY",
                startTime: `${earliest}:00Z`,
                endTime: `${latest}:00Z`,
                weekday: days[key as keyof IDayPickerWeek],
                repeatEveryNWeeks: +repeated,
                startWeek: +startWeek,
              }
            : []
        );
      }
    }
    if (onlyOnce) {
      timeSpans = [
        {
          type: "SPECIFIC",
          start: `${specificDate} ${earliest}:00Z`,
          end: `${specificDate} ${latest}:00Z`,
          repeatEveryNWeeks: 1,
        },
      ];
    }

    const data: IPlannedEvent = {
      name: eventName,
      description: description,
      timeSpan: timeSpans,
      staff: needsSpecific
        ? specificStaffs.map((staff) => {
            let userID: string | undefined = staff.userID;
            let condition: string | undefined = undefined;
            if (staff.userID === "ANYONE" || staff.userID === "DUAL_STAFFING") {
              userID = undefined;
              condition = staff.userID;
            }
            return {
              ...staff,
              userID,
              ...(!!condition && { condition }),
            };
          })
        : [],
      staffNeeded: specificStaffs.length ? specificStaffs.length : 1,
      duration: +duration > 0 ? +duration : undefined,
      prioritized: hasSpecific,
    };

    if (isEditing) {
      updatePlannedEvent(props.editPannedEvent!.id!, data).then(props.onSaved);
      enqueueSnackbar(`${translate("updatedEvent")} ${eventName}`, {
        variant: "success",
      });
      return;
    }
    createPlannedEvent(props.userId, data, props.organizationId).then(
      props.onSaved
    );
    enqueueSnackbar(`${translate("createdEvent")} ${eventName}`, {
      variant: "success",
    });
  };

  const areTimesValid = (
    earliestTime: string,
    latestTime: string,
    durationMin: string
  ) => {
    if (!earliestTime || !latestTime || !durationMin || +durationMin <= 0) {
      return false;
    }
    const timeStart = new Date(`01/01/2021 ${earliestTime}`);
    const timeEnd = new Date(`01/01/2021 ${latestTime}`);
    if (timeStart > timeEnd) {
      return false;
    }
    const minuteDiff = (timeEnd.getTime() - timeStart.getTime()) / 60 / 1000;
    if (minuteDiff < +durationMin) {
      return false;
    }
    return true;
  };

  const areDaysValid = (days: IDayPickerWeek) => {
    let isValid = false;
    Object.keys(days).forEach((key) => {
      isValid = !!days[key as keyof IDayPickerWeek] || isValid;
    });
    return isValid;
  };

  const renderOldForm = () => {
    return (
      <form
        className="add-pe"
        onSubmit={(e) => {
          e.preventDefault();
          if (props.isEditing) {
            savedHandler(props.isEditing);
            return;
          }
          savedHandler(props.isEditing);
        }}
      >
        <Switch
          label={translate("pressIfEventOnlyOccursOnce")}
          value={onlyOnce}
          onClick={() => setOnlyOnce(!onlyOnce)}
        />
        <Input
          label={translate("nameOfPlannedEvent")}
          value={eventName}
          onChange={(e) => setEventName(e.target.value)}
          type="text"
          textCentered
          width={"70%"}
        />
        <div className="add-pe--time">
          <Input
            label={translate("startEarliest")}
            value={earliest}
            onChange={(e) => setEarliest(e.target.value)}
            type="time"
            textCentered
            width={"100%"}
          />
          <Input
            label={translate("stopsLatest")}
            value={latest}
            onChange={(e) => setLatest(e.target.value)}
            type="time"
            textCentered
            width={"100%"}
          />
          <Input
            label={translate("duration")}
            value={duration}
            onChange={(e) => setDuration(e.target.value)}
            type="number"
            textCentered
            width={"100%"}
          />
        </div>
        {!isWheneverWeek && (
          <div className="add-pe--specific-time">
            <p className="add-pe--specific-time__label">
              {translate("ifLocked")}
            </p>
            <div
              className="add-pe--specific-time__lock waves-effect"
              style={{ color: hasSpecific ? "#11ffbd" : "" }}
              onClick={() => {
                if (!hasSpecific) {
                  const date = new Date();
                  date.setHours(Number(earliest.split(":")[0]));
                  date.setMinutes(
                    Number(earliest.split(":")[1]) + Number(duration)
                  );
                  const newLatest = date.toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  });
                  setLatest(newLatest);
                }
                setHasSpecific(!hasSpecific);
              }}
            >
              <i className="far fa-lock" />
            </div>
          </div>
        )}

        {onlyOnce ? (
          <>
            <p className="add-pe--specific-date__label">
              {translate("choseSingleDayEventOccurs")}
            </p>
            <Input
              value={specificDate}
              onChange={(e) => setSpecificDate(e.target.value)}
              type={"date"}
            />
          </>
        ) : (
          <>
            {!isWheneverWeek && (
              <DayPicker
                value={days}
                onChange={(ds) => setDays(ds)}
                label={translate("plannedEventOccuringFollowingDays")}
              />
            )}
            <Switch
              label="Om insatsen kan ske när som under en vecka"
              value={isWheneverWeek}
              onClick={() => {
                setHasSpecific(!isWheneverWeek);
                setIsWheneverWeek(!isWheneverWeek);
              }}
            />
          </>
        )}
        {renderCommonFields()}

        <Button label="Spara" color="green" />
      </form>
    );
  };

  const renderCommonFields = () => {
    return (
      <>
        <div style={{ display: "flex", alignItems: "flex-end", gap: 10 }}>
          <Dropdown
            title={translate("howDoesEventrepeat")}
            options={[
              { label: translate("everyWeek"), value: "1" },
              { label: translate("everyTwoWeek"), value: "2" },
              { label: translate("everyThreeWeek"), value: "3" },
              { label: translate("everyThreeWeek"), value: "4" },
            ]}
            value={repeated}
            onSelect={(value) => setRepeated(value)}
          />
          <Input
            type="number"
            value={startWeek}
            onChange={(e) => setStartWeek(e.target.value)}
            min={1}
            max={52}
            label={translate("startWeek")}
          />
        </div>
        <Switch
          label={translate("ifSpecificOrMultipleStaffNeeded")}
          value={needsSpecific}
          onClick={() => {
            if (!needsSpecific) {
              setSpecificStaffs([
                { userID: "DUAL_STAFFING", duration: Number(duration) },
              ]);
            }
            setNeedsSpecific(!needsSpecific);
          }}
        />
        {needsSpecific && (
          <>
            {specificStaffs.map((staff, index) => (
              <div className="add-pe--specific-staff" key={index}>
                <Dropdown
                  options={mappedStaffsWithAnyone}
                  title="Personal"
                  onSelect={(value) =>
                    setSpecificStaffs(
                      Object.assign([], specificStaffs, {
                        [index]: {
                          userID: value,
                          duration: staff.duration,
                        },
                      })
                    )
                  }
                  value={staff.userID || ""}
                />
                {staff.userID && (
                  <Input
                    value={(function () {
                      if (!staff.duration) {
                        if (!props.isEditing) {
                          return values.duration;
                        } else return `${duration}`;
                      }
                      return `${staff.duration}`;
                    })()}
                    onChange={(e) =>
                      setSpecificStaffs(
                        Object.assign([], specificStaffs, {
                          [index]: {
                            userID: staff.userID,
                            duration: +e.target.value,
                          },
                        })
                      )
                    }
                    placeHolder={translate("duration")}
                    type={"number"}
                  />
                )}
                <i
                  className="fas fa-trash"
                  onClick={() =>
                    setSpecificStaffs(
                      specificStaffs.filter((_, i) => i !== index)
                    )
                  }
                />
              </div>
            ))}
            <div className="add-pe--specific-staff">
              <Dropdown
                options={mappedStaffsWithAnyone}
                title={translate("addStaff")}
                onSelect={(value) => {
                  setSpecificStaffs(
                    specificStaffs.concat([
                      { userID: value, duration: undefined },
                    ])
                  );
                }}
                value={""}
              />
            </div>
          </>
        )}
        <div className="mt-2" />
        <Textarea
          label={translate("description")}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </>
    );
  };

  const renderNewForm = () => (
    <>
      <div className="flex w-full flex-col self-start mt-4 ">
        <div className="flex flex-1">
          <div className="flex-1">
            <div className="flex self-stretch flex-col w-full relative">
              <div
                className="absolute w-6 h-6 rounded-xl top-[30px] right-14 z-10"
                style={{
                  backgroundColor: selectedEventType?.color || "#000",
                }}
              />
              <Select
                labelAbove={translate("eventType")}
                selectType="normal"
                options={eventTypes.map((eventType) => ({
                  value: eventType,
                  label: eventType.name,
                }))}
                value={
                  {
                    label: selectedEventType?.name || "",
                    value: selectedEventType?.id || "",
                  } as any
                }
                error={""}
                touched={false}
                onChange={(e: any) => {
                  setSelectedEventType(e.value);
                }}
              />
            </div>
            <div className="mt-2" />

            <FormInput
              id="name"
              value={values.name}
              placeholder={translate("enterEventTypeOrName")}
              labelAbove={translate("name")}
              type="text"
              autoCapitalize="none"
              onChange={(e: any) => {
                setFieldValue("name", e.target.value);
                // if (!isAddingNew) debounceUpdateEventType();
              }}
              onBlur={handleBlur("name")}
              error={errors.name || ""}
              touched={touched.name || false}
            />
          </div>
        </div>

        <div className="flex flex-1 gap-10 mt-4">
          <FormInput
            id="start"
            placeholder={translate("enterStartTime")}
            value={values.start}
            labelAbove={translate("startEarliest")}
            type="time"
            max={values.end}
            autoCapitalize="none"
            onChange={(e: any) => {
              setFieldValue("start", e.target.value);
              // if (!isAddingNew) debounceUpdateEventType();
            }}
            onBlur={handleBlur("start")}
            error={errors.start || ""}
            touched={touched.start || false}
          />
          <FormInput
            id="end"
            placeholder={translate("enterEndTime")}
            value={values.end}
            labelAbove={translate("stopsLatest")}
            type="time"
            min={values.start}
            autoCapitalize="none"
            onChange={(e: any) => {
              setFieldValue("end", e.target.value);
              // if (!isAddingNew) debounceUpdateEventType();
            }}
            onBlur={handleBlur("end")}
            error={errors.end || ""}
            touched={touched.end || false}
          />

          <FormInput
            id="duration"
            defaultValue={calculateFieldDuration(values.start, values.end)}
            min={0}
            max={calculateFieldDuration(values.start, values.end)}
            placeholder={translate("enterDuration")}
            value={`${
              values.duration ??
              calculateFieldDuration(values.start, values.end)
            }`}
            labelAbove={translate("duration")}
            type="number"
            autoCapitalize="none"
            onChange={(e: any) => {
              setFieldValue("duration", e.target.value);
              setDuration(e.target.value);
              // if (!isAddingNew) debounceUpdateEventType();
            }}
            onBlur={handleBlur("duration")}
            error={errors.duration || ""}
            touched={touched.duration || false}
          />
        </div>

        <>
          <div className="mt-8 mb-8 flex flex-row w-[150px] self-center items-center  gap-2">
            <div
              onClick={() => {
                setFieldValue("prioritized", !values.prioritized);
                // if (!isAddingNew) debounceUpdateEventType();
              }}
              className={`w-8 h-8 rounded-full flex justify-center items-center items hover:cursor-pointer ${
                values.prioritized ? "bg-mint" : "bg-[#f3f3f3]"
              }`}
            >
              {values.prioritized ? <FaLock /> : <FaUnlock />}
            </div>
            <p className="bold-text">
              {values.prioritized
                ? translate("Prioritized")
                : translate("notPrioritized")}
            </p>
          </div>
          <DayPicker
            value={values.days}
            onChange={(days) => {
              setFieldValue("days", days);
              // debounceUpdateEventType();
            }}
            label={translate("plannedEventOccuringFollowingDays")}
          />
        </>

        <div className="mb-4 mt-4 items-center flex flex-col gap-2">
          {renderCommonFields()}
        </div>
      </div>

      <IconButton
        hasDoubleCheck={false} //double check only if the action is DELETE
        doubleCheckLabel={translate("areYouSure")}
        confirming={false}
        loadingText={translate("deleting")}
        isLoading={false}
        onConfirmClicked={() => {}}
        onCancelClicked={() => {}}
        continueLabel={translate("Yes")}
        cancelLabel={translate("No")}
        icon="add"
        label={translate("Add")}
        customStyle="mt-20 self-center"
        onClick={handleSubmit}
      />
    </>
  );

  return (
    <div className="flex flex-col items-center">
      {props.isEditing && renderOldForm()}
      {!props.isEditing && !onlyOnce && renderNewForm()}
    </div>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  organizationId: state.auth.organizationId,
});

export default connect(mapStateToProps)(AddPlannedEvent);
