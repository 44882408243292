import { useCallback, memo, useState } from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  useBlockLayout,
  usePagination,
  Column,
  useFlexLayout,
} from "react-table";
import { FixedSizeList } from "react-window";
import { FaCaretUp, FaCaretDown, FaSort, FaEdit } from "react-icons/fa";

import { ClientSidePagination, SearchInput, EmptyTable } from "./Components";
import Select from "../Form/Select";
import { useTranslation } from "react-i18next";
type Data = {
  name: string;
  age: string;
  style?: any;
};

type TableProps = {
  title?: string;
  rowKey?: string;
  data: any[];
  columns: Column<any>[];
  filteredColumns?: Column<any>[];
  onColumnsChange?: (columns: any) => void;
  onSearch?: (term: string) => void;
  canEditColumns?: boolean;
  canFilter?: boolean;
  onChangeFilter?: (term: string) => void;
  clientSidePagination?: boolean;
  clientSideCurrentPage?: number;
  showHeader?: true;
  pageSize?: number;
  currentPage?: number;
  onLeftClicked?: () => void;
  onRightClicked?: () => void;
  setCurrentPage?: (index: number) => void;
  loadingData?: boolean;
  sortable?: boolean;
  sortableColumns?: any[];
  stickyHeader?: boolean;
  emptyStateText: string;
  withReactWindow?: boolean;
  reactWindowRowHeight?: number;
  customStyle?: string;
};

const TableComponent = ({
  title,
  data,
  rowKey,
  columns,
  filteredColumns,
  onColumnsChange,
  canEditColumns = false,
  canFilter = false,
  clientSidePagination = false,
  showHeader = true,
  onSearch,
  onChangeFilter,
  currentPage = 1,
  setCurrentPage,
  loadingData = false,
  sortable = true,
  sortableColumns = [],
  emptyStateText = "There's no data",
  withReactWindow = false,
  reactWindowRowHeight = 28,
  customStyle = "",
}: TableProps) => {
  const [columnEditMode, setColumnEditMode] = useState(false);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    setGlobalFilter,
    totalColumnsWidth,
    state: { pageIndex },
  } = useTable(
    {
      columns: filteredColumns ? filteredColumns : columns,
      data,
      ...(rowKey && { getRowId: (row: any) => row[rowKey] }),
      initialState: clientSidePagination
        ? {
            pageIndex: 0,
            pageSize: 1,
          }
        : {},
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    withReactWindow ? useBlockLayout : useFlexLayout
  );

  const Row = useCallback(
    ({ index, style }): JSX.Element => {
      const row = rows[index];
      prepareRow(row);
      return (
        <tr {...row.getRowProps({ style })} role="row">
          {row.cells.map((cell) => {
            return (
              <td
                {...cell.getCellProps()}
                className="pt-1 whitespace-nowrap border-b border-gray-200 font-medium"
                role="cell"
              >
                {cell.render("Cell")}
              </td>
            );
          })}
        </tr>
      );
    },
    [prepareRow, rows]
  );

  const noData =
    !loadingData && (data.length === 0 || (canFilter && rows.length === 0));

  const Loader = (): JSX.Element => <div>Loading...</div>;

  const Table = () => (
    <table
      className={`text-sm min-w-full ${
        !withReactWindow && "divide-y divide-gray-200 w-full"
      } `}
      style={{ borderSpacing: 0 }}
      {...getTableProps()}
    >
      <thead style={showHeader ? {} : { visibility: "collapse" }}>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => {
              let shouldBeSorted =
                sortable &&
                sortableColumns &&
                column.Header &&
                sortableColumns.includes(column.Header as Data);
              return (
                <th
                  scope="col"
                  className={`group px-6 py-3 text-left font-medium text-base tracking-wider p-0  ${
                    !withReactWindow && "divide-y divide-gray-200"
                  }`}
                  {...column.getHeaderProps(
                    shouldBeSorted ? column.getSortByToggleProps() : undefined
                  )}
                >
                  <div className="flex items-center">
                    {column.render("Header")}
                    {shouldBeSorted && (
                      <span className="ml-2 flex flex-col">
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <FaCaretDown className="w-4 h-4 text-gray-400" />
                          ) : (
                            <FaCaretUp className="w-4 h-4 text-gray-400" />
                          )
                        ) : (
                          <FaSort className="w-4 h-4 text-gray-400" />
                        )}
                      </span>
                    )}
                  </div>
                </th>
              );
            })}
          </tr>
        ))}
      </thead>

      <tbody
        {...getTableBodyProps()}
        className={`bg-white w-full ${
          !withReactWindow && "border-b border-gray-200"
        }`}
      >
        {withReactWindow ? (
          <FixedSizeList
            style={{
              width: "100%",
            }}
            height={200}
            itemCount={rows.length}
            width={totalColumnsWidth}
            itemSize={reactWindowRowHeight}
          >
            {Row}
          </FixedSizeList>
        ) : (
          (clientSidePagination ? page : rows).map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="h-8">
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      className="pt-1 m-0 whitespace-nowrap border-b border-gray-200 font-medium"
                      role="cell"
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })
        )}
      </tbody>
    </table>
  );
  const { t } = useTranslation();
  const translate = (key: string) => t(`translations:schedulePage.${key}`);
  return (
    <div className={`w-full overflow-x-auto ${customStyle}`}>
      {title && (
        <div className="flex flex-row gap-8 mb-4  w-full items-start justify-between">
          <div className="bold-text text-lg">{title}</div>
          {onSearch && (
            <SearchInput
              placeHolder={`${translate("search")}`}
              onChange={(term) => {
                onSearch(term);
              }}
            />
          )}
          {canFilter && (
            <SearchInput
              placeHolder={`${translate("search")}`}
              onChange={(term: string) => {
                setGlobalFilter(term || undefined);
                onChangeFilter && onChangeFilter(term);
              }}
            />
          )}
          {canEditColumns && (
            <div className="flex flex-col self-end items-start justify-start">
              <div
                className="hover:cursor-pointer self-end"
                onClick={() => setColumnEditMode(!columnEditMode)}
              >
                <FaEdit />
              </div>
              {columnEditMode && (
                <Select
                  error=""
                  selectType="multiple"
                  touched={false}
                  labelAbove="Select available columns"
                  value={filteredColumns!.map((column) => ({
                    //error if columnEditMode is on and filteredColumns is unefined
                    label: column.Header,
                    value: column.accessor,
                  }))}
                  onChange={(options: any) => {
                    const newColumns = options.map(
                      (option: any) => option.value
                    );
                    onColumnsChange &&
                      onColumnsChange(
                        newColumns.map((accessor: string) => accessor)
                      );
                  }}
                  options={columns.map((column) => ({
                    label: column.Header,
                    value: column.accessor,
                  }))}
                />
              )}
            </div>
          )}
        </div>
      )}

      {loadingData && <Loader />}
      {noData && <EmptyTable emptyStateText={emptyStateText} />}
      {!noData && !loadingData && <Table />}
      {clientSidePagination && (
        <ClientSidePagination
          pageIndex={currentPage}
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          onNext={() => {
            previousPage();
            setCurrentPage && setCurrentPage(pageIndex - 1);
          }}
          onPrevious={() => {
            nextPage();
            setCurrentPage && setCurrentPage(pageIndex + 1);
          }}
        />
      )}
    </div>
  );
};

export default memo(TableComponent);
