import NavigationItem from "./NavigationItem/NavigationItem";
import "./NavigationItems.scss";
import { ROUTES } from "../../../constants/settings";
import { useTranslation } from "react-i18next";
type NavigationItemsProps = {
  isAdmin: boolean;
};

const NavigationItems = (props: NavigationItemsProps) => {
  const { t } = useTranslation();
  const translate = (key: string) => t(`translations:generalWords.${key}`);

  const navLinks = [
    {
      label: translate("schedule"),
      link: "/schema/individuell",
      linkAdmin: "/schema/redigera",
      admin: false,
      routes: ["/schema/redigera", "/schema/individuell", "/schema/edit"],
    },
    {
      label: translate("statistic"),
      link: "/statistik",
      linkAdmin: "/statistik",
      admin: false,
      routes: ["/statistik"],
    },
    {
      label: translate("users"),
      link: "/anvandare/personal",
      linkAdmin: "/anvandare/personal",
      admin: true,
      routes: ["/anvandare/personal", "/anvandare/kunder"],
    },
    {
      label: translate("settings"),
      link: ROUTES.settings.usersGroups,
      linkAdmin: ROUTES.settings.usersGroups,
      admin: true,
      routes: [...Object.values(ROUTES.settings)],
    },
  ];

  return (
    <div className="navigation-items">
      {props.isAdmin
        ? navLinks.map((nav) => (
            <NavigationItem
              key={nav.label}
              label={nav.label}
              link={nav.linkAdmin}
              routes={nav.routes}
            />
          ))
        : navLinks
            .filter((nav) => !nav.admin)
            .map((nav) => (
              <NavigationItem
                key={nav.label}
                label={nav.label}
                link={nav.link}
                routes={nav.routes}
              />
            ))}
    </div>
  );
};

export default NavigationItems;
