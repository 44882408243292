import axios from "../axios";
import { IOrganization, IOrganizationUser } from "../types/organization";

export const createUser = (
  firstName: string,
  lastName: string,
  email: string,
  phonenr: string,
  gender: string,
  address: string,
  postalcode: string,
  organizationId: string,
  isStaff: boolean
) =>
  axios
    .post(`organization/${organizationId}/user`, {
      email: !email ? undefined : email,
      password: !email ? undefined : email,
      firstName: firstName,
      lastName: lastName,
      role: isStaff ? "STAFF" : "CLIENT",
    })
    .then((res) =>
      axios
        .post(`user/${res.data.id}/restrictions`, {
          likes: [],
          dislikes: [],
          allergies: [],
          canMeetAll: true,
        })
        .then(() =>
          axios.post(`user/${res.data.id}/details`, {
            phoneNumber: phonenr,
            gender: gender,
            languages: [],
            allergens: [],
            location: {
              address: address,
              postalCode: postalcode,
            },
          })
        )
    );

export const getAllUsers = (organizationId: string) => {
  return axios.get<IOrganizationUser[]>(`organization/${organizationId}/user`);
};

export const getUserOrganizations = (userId: string) => {
  return axios.get<{ role: string; organization: IOrganization }>(
    `user/${userId}/organization`
  );
};

type GetOrgainzationUserParams = {
  userId: string;
  organizationId: string;
};

export const getOrgainzationUser = ({
  userId,
  organizationId,
}: GetOrgainzationUserParams) => {
  return axios.get(`organization/${organizationId}/user/${userId}`);
};

type UpdateOrganizationUserProps = {
  userId: string;
  organizationId: string;
  [x: string]: any;
};

export const updateOrganizationUser = ({
  organizationId,
  userId,
  ...body
}: UpdateOrganizationUserProps) =>
  axios.patch(`organization/${organizationId}/user/${userId}`, {
    ...body,
  });
