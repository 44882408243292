import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Table from "../../components/Table";
import TimeSelector from "../../components/UI/TimeSelector";

import TabSelector from "../../components/UI/TabSelector";
import { getStatistics } from "../../api/schedule";
import { ReduxState } from "../../shared/Types";
import transformStatisticResponseData from "./transform";
import useTableColumns from "./tableColumns";

const NewStatistics = (): JSX.Element => {
  const { t } = useTranslation();
  const translate = (key: string) => t(`translations:statisticsPage.${key}`);

  const { staffColumns, clientColumns } = useTableColumns();
  //organization
  const organizationId = useSelector(
    (state: ReduxState) => state.auth.organizationId
  );

  //statistics
  const [isOverview, setIsOverview] = useState(true);
  const [isStaff, setIsStaff] = useState(true);
  const [type, setType] = useState<"LIVE" | "STAGING">("LIVE");
  const [loadingStatistics, setLoadingStatistics] = useState(false);
  const [statistics, setStatistics] = useState<StatisticTableData>();

  const callGetStatistics = (from: string, to: string) => {
    setLoadingStatistics(true);
    setStatistics({ staffData: [], clientsData: [] });
    getStatistics(organizationId, from, to, type)
      .then((response) => {
        setLoadingStatistics(false);
        if (response.data) {
          const { clientsData, staffData } = transformStatisticResponseData(
            response.data
          );
          setStatistics({
            clientsData,
            staffData,
          });
        } else {
          setStatistics({ staffData: [], clientsData: [] });
        }
      })
      .catch(() => {
        setLoadingStatistics(false);
      });
  };

  const formatDate = (date: Date) => {
    const month = date.getMonth() + 1;
    let monthToDisplay = month.toString();
    if (month < 10) {
      monthToDisplay = `0${month}`;
    }
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${monthToDisplay}-${day}`;
  };

  useEffect(() => {
    const today = new Date();
    callGetStatistics(formatDate(today), formatDate(today));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const renderOverviewIndividualSwitcher = (): JSX.Element => (
    <div className="flex flex-row gap-4 items-center justify-start">
      <button
        onClick={() => setIsOverview(true)}
        className={`bold-text transition-all ease-in ${
          isOverview ? "text-base" : "text-gray-600"
        }`}
      >
        {translate("navigationOverview")}
      </button>
      <button
        onClick={() => setIsOverview(false)}
        className={`bold-text transition-all ease-in ${
          !isOverview ? "text-base" : "text-gray-600"
        }`}
      >
        {translate("navigationIndividual")}
      </button>
    </div>
  );

  const renderClienStaffSwitcher = (): JSX.Element => (
    <TabSelector
      onTabChange={(value: string) => {
        setIsStaff(value === "staff");
      }}
      options={[
        { label: translate("navigationStaff"), value: "staff" },
        { label: translate("navigationClient"), value: "client" },
      ]}
    />
  );

  const renderLiveStagingSwitcher = (): JSX.Element => (
    <TabSelector
      onTabChange={(value: any) => {
        setType(value);
      }}
      options={[
        { label: translate("navigationLive"), value: "LIVE" },
        { label: translate("navigationStaging"), value: "STAGING" },
      ]}
    />
  );

  return (
    <>
      {renderOverviewIndividualSwitcher()}
      <div className="card-container">
        <TimeSelector
          loading={loadingStatistics}
          setTimerange={(from, to) => {
            callGetStatistics(from, to); //TODO: debounce later?
          }}
        />
        <div className="self-stretch flex flex-row w-[40%] mt-4 justify-between">
          {renderClienStaffSwitcher()}
          {renderLiveStagingSwitcher()}
        </div>
        <Table
          title=" "
          canEditColumns={true}
          canFilter={true}
          filteredColumns={isStaff ? staffColumns : clientColumns}
          emptyStateText={translate("emptyStateText")}
          loadingData={loadingStatistics}
          sortable={true}
          data={
            isStaff
              ? statistics?.staffData || []
              : statistics?.clientsData || []
          }
          columns={isStaff ? staffColumns : clientColumns}
          sortableColumns={
            isStaff
              ? staffColumns.map((column) => column.Header)
              : clientColumns.map((column) => column.Header)
          }
          customStyle="mt-4"
        />
      </div>
    </>
  );
};

export default NewStatistics;
