import axios from "../axios";
import { IWorkTimeEdit } from "../components/Settings/Staff/WorkTimeSettings/AddWorkTime/AddWorkTime";
import { IPlannedEvent, IScheduledEvent } from "../types/event";
import { IDayDomain, IDomain, IWorkTime } from "../types/organization";
import { IGroup } from "../types/user";

export const getWorkTimesByUserId = (
  userId: string,
  organizationId: string
) => {
  return axios.get<IWorkTime[]>(
    `organization/${organizationId}/staff/${userId}/work_time`
  );
};

export const getPlannedEventsByUserId = (
  userId: string,
  organizationId: string
) => {
  return axios.get<IPlannedEvent[]>(`/event/planned_event`, {
    params: { userID: userId, organizationID: organizationId },
  });
};

export const createPlannedEvent = (
  userId: string,
  planningEvent: IPlannedEvent,
  organizationId: string
) => {
  return axios.post(`/event/planned_event`, {
    userID: userId,
    organizationID: organizationId,
    ...planningEvent,
  });
};

export const updatePlannedEvent = (
  plannedEventId: string,
  planningEvent: IPlannedEvent
) => {
  return axios.patch(`/event/planned_event/${plannedEventId}`, planningEvent);
};

export const deletePlannedEventById = (plannedEventId: string) => {
  return axios.delete(`/event/planned_event/${plannedEventId}`);
};

export const createWorkTime = (
  userId: string,
  workTime: IWorkTimeEdit,
  organizationId: string,
  hasDuration: boolean,
  availableForDualStaffing?: boolean,
  connectedToUserID?: string
  // repeatEveryNWeeks?: number,
  // startWeek?: number
) => {
  return axios.post(
    `organization/${organizationId}/staff/${userId}/work_time`,
    {
      ...workTime,
      duration: hasDuration ? workTime.duration : undefined,
      availableForDualStaffing: !!availableForDualStaffing,
      ...(!!availableForDualStaffing &&
        connectedToUserID !== "ingen" && { connectedToUserID }),
    }
  );
};

export const updateWorkTime = (
  userId: string,
  workTimeId: string,
  workTime: IWorkTimeEdit,
  organizationId: string,
  availableForDualStaffing?: boolean,
  connectedToUserID?: string
  // repeatEveryNWeeks?: number,
  // startWeek?: number
) => {
  let connectedToUserIDToSend: any = null;
  if (connectedToUserID == null || connectedToUserID === "ingen") {
    connectedToUserIDToSend = null;
  } else {
    connectedToUserIDToSend = connectedToUserID;
  }

  return axios.patch(
    `organization/${organizationId}/staff/${userId}/work_time/${workTimeId}`,
    {
      ...workTime,
      availableForDualStaffing: !!availableForDualStaffing,
      connectedToUserID: connectedToUserIDToSend,
    }
  );
};

export const deleteWorkTimeById = (
  userId: string,
  workTimeId: string,
  organizationId: string
) => {
  return axios.delete(
    `organization/${organizationId}/staff/${userId}/work_time/${workTimeId}`
  );
};

export const getHealthCenters = (organizationId: string) => {
  return axios.get<{ id: string; name: string }[]>(
    `organization/${organizationId}/health_center`
  );
};

export const createHealthCenter = (organizationId: string, name: string) => {
  return axios.post<{ id: string; name: string }>(
    `organization/${organizationId}/health_center`,
    { name }
  );
};

export const getGroups = (organizationId: string) => {
  return axios.get<IGroup[]>(`organization/${organizationId}/group`);
};

export const createGroup = (
  organizationId: string,
  name: string,
  color?: string
) => {
  const requestBody = {
    name,
    ...(!!color && { color }),
  };

  return axios.post<IGroup[]>(
    `organization/${organizationId}/group`,
    requestBody
  );
};

export const updateGroup = (
  organizationId: string,
  organizationGroupId: string,
  name: string,
  color?: string
) => {
  const requestBody = {
    name,
    ...(!!color && { color }),
  };
  return axios.patch<IGroup[]>(
    `organization/${organizationId}/group/${organizationGroupId}`,
    requestBody
  );
};

export const deleteGroup = (
  organizationId: string,
  organizationGroupId: string
) => {
  return axios.delete<IGroup[]>(
    `organization/${organizationId}/group/${organizationGroupId}`
  );
};

export const overrideDayDomain = (
  organizationId: string,
  date: string,
  dayDomain: IDayDomain
) => {
  return axios.post(`organization/${organizationId}/day_domain/override`, {
    date: date,
    users: dayDomain.users,
    events: dayDomain.events,
  });
};

export const getDayDomain = (organizationId: string, date: string) => {
  return axios.get<IDayDomain>(`organization/${organizationId}/day_domain`, {
    params: { date },
  });
};

export const getDomain = (organizationId: string) => {
  return axios.get<IDomain>(`organization/${organizationId}/day_domain`);
};

export const getDomainOverride = (
  organizationId: string,
  from: string,
  to: String
) =>
  axios.get<OverrideResponse>("/domain", {
    params: {
      organizationID: organizationId,
      from,
      to,
    },
  });
