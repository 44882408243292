import { useTranslatedDates } from "../../../shared/utility";
import "./DayPicker.scss";
import DayPickerButton from "./DayPickerButton/DayPickerButton";

type DayPickerProps = {
  value: IDayPickerWeek;
  onChange: (days: IDayPickerWeek) => void;
  label: string;
  error?: string;
  touched?: boolean;
};

export interface IDayPickerWeek {
  monday: string;
  tuesday: string;
  wednesday: string;
  thursday: string;
  friday: string;
  saturday: string;
  sunday: string;
}

const DayPicker = (props: DayPickerProps) => {
  const { days } = useTranslatedDates();

  return (
    <div className="day-picker">
      <div className="day-picker--label">{props.label}</div>
      <div className="day-picker--buttons">
        <DayPickerButton
          label={days[0].label}
          onClick={() =>
            props.onChange({
              ...props.value,
              monday: props.value.monday ? "" : days[0].value,
            })
          }
          active={!!props.value.monday}
        />
        <DayPickerButton
          label={days[1].label}
          onClick={() =>
            props.onChange({
              ...props.value,
              tuesday: props.value.tuesday ? "" : days[1].value,
            })
          }
          active={!!props.value.tuesday}
        />
        <DayPickerButton
          label={days[2].label}
          onClick={() =>
            props.onChange({
              ...props.value,
              wednesday: props.value.wednesday ? "" : days[2].value,
            })
          }
          active={!!props.value.wednesday}
        />
        <DayPickerButton
          label={days[3].label}
          onClick={() =>
            props.onChange({
              ...props.value,
              thursday: props.value.thursday ? "" : days[3].value,
            })
          }
          active={!!props.value.thursday}
        />
        <DayPickerButton
          label={days[4].label}
          onClick={() =>
            props.onChange({
              ...props.value,
              friday: props.value.friday ? "" : days[4].value,
            })
          }
          active={!!props.value.friday}
        />
        <DayPickerButton
          label={days[5].label}
          onClick={() =>
            props.onChange({
              ...props.value,
              saturday: props.value.saturday ? "" : days[5].value,
            })
          }
          active={!!props.value.saturday}
        />
        <DayPickerButton
          label={days[6].label}
          onClick={() =>
            props.onChange({
              ...props.value,
              sunday: props.value.sunday ? "" : days[6].value,
            })
          }
          active={!!props.value.sunday}
        />
      </div>
      {props.error && props.touched && (
        <label className="float-right normal-text text-red-500 text-right mt-[11px]">
          {props.error}
        </label>
      )}
    </div>
  );
};

export default DayPicker;
