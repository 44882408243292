import nodeAxios from "axios";

const axios = nodeAxios.create({
  baseURL:
    process.env.REACT_APP_API_URL || "https://api.app-staging.stalmarck.com",
});

axios.interceptors.request.use((config) => {
  config.headers.Authorization = "Bearer " + localStorage.getItem("sro-token");
  return config;
});

export default axios;
