import "./IconButton.scss";

type IconButtonProps = {
  className?: string;
  color?: "white" | "blue" | "red" | "grey";
  active?: boolean;
  icon: "plus" | "cross" | "trashcan" | "upload" | "none";
  onClick: () => void;
  width: string;
  type?: any;
  label: string;
};

const IconButton = (props: IconButtonProps) => {
  const buttonClasses = ["waves-effect", "icon-button"];
  if (props.className) {
    buttonClasses.push(props.className);
  }

  let btnColor = "";
  let btnTextColor = "";
  let iconColor = "";
  switch (props.color) {
    case "white":
      btnColor = "#FFFFFF";
      btnTextColor = "#182848";
      iconColor = "#323232";
      buttonClasses.push("waves-dark");
      break;
    case "blue":
      btnColor = "#0072FF";
      btnTextColor = "#FFFFFF";
      iconColor = "#FFFFFF";
      buttonClasses.push("waves-light");
      break;
    case "red":
      btnColor = "#FF0000";
      btnTextColor = "#FFFFFF";
      iconColor = "#FFFFFF";
      buttonClasses.push("waves-light");
      break;
    case "grey":
      btnColor = "#F3F3F3";
      btnTextColor = "#000000";
      iconColor = "#323232";
      buttonClasses.push("waves-dark");
      break;
    default:
      btnColor = "#FFFFFF";
      break;
  }

  if (props.active) {
    btnColor = "#0072FF";
    btnTextColor = "#FFFFFF";
    iconColor = "#FFFFFF";
    buttonClasses.push("waves-light");
  }

  let icon = null;
  switch (props.icon) {
    case "plus":
      icon = <i className="fas fa-plus-circle" style={{ color: iconColor }} />;
      break;
    case "cross":
      icon = <i className="fas fa-times-circle" style={{ color: iconColor }} />;
      break;
    case "trashcan":
      icon = <i className="fas fa-trash" style={{ color: iconColor }} />;
      break;
    case "upload":
      icon = (
        <i className="fas fa-cloud-upload-alt" style={{ color: iconColor }} />
      );
      break;

    default:
      break;
  }

  return (
    <button
      className={buttonClasses.join(" ")}
      style={{
        backgroundColor: btnColor,
        color: btnTextColor,
        width: props.width,
      }}
      onClick={props.onClick}
      {...(props.type && { type: props.type })}
    >
      <div className="icon-button--label">{props.label}</div> {icon}
    </button>
  );
};

export default IconButton;
