import Spinner from "../Spinner/Spinner";
import "./Button.scss";

type ButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  label: string;
  color: "green" | "grey" | "white" | "red";
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  width?: string;
  isLoading?: boolean;
  onMouseLeave?: () => void;
  style?: React.CSSProperties | undefined;
  className?: string;
};

const Button = (props: ButtonProps) => {
  const buttonClasses = [
    "button",
    "waves-effect",
    "waves-dark",
    props.color,
    props.className,
  ];

  return (
    <button
      className={buttonClasses.join(" ")}
      style={{
        ...props.style,
        minWidth: "100px",
        width: props.width || "fit-content",
        cursor: props.disabled ? "not-allowed" : "pointer",
      }}
      onClick={props.onClick}
      onMouseLeave={props.onMouseLeave}
      {...props}
    >
      {!props.isLoading ? props.label : <Spinner small />}
    </button>
  );
};

export default Button;
