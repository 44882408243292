import { useCallback, useEffect, useState } from "react";
import axios from "../../axios";
import ContactInfo from "../../components/Settings/ContactInfo/ContactInfo";
import NameSex from "../../components/Settings/NameSex/NameSex";
import WorkTimeSettings from "../../components/Settings/Staff/WorkTimeSettings/WorkTimeSettings";
import IconButton from "../../components/UI/NewIconButton";
import DropdownMultiple from "../../components/UI/DropdownMultiple/DropdownMultiple";
import Location from "../../components/Settings/Location/Location";
import { useTranslation } from "react-i18next";
import {
  IGroup,
  IUser,
  IUserAllergen,
  IUserDetailsKeys,
  IUserKeys,
  IUserLanguage,
  IUserLocationKeys,
  IUserRestrictionsKeys,
} from "../../types/user";
import "./StaffSettings.scss";
import TimeOffSettings from "../../components/Settings/Staff/WorkTimeSettings/TimeOffSettings/TimeOffSettings";
import { getGroups } from "../../api/organization";

import { OptionsObject, SnackbarMessage, useSnackbar } from "notistack";
import {
  timeUntilSaveText,
  timeUntilSaveSelect,
} from "../../constants/settings";

type StaffSettingsProps = {
  staff: IUser;
  setStaff: React.Dispatch<React.SetStateAction<IUser | null>>;
  allergies: IUserAllergen[];
  languages: IUserLanguage[];
  onDelete: () => void;
  onSave: () => void;
  organizationId: string;
  staffGroups: IGroup[] | null;
};

const StaffSettings = (props: StaffSettingsProps) => {
  const { t } = useTranslation();
  const translate = (key: string) => t(`translations:userPage.${key}`);
  const [staff, setStaff] = useState(props.staff);
  const hasRestrictions = !!props.staff.restrictions;
  const [areas, setAreas] = useState<IGroup[] | null>([]);
  const [staffGroups, setStaffGroups] = useState(props.staffGroups);
  const [loadingDeleteStaff, setLoadingDeleteStaff] = useState(false);
  const [confirmingDeleteStaff, setConfirmingDeleteStaff] = useState(false);

  useEffect(() => {
    setStaff(props.staff);
    setStaffGroups(props.staffGroups);
    getGroups(props.organizationId).then((res) => {
      setAreas(
        res.data.map(({ color, id, name }) => ({
          color,
          id,
          name,
        }))
      );
    });
  }, [props.organizationId, props.staff, props.staffGroups]);

  const onStaffRestrictionsChanged = (
    key: IUserRestrictionsKeys,
    value: any[]
  ) => {
    setStaff({
      ...staff,
      restrictions: {
        ...(staff.restrictions || {
          allergies: [],
          likes: [],
          dislikes: [],
          canMeetAll: true,
        }),
        [key]: value,
      },
    });
  };

  const onStaffDetailsChanged = (key: IUserDetailsKeys, value: any) => {
    if (!staff.details) return;
    setStaff({
      ...staff,
      details: { ...staff.details, [key]: value },
    });
  };

  const onStaffChanged = (key: IUserKeys, value: string) => {
    setStaff({ ...staff, [key]: value });
  };

  const onStaffLocationChanged = (key: IUserLocationKeys, value: string) => {
    if (!staff.details) return;
    setStaff({
      ...staff,
      details: {
        ...staff.details,
        location: { ...staff.details.location!, [key]: value },
      },
    });
  };

  const { enqueueSnackbar } = useSnackbar();

  const onDataSaved = useCallback(
    (message: SnackbarMessage, options: OptionsObject) => {
      enqueueSnackbar(message, options);
      props.onSave();
      props.setStaff(staff);
    },
    [enqueueSnackbar, props, staff]
  );

  useEffect(() => {
    //on name updated
    if (
      props.staff.firstName === staff.firstName &&
      props.staff.lastName === staff.lastName &&
      props.staff.password === staff.password &&
      props.staff.email === staff.email
    )
      return;
    const timer = setTimeout(() => {
      axios
        .patch(`user/${props.staff.id}`, {
          firstName: staff.firstName,
          lastName: staff.lastName,
          password: staff.password,
          email: staff.email,
        })
        .then(() => {
          props.staff.password = staff.password; //update fields with new data to avoid need to fetch
          props.staff.firstName = staff.firstName; //if we don't do this we need to fetch data
          props.staff.lastName = staff.lastName; //or we won't update if the original values are set again
          props.staff.email = staff.email;
          onDataSaved(translate("nameInfoUpdated"), { variant: "success" });
        })
        .catch((exception) => {
          let errors = exception.response?.data?.errors;
          if (
            errors !== undefined &&
            errors.length > 0 &&
            errors[0].code === "SIMPLE-VALIDATION-ERROR" &&
            errors[0].parameter === "email"
          ) {
            onDataSaved(translate(translate("unvalidEmail")), {
              variant: "error",
            });
          } else {
            onDataSaved(translate("unknownErrorOccured"), {
              variant: "error",
            });
          }
        });
    }, timeUntilSaveText);
    return () => {
      clearTimeout(timer);
    };
  }, [
    staff.firstName,
    staff.lastName,
    staff.password,
    staff.email,
    onDataSaved,
    props.staff,
  ]);

  useEffect(() => {
    //on details updated
    if (!staff.details || props.staff.details === staff.details) return;
    const timer = setTimeout(() => {
      axios
        .patch(`user/${props.staff.id}/details`, {
          ...staff.details,
          languages: staff.details?.languages.map((lang) => lang.id),
          allergens: staff.details?.allergens.map((aller) => aller.id),
        })
        .then(() => {
          onDataSaved("Information uppdaterades", { variant: "success" });
        })
        .catch(() => {
          onDataSaved("Ett okänt fel inträffade", {
            variant: "error",
          });
        });
    }, timeUntilSaveText);
    return () => {
      clearTimeout(timer);
    };
  }, [staff.details, onDataSaved, props.staff]);

  useEffect(() => {
    //on restrictions updated
    if (staff.restrictions === props.staff.restrictions) return;

    const timer = setTimeout(() => {
      if (hasRestrictions) {
        //update restrictions since they already exist
        axios
          .patch(`user/${props.staff.id}/restrictions`, {
            likes: staff.restrictions!.likes.map((user) => user.id),
            dislikes: staff.restrictions!.dislikes.map((user) => user.id),
            allergies: staff.restrictions!.allergies.map((aller) => aller.id),
          })
          .then(() => {
            onDataSaved("Begränsningar uppdaterades", {
              variant: "success",
            });
          })
          .catch(() => {
            onDataSaved("Ett okänt fel inträffade", {
              variant: "error",
            });
          });
      } else {
        //create new restrictions since they don't exist
        axios
          .post(`user/${props.staff.id}/restrictions`, {
            likes: (staff.restrictions?.likes || []).map((user) => user.id),
            dislikes: (staff.restrictions?.dislikes || []).map(
              (user) => user.id
            ),
            allergies: (staff.restrictions?.allergies || []).map(
              (aller) => aller.id
            ),
          })
          .then(() => {
            onDataSaved("Begränsningar skapades", {
              variant: "success",
            });
          })
          .catch(() => {
            onDataSaved("Ett okänt fel inträffade", {
              variant: "error",
            });
          });
      }
    }, timeUntilSaveSelect);
    return () => {
      clearTimeout(timer);
    };
  }, [
    hasRestrictions,
    staff.restrictions,
    staff.restrictions?.allergies,
    staff.restrictions?.dislikes,
    staff.restrictions?.likes,
    onDataSaved,
    props.allergies,
    props.staff,
  ]);

  const onDelete = () => {
    axios
      .delete(`user/${props.staff.id}`)
      .then(() => {
        setConfirmingDeleteStaff(false);
        setLoadingDeleteStaff(false);
        enqueueSnackbar("Successfully deleted staff", {
          variant: "success",
          autoHideDuration: 1000,
        });
        props.onDelete();
      })
      .catch(() => {
        setConfirmingDeleteStaff(false);
        setLoadingDeleteStaff(false);
        enqueueSnackbar("Failed to delete staff", {
          variant: "error",
          autoHideDuration: 1000,
        });
      });
  };

  return (
    <div className="staff-settings">
      <div className="staff-settings--name">
        <div
          className="image"
          style={(function () {
            if (props.staffGroups && props.staffGroups[0]) {
              return {
                background: props.staffGroups[0].color,
              };
            } else {
              return {
                background: "black",
              };
            }
          })()}
        >
          <i className="fas fa-user" />
        </div>
        <div className="name">{`${staff.firstName} ${staff.lastName}`}</div>
      </div>
      {staff.details && (
        <>
          <NameSex
            firstName={staff.firstName}
            onFirstNameChange={(value) => onStaffChanged("firstName", value)}
            lastName={staff.lastName}
            onLastNameChange={(value) => onStaffChanged("lastName", value)}
            gender={staff.details.gender!}
            onGenderChange={(gender) => onStaffDetailsChanged("gender", gender)}
            employmentType={staff.details.employmentType}
            onEmploymentTypeChange={(employmentType) =>
              onStaffDetailsChanged("employmentType", employmentType)
            }
          />
          <ContactInfo
            email={staff.email || ""}
            phoneNumber={staff.details.phoneNumber || ""}
            onEmailChange={(email) => onStaffChanged("email", email)}
            onPhoneNumberChange={(phoneNumber) =>
              onStaffDetailsChanged("phoneNumber", phoneNumber)
            }
            password={staff.password || ""}
            onPasswordChange={(password) =>
              onStaffChanged("password", password)
            }
          />
          <Location
            isStaff={true}
            address={staff.details.location?.address || ""}
            onAddressChange={(value) =>
              onStaffLocationChanged("address", value)
            }
            postalCode={staff.details.location?.postalCode || ""}
            onPostalCodeChange={(value) =>
              onStaffLocationChanged("postalCode", value)
            }
            transportMethod={staff.details?.transportMethods?.[0] || ""}
            onTransportMethodChange={(value) =>
              onStaffDetailsChanged("transportMethods", [value])
            }
            staffGroups={staffGroups}
            onStaffGroupsChange={(newStaffgroups) => {
              const staffGroupsToSend = newStaffgroups.map((group) => group.id);
              axios
                .patch(
                  `organization/${props.organizationId}/user/${props.staff.id}`,
                  {
                    group: staffGroupsToSend,
                  }
                )
                .then(() => {
                  if (props.staffGroups !== null && staffGroups != null)
                    // props.staffGroups = [...staffGroups];
                    setStaffGroups([...newStaffgroups]);
                  onDataSaved("Område uppdaterades", { variant: "success" });
                })
                .catch(() => {
                  onDataSaved("Ett okänt fel inträffade", {
                    variant: "error",
                  });
                });
            }}
            //TODO: Areas won't be set from here anymore.
            // onAreaAdded={(areaName) => {
            //   createGroup(props.organizationId, areaName).then((pRes) =>
            //     getGroups(props.organizationId).then((gRes) => {
            //       setAreas(
            //         gRes.data.map(({ color, id, name }) => ({
            //           color,
            //           id,
            //           name,
            //         }))
            //       );
            //       setStaffGroups(pRes.data);
            //       // setStaffGroupId(pRes.data.id);
            //     })
            //   );
            // }}
            areas={areas}
          />

          <div className="staff-settings--title">{translate("avialableWorkTimes")}</div>
          <WorkTimeSettings userId={staff.id} isWorkShift />
          {/* <div className="staff-settings--title">Raster</div>
          <WorkTimeSettings userId={staff.id} /> */}
          <div className="staff-settings--title">
            {translate("unavailableDays")}
          </div>
          <TimeOffSettings />
          <div className="staff-settings--title">{translate("generalConditions")}</div>
          <DropdownMultiple
            title={translate("allergies")}
            values={staff.restrictions?.allergies || []}
            options={props.allergies.map((allergy) => ({
              label: allergy.name,
              value: allergy,
            }))}
            getDisplayName={(allergy: IUserAllergen) => allergy.name}
            onRemove={(value: IUserAllergen) => {
              if (staff.restrictions) {
                const newArray = staff.restrictions.allergies.filter(
                  (allergen) => allergen.id !== value.id
                );
                onStaffRestrictionsChanged("allergies", newArray);
              }
            }}
            onSelect={(value: IUserAllergen) => {
              const newArray = [...(staff.restrictions?.allergies || [])];
              const isNotInArray =
                newArray.filter((allergy) => allergy.id === value.id).length ===
                0;
              if (isNotInArray) {
                newArray.push(value);
              }
              onStaffRestrictionsChanged("allergies", newArray);
            }}
          />
          <DropdownMultiple
            title={translate("languange")}
            values={staff.details.languages}
            options={props.languages.map((language) => ({
              label: language.name,
              value: language,
            }))}
            getDisplayName={(language: IUserLanguage) => language.name}
            onRemove={(value: IUserLanguage) => {
              if (staff.details) {
                const newArray = staff.details.languages.filter(
                  (language) => language.id !== value.id
                );
                onStaffDetailsChanged("languages", newArray);
              }
            }}
            onSelect={(value: IUserLanguage) => {
              const newArray = [...(staff.details?.languages || [])];
              const isNotInArray =
                newArray.filter((lang) => lang.id === value.id).length === 0;
              if (isNotInArray) {
                newArray.push(value);
              }
              onStaffDetailsChanged("languages", newArray);
            }}
          />
        </>
      )}
      <div className="staff-settings--buttons">
        <IconButton
          hasDoubleCheck={true} //double check only if the action is DELETE
          doubleCheckLabel={translate("areYouSure")}
          confirming={confirmingDeleteStaff}
          loadingText={translate("deleting")}
          isLoading={loadingDeleteStaff}
          onConfirmClicked={() => {
            setLoadingDeleteStaff(true);
            onDelete();
          }}
          onCancelClicked={() => {
            setConfirmingDeleteStaff(false);
          }}
          continueLabel={translate("yes")}
          cancelLabel={translate("no")}
          icon="delete"
          label={translate("deleteStaff")}
          customStyle="mt-48"
          onClick={() => {
            setConfirmingDeleteStaff(!confirmingDeleteStaff);
          }}
        />
        {/* <Button
          label={hasConfirmedDelete ? "Är du säker?" : "Radera personal"}
          color={hasConfirmedDelete ? "red" : "grey"}
          onClick={onDelete}
          isLoading={isDeleting}
          onMouseLeave={() => setHasConfirmedDelete(false)}
        /> */}
      </div>
    </div>
  );
};

export default StaffSettings;
