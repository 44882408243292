import { DateTime } from "luxon";
import { useEffect, useState, useCallback } from "react";
import { Calendar as BigCalendar, luxonLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { useSnackbar } from "notistack";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./customstyles.css";

import { updateScheduleEvent } from "../../api/schedule";
import { IScheduleEvent } from "../../types/schedule";
import CustomToolbar from "../../components/UI/TimeSelector/CustomReactBigCalendarToolbar";

const DragAndDropCalendar = withDragAndDrop(BigCalendar as any);

const localizer = luxonLocalizer(DateTime);

const EventComponent = ({ event }: any) => {
  const formatDate = (date: Date) =>
    date.toISOString().split("T")[1].slice(0, 5);
  const { start, end, title } = event;
  const bgColor = event.color !== "" ? event.color : "#0099e9";
  const stDate = formatDate(start);
  const enDate = formatDate(end);
  const formattedTitle = `${title}, ${stDate}-${enDate}`;

  return (
    <div
      className={`w-full h-full truncate rounded-md p-1 flex flex-col`}
      style={{ backgroundColor: `${bgColor}70` }}
    >
      <p className="text-black font-normal text-[12px]"> {formattedTitle}</p>
    </div>
  );
};

export type CalendarEvent = {
  id: string;
  title: string;
  description: string;
  allDay?: boolean;
  start: Date;
  end: Date;
  isSelected: boolean;
};

const Calendar = ({
  events,
  setDate,
  setSelectedEventIndex,
  onEventChange,
}: {
  events: IScheduleEvent[];
  setDate: (date: DateTime) => void;
  setSelectedEventIndex: React.Dispatch<React.SetStateAction<number>>;
  onEventChange: () => void;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [calendarEvents, setCalendarEvents] = useState<CalendarEvent[]>([]);
  const [draggableEvents, setDraggableEvents] =
    useState<CalendarEvent[]>(calendarEvents);

  useEffect(() => {
    const convertedEvents = events.map((event) => ({
      id: event.id,
      clientEventID: event.clientEventID,
      staff: event.staff,
      title: `${event.name}, ${event.client?.firstName}`,
      description: event.description,
      start: new Date(event.timeSpan.start),
      end: new Date(event.timeSpan.end),
      isSelected: false,
      color: (function getColorIfPossible() {
        if (
          event?.client?.group &&
          event.client.group[0] &&
          event.client.group[0].color
        ) {
          return event.client.group[0].color;
        } else return "";
      })(),
    }));
    setCalendarEvents(convertedEvents);
    setDraggableEvents(convertedEvents);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events]);

  const moveEvent = useCallback(
    ({ event, start, end, isAllDay: droppedOnAllDaySlot = false }) => {
      setDraggableEvents((prev: any) => {
        const { allDay } = event;
        if (!allDay && droppedOnAllDaySlot) {
          event.allDay = false;
        }
        const existing =
          prev.find((ev: CalendarEvent) => ev.id === event.id) ?? {};
        const filtered = prev.filter((ev: CalendarEvent) => ev.id !== event.id);
        return [...filtered, { ...existing, start, end }];
      });
      const eventIDToUpdate = event.clientEventID || event.id;

      const formattedStart = new Date(start).toISOString().split(".")[0] + "Z";
      const formattedEnd = new Date(end).toISOString().split(".")[0] + "Z";

      const updatedStaff = event.staff.map((staff: any) => {
        return {
          userID: staff.user.id,
          timeSpan: {
            start: formattedStart,
            end: formattedEnd,
          },
        };
      });
      updateScheduleEvent(eventIDToUpdate, {
        staff: updatedStaff,
        timeSpan: {
          start: formattedStart,
          end: formattedEnd,
        },
      })
        .then(() => {
          enqueueSnackbar("Successfully updated event", {
            variant: "success",
            autoHideDuration: 1000,
          });
          onEventChange();
        })
        .catch(() => {
          enqueueSnackbar("Failed to update event", {
            variant: "error",
            autoHideDuration: 1000,
          });
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setDraggableEvents, enqueueSnackbar]
  ); // eslint-disable-next-line react-hooks/exhaustive-deps

  const resizeEvent = useCallback(
    ({ event, start, end }) => {
      setDraggableEvents((prev: any) => {
        const existing =
          prev.find((ev: CalendarEvent) => ev.id === event.id) ?? {};
        const filtered = prev.filter((ev: CalendarEvent) => ev.id !== event.id);
        return [...filtered, { ...existing, start, end }];
      });
      const eventIDToUpdate = event.clientEventID || event.id;
      const formattedStart = new Date(start).toISOString().split(".")[0] + "Z";
      const formattedEnd = new Date(end).toISOString().split(".")[0] + "Z";
      const updatedStaff = event.staff.map((staff: any) => {
        return {
          userID: staff.user.id,
          timeSpan: {
            start: formattedStart,
            end: formattedEnd,
          },
        };
      });

      updateScheduleEvent(eventIDToUpdate, {
        staff: updatedStaff,
        timeSpan: {
          start: formattedStart,
          end: formattedEnd,
        },
      })
        .then(() => {
          enqueueSnackbar("Successfully updated event", {
            variant: "success",
            autoHideDuration: 1000,
          });
          onEventChange();
        })
        .catch(() => {
          enqueueSnackbar("Failed to update event", {
            variant: "error",
            autoHideDuration: 1000,
          });
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setDraggableEvents, enqueueSnackbar]
  ); // eslint-disable-next-line react-hooks/exhaustive-deps

  return (
    <DragAndDropCalendar
      className="w-full h-[700px]"
      localizer={localizer}
      events={draggableEvents}
      onNavigate={(date) => {
        setDate(DateTime.fromJSDate(date));
      }}
      defaultView="day"
      onEventDrop={moveEvent}
      onEventResize={resizeEvent}
      onSelectEvent={(calendarEvent: any) => {
        if (calendarEvent) {
          setSelectedEventIndex(
            events.indexOf(
              events.find((event) => event.id === calendarEvent.id)!
            )
          );
        }
      }}
      components={{
        toolbar: CustomToolbar,
        event: EventComponent,
      }}
      startAccessor="start"
      endAccessor="end"
      showMultiDayTimes={true}
      resizable={true}
      selectable={true}
      popup={true}
      step={45}
    />
  );
};

export default Calendar;
