import { IWorkTime } from "../../../../../types/organization";
import "./WorkTime.scss";

type WorkTimeProps = {
  day: string;
  workTimes: IWorkTime[];
  onAdd: () => void;
  onEdit: (wt: IWorkTime) => void;
  onRemove: (id: string) => void;
};

const WorkTime = (props: WorkTimeProps) => {
  return (
    <div className="work-time">
      <div className="work-time--day">{props.day}</div>
      {props.workTimes.map((wt) => (
        <div key={wt.id} className="work-time--time">
          <div className="time">
            {`${wt.timeSpan.startTime!.slice(
              0,
              5
            )}-${wt.timeSpan.endTime!.slice(0, 5)}`}
          </div>
          <div className="buttons">
            <i className="fas fa-pen" onClick={() => props.onEdit(wt)} />
            <i className="fas fa-trash" onClick={() => props.onRemove(wt.id)} />
          </div>
        </div>
      ))}
      {!props.workTimes.length && (
        <i className="fas fa-plus-circle" onClick={props.onAdd} />
      )}
    </div>
  );
};

export default WorkTime;
