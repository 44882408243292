import { useTranslation } from "react-i18next";
import { ReactComponent as SwedenFlagIcon } from "./sweden.svg";
import { ReactComponent as UKFlagIcon } from "./uk.svg";

const MySettings = () => {
  const { i18n } = useTranslation();

  const setLanguage = (code: string) => {
    return i18n.changeLanguage(code);
  };

  return (
    <div className="card-container flex-[4] items-start">
      <p className={`bold-text self-start`}>Language</p>
      <div className="divider-line" />
      <div
        onClick={() => setLanguage("en-US")}
        className="flex flex-row mt-4 items-center  hover:cursor-pointer"
      >
        <UKFlagIcon
          style={{
            backgroundColor: i18n.language === "en-US" ? "#11FFBD50" : "white",
          }}
          className="w-8 h-8 p-1 mr-2 rounded-full outline-1"
        />
        <p
          style={{
            fontWeight: i18n.language === "en-US" ? "bold" : "400",
          }}
        >
          English
        </p>
      </div>
      <div
        onClick={() => setLanguage("se")}
        className="flex flex-row pt-4 items-center hover:cursor-pointer"
      >
        <SwedenFlagIcon
          style={{
            backgroundColor: i18n.language === "se" ? "#11FFBD50" : "white",
          }}
          className="w-8 h-8 p-1 mr-2 rounded-full outline-1"
        />
        <p
          style={{
            fontWeight: i18n.language === "se" ? "bold" : "400",
          }}
        >
          Swedish
        </p>
      </div>
    </div>
  );
};

export default MySettings;
