import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";

type ClientSidePaginationProps = {
  canNextPage: boolean;
  canPreviousPage: boolean;
  onPrevious: () => void;
  onNext: () => void;
  pageIndex: number;
};

const ClientSidePagination = ({
  canNextPage,
  canPreviousPage,
  onNext,
  onPrevious,
  pageIndex,
}: ClientSidePaginationProps) => (
  <div className="pagination container styles go here">
    <FaArrowCircleLeft
      fill={canPreviousPage ? "green" : "grey"}
      style={{ cursor: canPreviousPage ? "pointer" : "default" }}
      onClick={() => {
        if (canPreviousPage) {
          onPrevious();
        }
      }}
    />
    <div className="pagination current page">{pageIndex + 1}</div>
    <FaArrowCircleRight
      fill={canNextPage ? "green" : "grey"}
      style={{ cursor: canNextPage ? "pointer" : "default" }}
      onClick={() => {
        if (canNextPage) {
          onNext();
        }
      }}
    />
  </div>
);

export default ClientSidePagination;
