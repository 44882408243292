import { FaUser, FaBuilding, FaCalendarCheck } from "react-icons/fa";
import { truncate } from "lodash";

type ItemSelectProps = {
  selected: boolean;
  color: string;
  onClick: () => void;
  name: string;
  type?: "user" | "group" | "schedule";
  lastItem?: boolean;
};

const ItemSelect = ({
  selected = false,
  color = "#000",
  onClick,
  type = "user",
  name,
  lastItem,
}: ItemSelectProps): JSX.Element => {
  const classNames = {
    container: `flex items-center py-2 px-1 rounded-lg w-full bold-text hover:bg-[#fafafa] transition-all duration-200 ease-out ${
      selected && "bg-mint hover:bg-mint"
    }`,
    imageWrapper: `flex items-center justify-center w-8 h-8 rounded-2xl mr-4`,
  };

  const renderIcon = (): JSX.Element => {
    switch (type) {
      case "user":
        return <FaUser fill="#fff" />;
      case "group":
        return <FaBuilding fill="#fff" />;
      case "schedule":
        return <FaCalendarCheck fill="#fff" />;
      default:
        return <div />;
    }
  };

  return (
    <>
      <div onClick={onClick} className={classNames.container}>
        <div
          className={classNames.imageWrapper}
          style={{ backgroundColor: color }}
        >
          {renderIcon()}
        </div>
        <p>{truncate(name, { length: 16 })}</p>
      </div>
      {!lastItem && <div className="divider-line my-2" />}
    </>
  );
};

export default ItemSelect;
