import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import {
  deletePlannedEventById,
  getPlannedEventsByUserId,
} from "../../../../api/organization";
import { getAllUsers } from "../../../../api/user";
import Popup from "../../../../hoc/Popup/Popup";
import { ReduxState } from "../../../../shared/Types";
import { IPlannedEvent } from "../../../../types/event";
import { IOrganizationUser } from "../../../../types/organization";
import AddPlannedEvent from "./AddPlannedEvent/AddPlannedEvent";
import PlannedEvent from "./PlannedEvent/PlannedEvent";
import "./PlannedEventSettings.scss";
import { useTranslation } from "react-i18next";
type PlannedEventSettingsProps = {
  userId: string;
  organizationId: string;
};

const PlannedEventSettings = (props: PlannedEventSettingsProps) => {
  const { t } = useTranslation();
  const translate = (key: string) => t(`translations:userPage.${key}`);
  const [showAddPopup, setShowAddPopup] = useState(false);
  const [plannedEvents, setPlannedEvents] = useState<IPlannedEvent[]>([]);
  const [editPlannedEvent, setEditPlannedEvent] =
    useState<IPlannedEvent | null>(null);
  const [staffs, setStaffs] = useState<IOrganizationUser[]>([]);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getPlannedEventsByUserId(props.userId, props.organizationId).then((res) =>
      setPlannedEvents(res.data)
    );
    getAllUsers(props.organizationId).then((res) =>
      setStaffs(res.data.filter((u) => u.role === "STAFF"))
    );
  }, [props.organizationId, props.userId]);

  const onDelete = (id: string) => {
    if (window.confirm(translate("doYouReallyWantToRemoveThisevent"))) {
      deletePlannedEventById(id)
        .then(() => {
          getPlannedEventsByUserId(props.userId, props.organizationId)
            .then((res) => setPlannedEvents(res.data))
            .then(() => {
              enqueueSnackbar(translate("eventRemoved"), { variant: "success" });
            });
        })
        .catch(() => {
          enqueueSnackbar(translate("failedRemovingEvent"), {
            variant: "error",
          });
        });
    }
  };

  const onEdit = (pe: IPlannedEvent) => {
    setEditPlannedEvent(pe);
    setShowAddPopup(true);
  };

  return (
    <>
      <div className="planned-event-settings">
        <div className="planned-event-settings--title">{translate("acceptedTasks")}</div>
        <div className="planned-event-settings--events">
          {plannedEvents.map((pe) => (
            <PlannedEvent
              key={pe.id}
              specificStaff={pe.staff
                ?.map(
                  (staff) =>
                    `${staff.user?.firstName || "Alla"} ${
                      staff.user?.lastName || ""
                    }`
                )
                .join(", ")}
              id={pe.id!}
              title={pe.name}
              duration={pe.duration}
              days={pe.timeSpan.map((ts) => ts.weekday!)}
              startTime={pe.timeSpan[0]?.startTime || ""}
              endTime={pe.timeSpan[0]?.endTime || ""}
              onEdit={() => onEdit(pe)}
              onDelete={onDelete}
              isPrioritized={pe.prioritized}
            />
          ))}
          <div className="planned-event-settings--events__add">
            <p>{translate("addEvent")}</p>
            <i
              className="fas fa-plus-circle"
              onClick={() => setShowAddPopup(true)}
            />
          </div>
        </div>
      </div>
      <Popup
        showPopup={showAddPopup}
        onClose={() => {
          setShowAddPopup(false);
          setEditPlannedEvent(null);
        }}
      >
        <AddPlannedEvent
          isEditing={!!editPlannedEvent}
          editPannedEvent={editPlannedEvent}
          userId={props.userId}
          onSaved={() => {
            setShowAddPopup(false);
            getPlannedEventsByUserId(props.userId, props.organizationId).then(
              (res) => setPlannedEvents(res.data)
            );
            setEditPlannedEvent(null);
          }}
          staffs={staffs}
        />
      </Popup>
    </>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  organizationId: state.auth.organizationId,
});

export default connect(mapStateToProps)(PlannedEventSettings);
