const transformStatisticResponseData = (
  statisticsResponse: StatisticsResponse
): StatisticTableData => {
  const { staff, clients } = statisticsResponse;

  const flattenedStaffData: StatisticStaffTableData[] = staff.map(
    ({ user: { user, group, externalID }, statistics }: StaffStatistic) => {
      const { id, email, username, firstName, lastName } = user;

      const { nEvents, efficiency, daysOff, timeWorked } = statistics;
      return {
        externalID,
        id,
        email,
        username,
        firstName,
        lastName,
        ...(group &&
          group[0] && {
            groupName: group[0].name,
            groupColor: group[0].color,
            groupId: group[0].id,
          }),
        nEvents,
        efficiency,
        daysOff,
        timeWorked,
      };
    }
  );

  const flattenedClientData: StatisticClientTableData[] = clients.map(
    ({ user: { user, group, externalID }, statistics }: ClientStatistic) => {
      const { id, email, username, firstName, lastName } = user;
      const {
        continuity,
        nEvents,
        timeNotCancelled,
        cancelled: { atDoor, inAdvance, otherReason },
      } = statistics;
      return {
        externalID,
        id,
        email,
        username,
        firstName,
        lastName,
        ...(group &&
          group[0] && {
            groupName: group[0].name,
            groupColor: group[0].color,
            groupId: group[0].id,
          }),
        continuity,
        nEvents,
        timeNotCancelled,
        atDoor,
        inAdvance,
        otherReason,
      };
    }
  );

  return { staffData: flattenedStaffData, clientsData: flattenedClientData };
};

export default transformStatisticResponseData;
