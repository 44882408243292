import { useEffect, useState, useContext } from "react";
import { connect, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import TextButton from "../../components/UI/TextButton/TextButton";
import { ReduxState } from "../../shared/Types";
import Schedule from "../Schedule/Schedule";
import { SocketContext } from "../../socket";
import ScheduleOverview from "../ScheduleOverview/ScheduleOverview";
import ScheduleEdit from "../Schedule/ScheduleResourcesCalendar";
import { useTranslation } from "react-i18next";
import "./SchedulePage.scss";

type SchedulePageProps = {
  organizationId: string;
};

const SchedulePage = (props: SchedulePageProps) => {
  const { t } = useTranslation();
  const translate = (key: string) => t(`translations:schedulePage.${key}`);
  const { isConnected, initialize } = useContext(SocketContext);
  const state = useSelector((state: ReduxState) => state);
  const [scheduleMode, setScheduleMode] = useState<
    "edit" | "individual" | "new"
  >("edit");
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (location.pathname.includes("/individuell")) {
      setScheduleMode("individual");
    } else if (location.pathname.includes("/redigera")) {
      setScheduleMode("edit");
    } else if (location.pathname.includes("/edit")) {
      setScheduleMode("new");
    }
  }, [location]);

  useEffect(() => {
    if (state.auth.role === "ADMIN") {
      if (!isConnected()) {
        initialize();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="schedule-page">
      <div className="schedule-page--top">
        {state.auth.role === "ADMIN" && (
          <TextButton
            label={translate("navigationCreateSchedule")}
            active={scheduleMode === "edit"}
            onClick={() => {
              navigate("/schema/redigera");
            }}
          />
        )}
        <TextButton
          label={translate("navigationIndividualSchedule")}
          active={scheduleMode === "individual"}
          onClick={() => {
            navigate("/schema/individuell");
          }}
        />
        <TextButton
          label={translate("navigaionEditSchedule")}
          active={scheduleMode === "new"}
          onClick={() => {
            navigate("/schema/edit");
          }}
        />
      </div>
      {scheduleMode === "edit" && (
        <ScheduleOverview organizationId={props.organizationId} showEdit />
      )}
      {scheduleMode === "individual" && (
        <Schedule organizationId={props.organizationId} />
      )}
      {scheduleMode === "new" && <ScheduleEdit />}
    </div>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  organizationId: state.auth.organizationId,
});

export default connect(mapStateToProps)(SchedulePage);
