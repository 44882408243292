import TimeSelector from ".";

const CustomToolbar = (toolbar: any) => {
  const onBack = () => toolbar.onNavigate("PREV");
  const onNext = () => toolbar.onNavigate("NEXT");
  const onToday = () => toolbar.onNavigate("TODAY");
  const onExactDate = (date: Date) => {
    //hacky solution to achieve custom date navigation
    //since we use onNavigate("NEXT",date)
    //we have to decrease date by 1 day, so when calendar runs next into it,
    //we get the desired date
    const newDate = new Date(date.toDateString());
    newDate.setDate(newDate.getDate() - 1);

    //set the toolbar view daily
    toolbar.onView("day");
    toolbar.onNavigate("NEXT", newDate);
  };

  //viewTypes
  const onDailyView = () => toolbar.onView("day");
  const onMonthlyView = () => toolbar.onView("month");
  const onWeeklyView = () => toolbar.onView("week");
  const onAgendaView = () => toolbar.onView("agenda");

  //label
  const label = () => {
    return <div>{toolbar.label}</div>;
  };

  return (
    <TimeSelector
      constainerCustomStyles="mb-4"
      label={label}
      onDailyView={onDailyView}
      onMonthlyView={onMonthlyView}
      onWeeklyView={onWeeklyView}
      onAgendaView={onAgendaView}
      onBack={onBack}
      onNext={onNext}
      onToday={onToday}
      onExactDate={onExactDate}
    />
  );
};

export default CustomToolbar;
