import { truncate } from "lodash";

import {
  FaUpload,
  FaPlusCircle,
  FaTrashAlt,
  FaTimesCircle,
} from "react-icons/fa";

type IconButtonProps = {
  label: string;
  icon?: "upload" | "add" | "cancel" | "delete" | "none";
  hasDoubleCheck?: boolean;
  confirming?: boolean;
  doubleCheckLabel?: string;
  continueLabel?: string;
  cancelLabel?: string;
  onClick?: () => void;
  onConfirmClicked?: () => void;
  onCancelClicked?: () => void;
  isLoading?: boolean;
  loadingText?: string;
  customStyle?: string;
  type?: "submit" | "button" | "reset" | undefined;
};

const IconButton = ({
  label,
  icon,
  doubleCheckLabel,
  continueLabel,
  confirming,
  onCancelClicked,
  onConfirmClicked,
  isLoading,
  loadingText,
  onClick,
  cancelLabel,
  customStyle = "",
  type = undefined,
}: IconButtonProps): JSX.Element => {
  const renderActions = (): JSX.Element => (
    <div className="flex flex-row ml-4 gap-2 items-center">
      <button
        type={type}
        onClick={onConfirmClicked}
        className="flex items-center align-center h-4 rounded-4 pt-2 pb-2 pl-4 pr-4 bg-white bold-text text-red-500"
      >
        {continueLabel}
      </button>
      <button
        onClick={onCancelClicked}
        className="flex items-center align-center h-4 rounded-4 pt-2 pb-2 pl-4 pr-4 bold-text bg-white text-dark-text ml-2"
      >
        {cancelLabel}
      </button>
    </div>
  );

  const renderIcon = (): JSX.Element => {
    switch (icon) {
      case "add":
        return <FaPlusCircle />;
      case "cancel":
        return <FaTimesCircle />;
      case "upload":
        return <FaUpload />;
      case "delete":
        return <FaTrashAlt />;
      case "none":
        return <></>;
      default:
        return <></>;
    }
  };

  const renderLoader = (): JSX.Element => <div>...</div>;

  const renderButtonContent = (): JSX.Element => {
    //default button state
    let text = label;
    let fn = renderIcon;

    if (!!confirming && !isLoading) {
      text = truncate(doubleCheckLabel!, { length: 20 }); //purposly with exclamation mark so it will throw error if a label is missing
      fn = renderActions;
    }
    if (isLoading) {
      text = loadingText!;
      fn = renderLoader;
    }

    return (
      <>
        {text} {fn()}
      </>
    );
  };

  return (
    <div
      className={`h-11 rounded-3xl bold-text text-left flex items-center justify-between gap-2 pl-4 pr-4 transition-all duration-500 ease-out overflow-ellipsis cursor-pointer ${customStyle} ${
        confirming ? "bg-red-200" : "bg-grey"
      }`}
      onClick={onClick}
    >
      {renderButtonContent()}
    </div>
  );
};
export default IconButton;
