import { DateTime } from "luxon";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { getIndividualSchedule } from "../../api/schedule";
import { getAllUsers } from "../../api/user";
import UserSelector from "../../components/UserSelector/UserSelector";
import Popup from "../../hoc/Popup/Popup";
import { useWindowDimensions } from "../../shared/hooks";
import { dateHoursMinute, getFromAndToFromWeek } from "../../shared/utility";
import { IOrganizationUser } from "../../types/organization";
import { IScheduleEvent } from "../../types/schedule";
import Calendar from "./Calendar";
import "./Schedule.scss";
import ScheduleInfoBox from "./ScheduleInfoBox/ScheduleInfoBox";
import { useTranslation } from "react-i18next";
type ScheduleProps = {
  organizationId: string;
};

const Schedule = (props: ScheduleProps) => {
  const scheduleType = "LIVE";
  const [users, setUsers] = useState<IOrganizationUser[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<IOrganizationUser[]>([]);
  const [scheduleEvents, setScheduleEvents] = useState<IScheduleEvent[]>([]);
  const [selectedEventIndex, setSelectedEventIndex] = useState(-1);
  const [role, setRole] = useState<"STAFF" | "CLIENT">("STAFF");
  const [chosenUserId, setChosenUserId] = useState("");
  const [date, setDate] = useState(DateTime.now());
  const dimensions = useWindowDimensions();
  const { t } = useTranslation();
  const translate = (key: string) => t(`translations:schedulePage.${key}`);
  const navigate = useNavigate();

  const search = useCallback(
    (searchQuery: string) => {
      setFilteredUsers(
        searchQuery
          ? users.filter(
              (user) =>
                user.user.firstName
                  .toLowerCase()
                  .startsWith(searchQuery.toLowerCase()) ||
                user.user.lastName
                  .toLowerCase()
                  .startsWith(searchQuery.toLowerCase())
            )
          : users
      );
    },
    [users]
  );

  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (users.length > 0) {
      const userId = searchParams.get("userId");
      const urlDate = searchParams.get("date");
      if (urlDate) {
        setDate(DateTime.fromISO(urlDate));
      }
      if (userId) {
        const foundUser = users
          .filter((user) => user.role === role)
          .find((user) => user.user.id === userId);
        setChosenUserId(userId);
        if (!foundUser) {
          setRole("CLIENT");
        }
      } else if (!chosenUserId) {
        const firstUser = users.find((user) => user.role === role)?.user.id!;
        setChosenUserId(firstUser);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  useEffect(() => {
    getAllUsers(props.organizationId).then((res) => {
      setUsers(res.data);
      setFilteredUsers(res.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.organizationId]);

  useEffect(() => {
    if (chosenUserId && date) {
      navigate({ search: `?userId=${chosenUserId}&date=${date.toISODate()}` });
    }
  }, [chosenUserId, date, navigate]);

  useEffect(() => {
    if (chosenUserId) {
      const from = date.startOf("month").toISODate();
      const to = date.endOf("month").toISODate();
      /** Här kan ni ändra den första tomma strängen med 'from' och andra tomma strängen med 'to' */
      getIndividualSchedule(chosenUserId, from, to, scheduleType).then(
        (res) => {
          setScheduleEvents(res.data);
        }
      );
    }
  }, [chosenUserId, scheduleType, date]);

  useEffect(() => {
    if (!chosenUserId) {
      setChosenUserId(users.find((u) => u.role === role)?.user.id || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);

  const reloadSchedule = () => {
    let fromTo = ["", ""];
    if (date) {
      fromTo = getFromAndToFromWeek(date);
    }
    const [from, to] = fromTo;
    getIndividualSchedule(chosenUserId, from, to, scheduleType).then((res) => {
      if (!date) {
        setDate(DateTime.fromISO(res.data[0].timeSpan.start));
      }
      setScheduleEvents(res.data);
    });
  };

  return (
    <div className="w-full flex flex-row h-full">
      <div className="mr-4">
        <UserSelector
          users={filteredUsers}
          isStaff={role === "STAFF"}
          selectedUserId={chosenUserId}
          onSelectUser={(s) => setChosenUserId(s)}
          onRoleChange={setRole}
          search={search}
        />
      </div>
      <div className="card-container flex-1 items-start self-stretch">
        <Calendar
          events={scheduleEvents}
          setDate={setDate}
          setSelectedEventIndex={setSelectedEventIndex}
          onEventChange={reloadSchedule}
        />
      </div>
      {selectedEventIndex >= 0 && scheduleEvents.length > 0 && (
        <Popup
          onClose={() => {
            setSelectedEventIndex(-1);
          }}
          showPopup={selectedEventIndex >= 0 && scheduleEvents.length > 0}
        >
          <ScheduleInfoBox
            eventId={scheduleEvents[selectedEventIndex].id}
            title={scheduleEvents[selectedEventIndex].name}
            startTime={dateHoursMinute(
              new Date(scheduleEvents[selectedEventIndex].timeSpan.start)
            )}
            endTime={dateHoursMinute(
              new Date(scheduleEvents[selectedEventIndex].timeSpan.end)
            )}
            address={
              scheduleEvents[selectedEventIndex].client?.location.address ||
              translate("clientAddress")
            }
            otherUser={
              scheduleEvents[selectedEventIndex].client ||
              scheduleEvents[selectedEventIndex].staff?.[0] ||
              translate("noNameFound")
            }
            cancelReason={scheduleEvents[selectedEventIndex].cancelled}
            description={scheduleEvents[selectedEventIndex].description}
            mapsLink={
              scheduleEvents.length - 1 > selectedEventIndex && role === "STAFF"
                ? `https://www.google.com/maps/dir/${
                    scheduleEvents[selectedEventIndex].client?.location?.address
                  } ${
                    scheduleEvents[selectedEventIndex].client?.location
                      ?.postalCode
                  }/${
                    scheduleEvents[selectedEventIndex + 1].client?.location
                      ?.address
                  } ${
                    scheduleEvents[selectedEventIndex + 1].client?.location
                      ?.postalCode
                  }/`
                : ""
            }
            isStaff={role === "STAFF"}
            isMobile={dimensions.width < 1000}
            onPopupClose={() => setSelectedEventIndex(-1)}
            onRebook={() => {
              setSelectedEventIndex(-1);
              reloadSchedule();
            }}
          />
        </Popup>
      )}
    </div>
  );
};

export default Schedule;
