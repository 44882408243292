/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import "./App.scss";
import "./shared/WavesEffect/waves.css";
import "./shared/WavesEffect/waves.js";
import { Route, Routes, useNavigate, useLocation } from "react-router";
import * as actions from "./store/actions/index";
import Login from "./containers/Login/Login";
import { useDispatch, useSelector } from "react-redux";
import Layout from "./hoc/Layout/Layout";
import { ReduxState } from "./shared/Types";

import { ROUTES } from "./constants/settings";

//Main pages
import SchedulePage from "./containers/SchedulePage/SchedulePage";
import SettingsPage from "./containers/SettingsPage/SettingsPage";
import Statistics from "./containers/Statistics";
import GeneralSettings from "./containers/GeneralSettings";
//Sub General settings routes
import SchedulePresettings from "./containers/GeneralSettings/Schedule/Presettings";
import UserGroups from "./containers/GeneralSettings/Users/Groups";
import EventTypes from "./containers/GeneralSettings/Users/EventType";
import MySettings from "./containers/GeneralSettings/MySettings";
import ScheduleHowToUse from "./containers/GeneralSettings/Schedule/HowToUse";
import HowToUse from "./containers/GeneralSettings/Schedule/HowToUse";

//TEST
import Test from "./containers/GeneralSettings/Test";

import Spinner from "./components/UI/Spinner/Spinner";

function App() {
  const state = useSelector((state: ReduxState) => state);
  const dispatch = useDispatch();
  const isAuthenticated = state.auth.token !== null;
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    dispatch(actions.authCheckState());
  }, []);

  useEffect(() => {
    if (state.auth.role === "ADMIN") {
      if (location.pathname === "/") {
        navigate("/schema/redigera");
      }
    } else if (state.auth.role === "STAFF") {
      if ((location.pathname = "/")) {
        navigate("/schema/individuell");
      }
    }
  }, [state.auth.role]);
  let routes = (
    <Layout>
      <Routes>
        <Route path="/installningar" element={<GeneralSettings />}>
          <Route
            path={ROUTES.settings.scheduleHowToUse}
            element={<ScheduleHowToUse />}
          />
          <Route
            path={ROUTES.settings.schedulePreSettings}
            element={<SchedulePresettings />}
          />
          <Route
            path={ROUTES.settings.statisticsHowToUse}
            element={<div className="flex-[4]">Statistic how use</div>}
          />
          <Route
            path={ROUTES.settings.statisticsHandlingYourData}
            element={
              <div className="flex-[4]">Statistics handling your data</div>
            }
          />
          <Route path={ROUTES.settings.usersHowToUse} element={<HowToUse />} />
          <Route path={ROUTES.settings.usersGroups} element={<UserGroups />} />
          <Route
            path={ROUTES.settings.usersEventType}
            element={<EventTypes />}
          />
          <Route
            path={ROUTES.settings.myAccountSettings}
            element={<MySettings />}
          />
          <Route
            path={ROUTES.settings.ourCompanyAdminAccounts}
            element={<div className="flex-[4]">Our company admin accounts</div>}
          />
          <Route
            path={ROUTES.settings.ourCompanyPrenumertaion}
            element={<div className="flex-[4]">Our company prenumeration</div>}
          />
          <Route
            path={ROUTES.settings.ourCompanyCostumization}
            element={<div className="flex-[4]">Our company costumization</div>}
          />
        </Route>
        <Route path="/anvandare/personal" element={<SettingsPage />} />
        <Route path="/anvandare/kunder" element={<SettingsPage />} />
        <Route path="/statistik/*" element={<Statistics />} />
        <Route path="/schema/individuell" element={<SchedulePage />} />
        <Route path="/schema/edit" element={<SchedulePage />} />
        <Route path="/schema/oversikt" element={<SchedulePage />} />
        <Route path="/schema/redigera" element={<SchedulePage />} />
      </Routes>
    </Layout>
  );

  if (!isAuthenticated) {
    routes = <Login />;
  }

  return state.auth.loading ? (
    <div className="app-loading">
      <Spinner />
    </div>
  ) : (
    routes
  );
}

export default App;
