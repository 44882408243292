import { useNavigate, useLocation } from "react-router-dom";

import Button from "../../../components/UI/NewButton";

import { NavigationGroup } from "./types";
import useNavConfig from "./config";

const NavigationItems = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const navigationConfig = useNavConfig();

  const NavigationItem = ({ routes, title }: NavigationGroup): JSX.Element => {
    return (
      <>
        <div>{title}</div>
        <div className="divider-line mb-2" />
        {routes.map(({ to, title }, index) => (
          <Button
            key={to}
            customStyle={`w-full mt-2 ${
              index === routes.length - 1 && "mb-8" //last item
            }`}
            title={title}
            type={(function setSelectedIfMatchesLocation() {
              if (location.pathname === to) {
                return "selected";
              } else {
                return "normal";
              }
            })()}
            onClick={() => navigate(to.trim())}
          />
        ))}
      </>
    );
  };

  return (
    <nav className="card-container flex-1">
      {navigationConfig.map(({ routes, title }) => (
        <NavigationItem key={title} routes={routes} title={title} />
      ))}
    </nav>
  );
};
export default NavigationItems;
