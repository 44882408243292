import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { object, string } from "yup";
import debounce from "lodash/debounce";
import { useSnackbar } from "notistack";

import {
  getGroups,
  createGroup,
  updateGroup,
  deleteGroup,
} from "../../../../api/organization";
import { ReduxState } from "../../../../shared/Types";
import { IGroup } from "../../../../types/user";

import IconButton from "../../../../components/UI/NewIconButton";
import ItemSelect from "../../../../components/UI/ItemSelect";
import Input from "../../../../components/Form/Input";
import ColorPicker from "../../../../components/Form/ColorPicker";
import { useTranslation } from "react-i18next";

const emptyGroup: IGroup = {
  id: "",
  name: "",
  color: "",
};

const Groups = (): JSX.Element => {
  const { t } = useTranslation();
  const translate = (key: string) => t(`translations:settingsPage.${key}`);
  const { enqueueSnackbar } = useSnackbar();

  //groups data
  const organizationId = useSelector(
    (state: ReduxState) => state.auth.organizationId
  );
  const [loadingGroups, setLoadingGroups] = useState(true);
  const [groups, setGroups] = useState<IGroup[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<IGroup | null>(null);
  const [fetchGroups, refetchGroups] = useState(0);

  //form
  const [isAddingNew, setIsAddingNew] = useState<boolean>(false);
  const [colorPickerVisible, setColorPickerVisible] = useState(false);
  const [confirmingDeletion, setConfirmingDeletion] = useState(false);
  const [loadingDeleting, setLoadingDeleting] = useState(false);

  useEffect(() => {
    setLoadingGroups(true);
    getGroups(organizationId)
      .then((groupsResponse) => {
        setLoadingGroups(false);
        const receivedGroups = groupsResponse.data;
        if (!receivedGroups.length) return;
        const formattedGroups = receivedGroups.map(({ id, name, color }) => ({
          id,
          name,
          color,
        }));
        setGroups(formattedGroups);
        if (!selectedGroup) {
          setSelectedGroup(formattedGroups[0]); //select the first one
        }
      })
      .catch(() => {
        setLoadingGroups(false);
        setSelectedGroup(emptyGroup);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchGroups]);

  const emptyGroups = !loadingGroups && !groups.length;

  const renderGroupsList = (): JSX.Element => {
    if (loadingGroups) {
      return <div />; //loader
    } else if (emptyGroups) {
      return <div />; //empty groups info
    } else {
      return (
        <>
          {groups.map(({ id, color, name }, index) => (
            <ItemSelect
              type="group"
              key={id}
              color={color}
              name={name}
              selected={selectedGroup?.id === id}
              onClick={() => {
                setSelectedGroup({ id, color, name });
                setIsAddingNew(false);
              }}
              lastItem={index === groups.length - 1}
            />
          ))}
        </>
      );
    }
  };

  const schema = object().shape({
    name: string()
      .required(translate("nameIsRequired"))
      .min(1, translate("minimumLengthNameText")),
    color: string().optional(),
  });

  const callCreateGroup = (name: string, color?: string) => {
    createGroup(organizationId, name, color)
      .then(() => {
        enqueueSnackbar(translate("succesfullySavedGroup"), {
          variant: "success",
        });
        refetchGroups(fetchGroups + 1);
      })
      .catch(() => {
        enqueueSnackbar(translate("failedSavingGroup"), { variant: "error" });
      });
  };

  const callUpdateGroup = (name: string, color?: string) => {
    if (!selectedGroup?.id) return;
    updateGroup(organizationId, selectedGroup.id, name, color)
      .then(() => {
        enqueueSnackbar(translate("succesfullyUpdatedGroup"), {
          variant: "success",
        });
        refetchGroups(fetchGroups + 1);
      })
      .catch(() => {
        enqueueSnackbar(translate("failedUpdatingGroup"), { variant: "error" });
      });
  };

  const callDeleteGroup = () => {
    if (!selectedGroup?.id) return;
    deleteGroup(organizationId, selectedGroup.id)
      .then(() => {
        setSelectedGroup(null);
        enqueueSnackbar(translate("succesfullyDeletedGroup"), {
          variant: "success",
        });
        setLoadingDeleting(false);
        setConfirmingDeletion(false);
        refetchGroups(fetchGroups + 1);
      })
      .catch(() => {
        enqueueSnackbar(translate("failedDeletingGroup"), { variant: "error" });
      });
  };

  const {
    submitForm,
    handleBlur,
    setFieldValue,
    handleSubmit,
    values,
    errors,
    touched,
  } = useFormik({
    validationSchema: schema,
    enableReinitialize: true, //IMPORTANT: this allows to reset the form data when another group is selected
    initialValues: selectedGroup ?? emptyGroup,
    onSubmit: (values) => {
      const { name, color } = values;
      if (isAddingNew) {
        callCreateGroup(name, color);
      } else {
        //is updating
        callUpdateGroup(name, color);
      }
    },
  });

  const debounceUpdateGr = useCallback(debounce(submitForm, 500), []); // eslint-disable-line react-hooks/exhaustive-deps

  const renderEditGroup = (): JSX.Element => (
    <>
      <div className="flex w-full self-start mt-4">
        <div className="flex flex-1 justify-between gap-14">
          <Input
            id="name"
            value={values.name}
            placeholder={translate("enterGroupName")}
            labelAbove={translate("name")}
            type="text"
            autoCapitalize="none"
            onChange={(e: any) => {
              setFieldValue("name", e.target.value);
              if (!isAddingNew) {
                //edit group happens on change
                debounceUpdateGr();
              }
            }}
            onBlur={handleBlur("name")}
            error={String(errors?.name ?? "")}
            touched={!!touched.name}
          />
          <ColorPicker
            labelAbove={translate("color")}
            pickerVisible={colorPickerVisible}
            onPreviewClicked={() => {
              setColorPickerVisible(!colorPickerVisible);
            }}
            color={values.color}
            error={String(errors.color || "")}
            touched={!!touched.color}
            onChangeComplete={(hex) => {
              setFieldValue("color", hex);
              setColorPickerVisible(false);
              if (!isAddingNew) {
                //edit group happens on change
                submitForm();
              }
            }}
          />
        </div>
      </div>
      <IconButton
        hasDoubleCheck={!isAddingNew} //double check only if the action is DELETE
        doubleCheckLabel={translate("areYouSure")}
        confirming={confirmingDeletion}
        loadingText={translate("deleting")}
        isLoading={loadingDeleting}
        onConfirmClicked={() => {
          setLoadingDeleting(true);
          callDeleteGroup();
        }}
        onCancelClicked={() => {
          setConfirmingDeletion(false);
        }}
        continueLabel={translate("Yes")}
        cancelLabel={translate("No")}
        icon={isAddingNew ? "add" : "delete"}
        label={isAddingNew ? translate("addGroup") : translate("deleteGroup")}
        customStyle="mt-48"
        onClick={() => {
          if (isAddingNew) {
            handleSubmit();
          } else {
            setConfirmingDeletion(!confirmingDeletion);
          }
        }}
      />
    </>
  );

  return (
    <div className="flex flex-[4] gap-4">
      <div className="flex-[5]">
        <div className="card-container">
          <p className="bold-text self-start">
            {isAddingNew ? translate("addGroup") : translate("editGroup")}
          </p>
          <div className="divider-line" />
          {emptyGroups && (
            <p className="normal-text">
              {translate("groupListIsEmptyButYouCanCreateOne")}
            </p>
          )}
          {!!selectedGroup && renderEditGroup()}
        </div>
      </div>
      <div className="flex-[2]">
        <div className="card-container">
          <IconButton
            icon="add"
            label={translate("addGroup")}
            customStyle="mb-4 w-full"
            onClick={() => {
              setIsAddingNew(true);
              setSelectedGroup(emptyGroup);
              setConfirmingDeletion(false);
            }}
          />
          {renderGroupsList()}
        </div>
      </div>
    </div>
  );
};

export default Groups;
