const timeUntilSaveText = 1500;
const timeUntilSaveSelect = 50;

const ROUTES = {
  settings: {
    scheduleHowToUse: "/installningar/scheman/hur-anvander-jag",
    schedulePreSettings: "/installningar/scheman/forinstallningar",
    statisticsHowToUse: "/installningar/statistik/hur-anvander-jag",
    statisticsHandlingYourData: "/installningar/statistik/hantering-av-er-data",
    usersHowToUse: "/installningar/anvandare/hur-anvander-jag",
    usersGroups: "/installningar/anvandare/grupper",
    usersEventType: "/installningar/anvandare/insatstyp",
    myAccountSettings: "/installningar/mitt-konto/mina-installningar",
    ourCompanyAdminAccounts: "/installningar/ert-foretag/admin-konton",
    ourCompanyPrenumertaion: "/installningar/ert-foretag/er-prenumeration",
    ourCompanyCostumization:
      "/installningar/ert-foretag/styling-och-foretagsuppgifter",
  },
};

Object.freeze(ROUTES);
export { timeUntilSaveSelect, timeUntilSaveText, ROUTES };
