/* eslint-disable react-hooks/exhaustive-deps */
import "./Popup.scss";

type PopupProps = {
  children: React.ReactNode;
  showPopup: boolean;
  onClose: () => void;
  overflow?: string;
};

const Popup = (props: PopupProps) => {
  return props.showPopup ? (
    <div className="popup-wrapper">
      <div className="popup" style={{ overflow: props.overflow }}>
        <i className="fas fa-times-circle popup-icon" onClick={props.onClose} />
        {props.children}
      </div>
    </div>
  ) : null;
};

export default Popup;
