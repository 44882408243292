import { createContext, useEffect, useState } from "react";
import { io, Socket } from "socket.io-client";
import { uniqBy } from "lodash";

type SocketContextProps = {
  initialize: () => void;
  start: ({ data }: any) => void;
  cancel: (requestId: string) => void;

  deleteSavedEvent: (eventRequestId: string) => void;
  isConnected: () => boolean;
  failedEvents: any[];
  cancelledEvents: any[];
  completedEvents: any[];
  startedEvents: any[];
  runningEvents: any[];
};

//emit funcion codes
const START = "SG:START";
const CANCEL = "SG:CANCEL";

//listener function codes
const CONNECT = "connect";
const DISCONNECT = "disconnect";
const STARTED = "SG:STARTED";
const RUNNING = "SG:RUNNING";
const DONE = "SG:DONE";
const CANCELLED = "SG:CANCELLED";
const ERROR = "SG:ERROR";
const AUTH_SUCCESS = "AUTH_SUCCESS";
const AUTH_FAIL = "AUTH_FAIL";

export const SocketContext = createContext<SocketContextProps>(null!);

const socketUrl =
  process.env.REACT_APP_API_URL === "https://api.app-staging.stalmarck.com/" ||
  process.env.REACT_APP_API_URL === "https://api.app-staging.stalmarck.com"
    ? "https://middleman.app-staging.stalmarck.com"
    : "https://middleman.app.stalmarck.com";

console.log("socketUrl: ", socketUrl);
console.log("process.env: ", process.env);
console.log(" process.env.NODE_ENV: ", process.env.NODE_ENV);

const SocketProvider = ({ children }: any): JSX.Element => {
  const [socket] = useState<Socket>(
    () => io(socketUrl, { autoConnect: false, transports: ["websocket"] }) //Find a way to ignore CORS errors to remove transports: ["websocket"
  );

  const [runningEvents, setRunningEvents] = useState<any[]>([]);
  const [startedEvents, setStartedEvents] = useState<any[]>([]);
  const [completedEvents, setCompletedEvents] = useState<any[]>([]);
  const [cancelledEvents, setCancelledEvents] = useState<any[]>([]);
  const [failedEvents, setFailedEvents] = useState<any[]>([]);

  const initialize = () => {
    const accessToken = localStorage.getItem("sro-token");
    socket.auth = { accessToken };
    socket.connect();
  };
  const start = (data: any) => {
    socket.emit(START, data);
  };
  const cancel = (requestId: string) => {
    socket.emit(CANCEL, requestId);
  };
  const isConnected = () => {
    return socket.connected;
  };

  const deleteSavedEvent = (eventRequestId: string): void => {
    const possiblySavedCompletedEvents = window.localStorage.getItem(
      "completedScheduleGeneratorEvents"
    );
    if (possiblySavedCompletedEvents) {
      const savedCompletedEvents = JSON.parse(possiblySavedCompletedEvents);
      if (Array.isArray(savedCompletedEvents)) {
        console.log("savedCompletedEvents: ", savedCompletedEvents);
        const eventsWithDeletedOne = savedCompletedEvents.filter(
          (event) => event.requestID !== eventRequestId
        );
        window.localStorage.setItem(
          "completedScheduleGeneratorEvents",
          JSON.stringify(eventsWithDeletedOne)
        );
        setCompletedEvents(eventsWithDeletedOne);
      }
    }
  };

  useEffect(() => {
    socket.onAny((event, ...args) => {
      console.log("socket event: ", event);
      console.log("socket args: ", args);
    });

    const possiblySavedCompletedEvents = window.localStorage.getItem(
      "completedScheduleGeneratorEvents"
    );
    if (possiblySavedCompletedEvents) {
      const savedCompletedEvents = JSON.parse(possiblySavedCompletedEvents);
      if (Array.isArray(savedCompletedEvents)) {
        setCompletedEvents(savedCompletedEvents);
      }
    }

    socket.on("connect_error", (err) => {
      console.error(err);
    });

    socket.on(CONNECT, () => {});

    socket.on(DISCONNECT, () => {});

    socket.on(STARTED, (data) => {
      let allData = [];
      if (Array.isArray(data)) {
        allData = [...startedEvents, ...data];
      } else {
        allData = [...startedEvents, data];
      }
      const formattedData = uniqBy(allData, (dt) => dt.requestID);
      setStartedEvents(formattedData);
    });

    socket.on(RUNNING, (data) => {
      console.log("data: ", data);
      // let rEvents = [];

      // const possiblyRunningScheduleGenEvents = window.localStorage.getItem(
      //   "runningScheduleGeneratorEvents"
      // );
      // if (possiblyRunningScheduleGenEvents) {
      //   const runningScheduleGenEvents = JSON.parse(
      //     possiblyRunningScheduleGenEvents
      //   );
      //   if (Array.isArray(runningScheduleGenEvents)) {
      //   }
      // }

      let allData = [];
      if ((Array.isArray(data) && !data.length) || !data) {
        setRunningEvents([]);
        return;
      }
      if (Array.isArray(data)) {
        allData = [...runningEvents, ...data];
      } else {
        allData = [...runningEvents, data];
      }
      const formattedData = uniqBy(allData, (dt) => dt.requestID);
      setRunningEvents(formattedData);
    });

    socket.on(DONE, (data) => {
      let cEvents = [];

      const possiblySavedCompletedEvents = window.localStorage.getItem(
        "completedScheduleGeneratorEvents"
      );
      if (possiblySavedCompletedEvents) {
        const savedCompletedEvents = JSON.parse(possiblySavedCompletedEvents);
        if (Array.isArray(savedCompletedEvents)) {
          cEvents = savedCompletedEvents;
        }
      }

      let allData = [];
      if (Array.isArray(data)) {
        allData = [...cEvents, ...data];
      } else {
        allData = [...cEvents, data];
      }
      window.localStorage.setItem(
        "completedScheduleGeneratorEvents",
        JSON.stringify(allData)
      );
      setCompletedEvents(allData);
    });

    socket.on(CANCELLED, (data) => {
      let allData = [];
      if (Array.isArray(data)) {
        allData = [...cancelledEvents, ...data];
      } else {
        allData = [...cancelledEvents, data];
      }
      const formattedData = uniqBy(allData, (dt) => dt.requestID);
      setCancelledEvents(formattedData);
    });

    socket.on(ERROR, (data) => {
      let allData = [];
      if (Array.isArray(data)) {
        allData = [...failedEvents, ...data];
      } else {
        allData = [...failedEvents, data];
      }
      const formattedData = uniqBy(allData, (dt) => dt.requestID);
      setFailedEvents(formattedData);
    });

    // socket.on(AUTH_SUCCESS, (data) => {
    // });

    // socket.on(AUTH_FAIL, (data) => {
    // });

    return () => {
      socket.removeAllListeners(CONNECT);
      socket.removeAllListeners(STARTED);
      socket.removeAllListeners(RUNNING);
      socket.removeAllListeners(DONE);
      socket.removeAllListeners(CANCELLED);
      socket.removeAllListeners(ERROR);
      socket.removeAllListeners(AUTH_SUCCESS);
      socket.removeAllListeners(AUTH_FAIL);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SocketContext.Provider
      value={{
        initialize,
        start,
        cancel,
        isConnected,
        cancelledEvents,
        failedEvents,
        completedEvents,
        runningEvents,
        startedEvents,
        deleteSavedEvent,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export default SocketProvider;
