import { FaEdit } from "react-icons/fa";
import Checkbox from "../../../components/UI/Checkbox";
import { useTranslation } from "react-i18next";
const renderAvailablilityCell = ({
  cellProps,
  onCheck,
  onEdit,
}: {
  cellProps: any;
  onCheck: (rowValues: any) => void;
  onEdit: (rowValues: any) => void;
}): JSX.Element => {
  const row = cellProps.cell.row.original;
  return (
    <div className="h-[100%] w-[100%] items-center justify-center flex flex-row gap-4 rounded-xl">
      <Checkbox checked={cellProps.value} onCheck={() => onCheck(row)} />
      <FaEdit onClick={() => onEdit(row)} className="hover:cursor-pointer" />
    </div>
  );
};

const useEventColumns = ({
  onAvailabilityCheck,
  onAvailabilityEdit,
}: {
  onAvailabilityCheck: (row: any) => void;
  onAvailabilityEdit: (row: any) => void;
}): any => {
  const { t } = useTranslation();
  const translate = (key: string) => t(`translations:schedulePage.${key}`);
  return {
    available: {
      Header: translate("available"),
      accessor: "available",
      Cell: (props: any) =>
        renderAvailablilityCell({
          cellProps: props,
          onCheck: onAvailabilityCheck,
          onEdit: onAvailabilityEdit,
        }),
    },
    name: {
      Header: translate("name"),
      accessor: "name",
    },
    duration: {
      Header: translate("duration"),
      accessor: "duration",
    },
    description: {
      Header: translate("description"),
      accessor: "description",
    },
    lock: {
      Header: translate("lock"),
      accessor: "lock",
      Cell: (props: any) => (props.value ? translate("Yes") : translate("No")),
    },
    staffNeeded: {
      Header: translate("numberOfStaff"),
      accessor: "staffNeeded",
    },
    staff: {
      Header: translate("specificStaff"),
      accessor: "staff",
    },
    start: {
      Header: translate("start"),
      accessor: "start",
    },
    end: {
      Header: translate("end"),
      accessor: "end",
    },
  };
};
export default useEventColumns;
