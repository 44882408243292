import { SnackbarProvider } from "notistack";
import { useSelector } from "react-redux";
import Toolbar from "../../components/Toolbar/Toolbar";
import { ReduxState } from "../../shared/Types";
import "./Layout.scss";

type LayoutProps = {};

const Layout: React.FC<LayoutProps> = (props) => {
  const state = useSelector((state: ReduxState) => state);
  return (
    <SnackbarProvider maxSnack={3}>
    <div className="app-wrapper">
      <Toolbar isAdmin={state.auth.role === "ADMIN"} />
      <div className="content">{props.children}</div>
    </div>
    </SnackbarProvider>
  );
};

export default Layout;
