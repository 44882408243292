/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { ReduxState } from "../../../../../shared/Types";
import { IScheduledEvent } from "../../../../../types/event";
import { ISpecificTimeSpan } from "../../../../../types/time";
import Button from "../../../../UI/Button/Button";
import Input from "../../../../UI/Input/Input";
import Textarea from "../../../../UI/Textarea/Textarea";
import "./EditScheduledEvent.scss";
import { useSnackbar } from "notistack";
import { updateScheduledEvent } from "../../../../../api/schedule";
import { DateTime } from "luxon";

type EditScheduledEventProps = {
  onSaved: () => void;
  scheduledEvent: IScheduledEvent;
  organizationId: string;
};

const EditScheduledEvent = (props: EditScheduledEventProps) => {
  const [date, setDate] = useState(DateTime.now());
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [description, setDescription] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    loadEditEvent(props.scheduledEvent!);
  }, []);

  const loadEditEvent = (se: IScheduledEvent) => {
    setDate(DateTime.fromISO(se.timeSpan.start));
    setStart(
      DateTime.fromISO(se.timeSpan.start).toLocaleString({
        hour: "2-digit",
        minute: "2-digit",
      })
    );
    setEnd(
      DateTime.fromISO(se.timeSpan.end).toLocaleString({
        hour: "2-digit",
        minute: "2-digit",
      })
    );
    setDescription(se.description);
  };

  const savedHandler = () => {
    const timeSpan: ISpecificTimeSpan = {
      start: date
        .set({
          hour: parseInt(start.slice(0, 2)),
          minute: parseInt(start.slice(3, 5)),
        })
        .toString(),
      end: date
        .set({
          hour: parseInt(end.slice(0, 2)),
          minute: parseInt(end.slice(3, 5)),
        })
        .toString(),
    };
    const data = {
      description: description,
      timeSpan: timeSpan,
    };
    updateScheduledEvent(props.scheduledEvent.id, data).then(props.onSaved);
    enqueueSnackbar(`Uppdaterade tjänsten ${props.scheduledEvent.name}`, {
      variant: "success",
    });
  };

  return (
    <form
      className="edit-se"
      onSubmit={(e) => {
        e.preventDefault();
        savedHandler();
      }}
    >
      <div className="edit-se--time">
        <Input
          label="Börjar tidigast"
          value={start}
          onChange={(e) => setStart(e.target.value)}
          type="time"
          textCentered
          width={"100%"}
        />
        <Input
          label="Slutar senast"
          value={end}
          onChange={(e) => {
            setEnd(e.target.value);
          }}
          type="time"
          textCentered
          width={"100%"}
        />
      </div>
      <Textarea
        label="Beskrivning"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      <Button label="Spara" color="green" />
    </form>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  organizationId: state.auth.organizationId,
});

export default connect(mapStateToProps)(EditScheduledEvent);
