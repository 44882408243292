import "./Switch.scss";

type SwitchProps = {
  label?: string;
  value: boolean;
  onClick: () => void;
  white?: boolean;
};

const Switch = (props: SwitchProps) => {
  const switchDotClasses = ["switch--dot"];
  const switchWrapperClasses = ["switch"];
  if (props.value) {
    switchDotClasses.push("switched");
    switchWrapperClasses.push("switched");
  }
  if (props.white) {
    switchWrapperClasses.push("white");
  }

  return (
    <div className="switch-wrapper">
      <div className="switch-label">{props.label}</div>
      <div className={switchWrapperClasses.join(" ")} onClick={props.onClick}>
        <div className={switchDotClasses.join(" ")} />
      </div>
    </div>
  );
};

export default Switch;
