import { useTranslation } from "react-i18next";

const renderNameWithGroupColor = (
  name: string,
  groupColor?: string
): JSX.Element => {
  return (
    <div className="flex flex-row gap-3 items-center">
      <div
        className={`w-2 h-2 rounded-[4px]`}
        style={{ backgroundColor: groupColor ? groupColor : "white" }}
      />
      <>{name}</>
    </div>
  );
};

const useTableColumns = () => {
  const { t } = useTranslation();
  const translate = (key: string) => t(`translations:statisticsPage.${key}`);

  const staffColumns =
    // need translation help here, how do I reach the useTranslation
    [
      {
        Header: translate("name"),
        accessor: "firstName",
        Cell: (props: any) => {
          const name = props.value;
          const { groupColor } = props.cell.row.original;
          return renderNameWithGroupColor(name, groupColor);
        },
      },
      {
        Header: translate("numberOfEvents"),
        accessor: "nEvents",
        Cell: (props: any) => `${props.value} st`,
      },
      {
        Header: translate("efficiency"),
        accessor: "efficiency",
        Cell: (props: any) => `${Math.round(props.value * 10000) / 100}%`,
      },
    ];

  const clientColumns = [
    {
      Header: translate("name"),
      accessor: "firstName",
      Cell: (props: any) => {
        const name = props.value;
        const { groupColor } = props.cell.row.original;
        return renderNameWithGroupColor(name, groupColor);
      },
    },
    {
      Header: translate("continuity"),
      accessor: "continuity",
    },
    {
      Header: translate("numberOfEvents"),
      accessor: "nEvents",
    },
    {
      Header: translate("cancelledAtDoor"),
      accessor: "atDoor",
    },
    {
      Header: translate("cancelledInAdvanced"),
      accessor: "inAdvance",
    },
    {
      Header: translate("cancelledOtherReason"),
      accessor: "otherReason",
    },
  ];

  return { staffColumns, clientColumns };
};

export default useTableColumns;
