import { useTranslatedDates } from "../../../../../shared/utility";
import "./PlannedEvent.scss";

type PlannedEventProps = {
  title: string;
  duration?: number;
  startTime: string;
  endTime: string;
  days: string[];
  id: string;
  specificStaff?: string;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
  isPrioritized?: boolean;
};

const PlannedEvent = (props: PlannedEventProps) => {
  const { days } = useTranslatedDates();

  return (
    <div className="planned-event">
      <div className="planned-event--overlay">
        <i className="fas fa-pen" onClick={() => props.onEdit(props.id)} />
        <i className="fas fa-trash" onClick={() => props.onDelete(props.id)} />
      </div>
      <p className="planned-event--title">
        {props.title} {props.isPrioritized && <i className="far fa-lock" />}
      </p>
      <p className="planned-event--info">{`Dagar: ${props.days
        .map((day) => days[days.findIndex((d) => d.value === day)]?.label)
        .join(", ")}`}</p>
      {props.duration && (
        <p className="planned-event--info">{`Tid: ${props.duration} min`}</p>
      )}
      <p className="planned-event--info">{`Intervall: ${props.startTime.slice(
        0,
        5
      )}-${props.endTime.slice(0, 5)}`}</p>
      {props.specificStaff && (
        <p className="planned-event--info">{`Personal: ${props.specificStaff}`}</p>
      )}
    </div>
  );
};

export default PlannedEvent;
