import { useTranslation } from "react-i18next";
import Dropdown from "../../UI/Dropdown/Dropdown";
import DropdownCreate from "../../UI/DropdownCreate/DropdownCreate";
import DropdownMultiple from "../../UI/DropdownMultiple/DropdownMultiple";
import { IGroup } from "../../../types/user";
import Input from "../../UI/Input/Input";
import "./Location.scss";

type LocationProps = {
  address: string;
  onAddressChange: (address: string) => void;
  postalCode: string;
  onPostalCodeChange: (postalCode: string) => void;
  isStaff?: boolean;
  transportMethod?: string;
  onTransportMethodChange?: (transportMethod: string) => void;
  area?: IGroup | null;
  onAreaChange?: (area: IGroup) => void;
  onAreaAdded?: (areaName: string) => void;
  areas?: IGroup[] | null;
  staffGroups?: IGroup[] | null;
  onStaffGroupsChange?: (staffGroups: IGroup[]) => void;
};

const RenderClientInputs = (props: LocationProps): JSX.Element => {
  const { t } = useTranslation();
  const translate = (key: string) => t(`translations:userPage.${key}`);
  return (
    <>
      <Input
        label={translate("address")}
        type="text"
        value={props.address}
        onChange={(e) => props.onAddressChange(e.target.value)}
      />
      <Input
        label={translate("postalCode")}
        type="numeric"
        value={props.postalCode}
        onChange={(e) => props.onPostalCodeChange(e.target.value)}
      />
      {props.areas && props.onAreaChange && (
        <DropdownCreate
          options={props.areas.map(({ name, id }) => ({
            label: name,
            value: id,
          }))}
          title={translate("chose_county_or_groups")}
          onSelect={(v: string) => {
            const filteredAreas = props.areas!.filter((area) => area.id === v);
            props.onAreaChange!(filteredAreas[0]);
          }}
          value={props.area?.id || ""}
          isSearchable
          showTitle
        />
      )}
    </>
  );
};

const RenderStaffInputs = (props: LocationProps): JSX.Element => {
  const { t } = useTranslation();
  const translate = (key: string) => t(`translations:userPage.${key}`);
  const {
    onTransportMethodChange,
    onStaffGroupsChange,
    areas,
    staffGroups,
    transportMethod,
  } = props;
  return (
    <div>
      <Dropdown
        options={[
          { value: "WALKING", label: translate("Walk") },
          { value: "BICYCLE", label: translate("Bike") },
          { value: "CAR", label: translate("Car") },
        ]}
        title={translate("travelWay")}
        onSelect={(v) => onTransportMethodChange!(v)}
        value={transportMethod!}
        showTitle
      />
      {areas && areas.length && (
        <DropdownMultiple
          title={translate("county_or_group")}
          values={staffGroups || []}
          options={areas!.map((area) => ({
            label: area.name,
            value: area,
          }))}
          getDisplayName={(area: IGroup) => {
            return area.name;
          }}
          onRemove={(area: IGroup) => {
            const newArray = staffGroups!.filter(({ id }) => id !== area.id);
            onStaffGroupsChange!(newArray);
          }}
          onSelect={(a: IGroup) => {
            const newArray = [...staffGroups!];
            const isNotInArray =
              newArray.filter(({ id }) => id === a.id).length === 0;
            if (isNotInArray) {
              newArray.push(a);
            }
            onStaffGroupsChange!(newArray);
          }}
        />
      )}
    </div>
  );
};
const Location = (props: LocationProps): JSX.Element => {
  const { t } = useTranslation();
  const translate = (key: string) => t(`translations:userPage.${key}`);
  return (
    <div className="location">
      <div className="location--title">
        {props.isStaff ? translate("travelWayAndGeographicPlace") : translate("livingplace")}
      </div>
      <div className="location--inputs">
        {!props.isStaff && RenderClientInputs(props)}
        {props.isStaff && RenderStaffInputs(props)}
      </div>
    </div>
  );
};

export default Location;
