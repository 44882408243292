import NameSex from "../../components/Settings/NameSex/NameSex";
import Location from "../../components/Settings/Location/Location";
import {
  IGroup,
  IUser,
  IUserAllergen,
  IUserDetailsKeys,
  IUserKeys,
  IUserLanguage,
  IUserLocationKeys,
  IUserRestrictionsKeys,
} from "../../types/user";
import "./ClientSettings.scss";
import DropdownMultiple from "../../components/UI/DropdownMultiple/DropdownMultiple";
import { useCallback, useState } from "react";
import Button from "../../components/UI/Button/Button";
import axios from "../../axios";
import { useEffect } from "react";
import ContactInfo from "../../components/Settings/ContactInfo/ContactInfo";
import PlannedEventSettings from "../../components/Settings/Client/PlannedEventSettings/PlannedEventSettings";
import { IOrganizationUser } from "../../types/organization";
import {
  createHealthCenter,
  getGroups,
  getHealthCenters,
} from "../../api/organization";
import { useTranslation } from "react-i18next";

import { OptionsObject, SnackbarMessage, useSnackbar } from "notistack";
import {
  timeUntilSaveText,
  timeUntilSaveSelect,
} from "../../constants/settings";

type ClientSettingsProps = {
  client: IUser;
  setClient: React.Dispatch<React.SetStateAction<IUser | null>>;
  staffs: IOrganizationUser[];
  allergies: IUserAllergen[];
  languages: IUserLanguage[];
  onDelete: () => void;
  onSave: () => void;
  organizationId: string;
  clientGroup: IGroup[] | null;
};

const ClientSettings = (props: ClientSettingsProps) => {
 
  const [client, setClient] = useState(props.client);
  const [isDeleting, setisDeleting] = useState(false);
  const [hasConfirmedDelete, setHasConfirmedDelete] = useState(false);
  const [healthCenters, setHealthCenters] = useState<
    { value: string; label: string }[]
  >([]);
  const [prioritizedPreferredStaffList, setPrioritizedPreferredStaffList] =
    useState<IOrganizationUser[]>([]);

  const [areas, setAreas] = useState<IGroup[]>([]);
  const [clientGroup, setClientGroup] = useState(
    props.clientGroup && props.clientGroup[0] ? props.clientGroup[0] : null
  );

  useEffect(() => {
    setClient(props.client);
    const groupVal =
      props.clientGroup && props.clientGroup[0] ? props.clientGroup[0] : null;

    setClientGroup(groupVal);
    Promise.all([
      getHealthCenters(props.organizationId),
      getGroups(props.organizationId),
    ]).then(([hcRes, groupRes]) => {
      setHealthCenters(hcRes.data.map((h) => ({ value: h.id, label: h.name })));
      setAreas(
        groupRes.data.map(({ id, name, color }) => ({ id, name, color }))
      );
    });
  }, [props.client, props.organizationId, props.clientGroup]);

  const onClientRestrictionsChanged = (
    key: IUserRestrictionsKeys,
    value: any[]
  ) => {
    if (!client.restrictions) return;
    setClient({
      ...client,
      restrictions: { ...client.restrictions, [key]: value },
    });
  };

  const { enqueueSnackbar } = useSnackbar();

  const onDataSaved = useCallback(
    (message: SnackbarMessage, options: OptionsObject) => {
      enqueueSnackbar(message, options);
      props.onSave();
      props.setClient(client);
    },
    [enqueueSnackbar, props, client]
  );

  const onClientDetailsChanged = (key: IUserDetailsKeys, value: any) => {
    if (!client.details) return;
    setClient({
      ...client,
      details: { ...client.details, [key]: value },
    });
  };

  const onClientChanged = (key: IUserKeys, value: string) => {
    setClient({ ...client, [key]: value });
  };

  const onClientLocationChanged = (key: IUserLocationKeys, value: string) => {
    if (!client.details) return;
    setClient({
      ...client,
      details: {
        ...client.details,
        location: { ...client.details.location!, [key]: value },
      },
    });
  };

  useEffect(() => {
    //on name updated
    if (
      client.email === props.client.email &&
      client.firstName === props.client.firstName &&
      client.lastName === props.client.lastName
    )
      return;

    const timer = setTimeout(() => {
      axios
        .patch(`user/${props.client.id}`, {
          email: client.email,
          firstName: client.firstName,
          lastName: client.lastName,
        })
        .then(() => {
          props.client.email = client.email; //update fields with new data to avoid need to fetch
          props.client.firstName = client.firstName; //if we don't do this we need to fetch data
          props.client.lastName = client.lastName; //or we won't update if the original values are set again
          onDataSaved(translate("nameInfoUpdated"), {
            variant: "success",
          });
        })
        .catch((exception) => {
          let errors = exception.response?.data?.errors;
          if (
            errors !== undefined &&
            errors.length > 0 &&
            errors[0].code === "SIMPLE-VALIDATION-ERROR" &&
            errors[0].parameter === "email"
          ) {
            onDataSaved(translate("unvalidEmail"), {
              variant: "error",
            });
          } else {
            onDataSaved(translate("unknownErrorOccured"), {
              variant: "error",
            });
          }
        });
    }, timeUntilSaveText);
    return () => {
      clearTimeout(timer);
    };
  }, [
    client.email,
    client.firstName,
    client.lastName,
    onDataSaved,
    props.client,
    props.client.id,
  ]);

  useEffect(() => {
    //on details updated
    if (props.client.details === client.details) return;
    const timer = setTimeout(() => {
      axios
        .patch(`user/${props.client.id}/details`, {
          ...client.details,
          healthCenter:
            typeof client.details?.healthCenter === "string"
              ? client.details.healthCenter
              : client.details?.healthCenter?.id,
          languages: client.details?.languages.map((lang) => lang.id),
          allergens: client.details?.allergens.map((aller) => aller.id),
        })
        .then(() => {
          onDataSaved(translate("infoUpdated"), { variant: "success" });
        })
        .catch(() => {
          onDataSaved(translate("unknownErrorOccured"), {
            variant: "error",
          });
        });
    }, timeUntilSaveText);
    return () => {
      clearTimeout(timer);
    };
  }, [client.details, onDataSaved, props.client, props.client.id]);

  useEffect(() => {
    //on restrictions updated
    if (client.restrictions === props.client.restrictions) return;

    const timer = setTimeout(() => {
      axios
        .patch(`user/${props.client.id}/restrictions`, {
          likes: client.restrictions?.likes.map((user) => user.id),
          dislikes: client.restrictions?.dislikes.map((user) => user.id),
          allergies: client.restrictions?.allergies.map((aller) => aller.id),
        })
        .then(() => {
          onDataSaved(translate("conditionsUpdated"), { variant: "success" });
        })
        .catch(() => {
          onDataSaved(translate("unknownErrorOccured"), {
            variant: "error",
          });
        });
    }, timeUntilSaveSelect);
    return () => {
      clearTimeout(timer);
    };
  }, [
    client.restrictions,
    client.restrictions?.allergies,
    client.restrictions?.dislikes,
    client.restrictions?.likes,
    enqueueSnackbar,
    onDataSaved,
    props.allergies,
    props.client,
    props.client.id,
  ]);

  const onDelete = () => {
    if (!hasConfirmedDelete) {
      setHasConfirmedDelete(true);
      return;
    }
    setisDeleting(true);
    axios.delete(`user/${props.client.id}`).then(() => {
      setisDeleting(false);
      props.onDelete();
    });
  };
  const { t } = useTranslation();
  const translate = (key: string) => t(`translations:userPage.${key}`);
  const renderStaffPrioritiser = (): JSX.Element => (
    <>
      <div className="client-settings--title">{translate("priorities")}</div>
      <DropdownMultiple
        isDraggable
        title={translate("prioritylistOfStaff")}
        values={prioritizedPreferredStaffList}
        options={props.staffs.map((organizationUser) => {
          const {
            user: { firstName },
          } = organizationUser;
          return {
            label: firstName,
            value: organizationUser,
          };
        })}
        getId={({ user: { id } }) => id}
        getDisplayName={({ user: { firstName } }) => {
          return firstName;
        }}
        onRemove={(organizationUser: IOrganizationUser) => {
          const newArray = prioritizedPreferredStaffList!.filter(
            ({ user: { id } }) => id !== organizationUser.user.id
          );
          setPrioritizedPreferredStaffList(newArray);
        }}
        onSelect={(organizationUser: IOrganizationUser) => {
          const newArray = [...prioritizedPreferredStaffList];
          const isNotInArray =
            newArray.filter(
              ({ user: { id } }) => id === organizationUser.user.id
            ).length === 0;
          if (isNotInArray) {
            newArray.push(organizationUser);
          }
          setPrioritizedPreferredStaffList(newArray);
        }}
        onDragOver={(
          startElement: IOrganizationUser,
          endElement: IOrganizationUser
        ) => {
          const startElementIndex = prioritizedPreferredStaffList.findIndex(
            ({ user: { id } }) => id === startElement.user.id
          );

          const endElementIndex = prioritizedPreferredStaffList.findIndex(
            ({ user: { id } }) => id === endElement.user.id
          );
          const newArray = [...prioritizedPreferredStaffList];
          newArray.splice(
            endElementIndex,
            0,
            newArray.splice(startElementIndex, 1)[0]
          );
          //TODO: do the backend call here
          setPrioritizedPreferredStaffList(newArray);
        }}
      />
    </>
  );
  return (
    <div className="client-settings">
      <div className="client-settings--name">
        <div
          className="image"
          style={(function () {
            if (props.clientGroup && props.clientGroup[0]) {
              return {
                background: props.clientGroup[0].color,
              };
            } else {
              return {
                background: "black",
              };
            }
          })()}
        >
          <i className="fas fa-user" />
        </div>
        <div className="name">{`${client.firstName} ${client.lastName}`}</div>
      </div>
      {client.restrictions && client.details && (
        <>
          <NameSex
            firstName={client.firstName}
            onFirstNameChange={(value) => onClientChanged("firstName", value)}
            lastName={client.lastName}
            onLastNameChange={(value) => onClientChanged("lastName", value)}
            gender={client.details.gender!}
            onGenderChange={(gender) =>
              onClientDetailsChanged("gender", gender)
            }
            healthCenter={
              typeof client.details.healthCenter === "string"
                ? client.details.healthCenter
                : client.details.healthCenter?.id
            }
            onHealthCenterChanged={(healthCenter) =>
              onClientDetailsChanged("healthCenter", healthCenter)
            }
            onHealthCenterAdded={(healthCenterName) =>
              createHealthCenter(props.organizationId, healthCenterName).then(
                (pRes) =>
                  getHealthCenters(props.organizationId).then((gRes) => {
                    setHealthCenters(
                      gRes.data.map((h) => ({ value: h.id, label: h.name }))
                    );
                    onClientDetailsChanged("healthCenter", pRes.data.id);
                  })
              )
            }
            healthCenters={healthCenters}
          />
          <ContactInfo
            email={client.email || ""}
            phoneNumber={client.details.phoneNumber!}
            onEmailChange={(email) => onClientChanged("email", email)}
            onPhoneNumberChange={(phoneNumber) =>
              onClientDetailsChanged("phoneNumber", phoneNumber)
            }
          />
          <Location
            address={client.details.location?.address || ""}
            onAddressChange={(value) =>
              onClientLocationChanged("address", value)
            }
            postalCode={client.details.location?.postalCode || ""}
            onPostalCodeChange={(value) =>
              onClientLocationChanged("postalCode", value)
            }
            area={clientGroup}
            onAreaChange={(value) => {
              axios
                .patch(
                  `organization/${props.organizationId}/user/${props.client.id}`,
                  {
                    group: [value.id],
                  }
                )
                .then(() => {
                  onDataSaved(translate("groupUpdated"), { variant: "success" });
                  setClientGroup(value);
                })
                .catch(() => {
                  onDataSaved(translate("unexpectedError"), {
                    variant: "error",
                  });
                });
            }}
            //TODO: area creation will happen somewhere else
            // onAreaAdded={(areaName) => {
            //   createGroup(props.organizatonId, areaName).then((pRes) =>i
            //     getGroups(props.organizationId).then((gRes) => {
            //       setAreas(
            //         gRes.data.map(({ id, name, color }) => ({
            //           id,
            //           name,
            //           color,
            //         }))
            //       );
            //       setClientGroup(pRes.data[0]);
            //     })
            //   );
            // }}
            areas={areas}
          />
          {props.staffs && !!props.staffs.length && renderStaffPrioritiser()}

          <PlannedEventSettings userId={props.client.id} />
          <div className="client-settings--title">{translate("generalConditions")}</div>
          {false && (
            <DropdownMultiple
              title={translate("likes")}
              values={client.restrictions!.likes}
              options={props.staffs.map((staff) => ({
                label: `${staff.user.firstName} ${staff.user.lastName}`,
                value: staff.user,
              }))}
              getDisplayName={(staff: IUser) =>
                `${staff.firstName} ${staff.lastName}`
              }
              onRemove={(value: IUser) => {
                if (client.restrictions) {
                  const newArray = client.restrictions.likes.filter(
                    (staff) => staff.id !== value.id
                  );
                  onClientRestrictionsChanged("likes", newArray);
                }
              }}
              onSelect={(value: IUser) => {
                const newArray = [...(client.restrictions?.likes ?? [])];
                const isNotInArray =
                  newArray.filter((user) => user.id === value.id).length === 0;
                if (isNotInArray) {
                  newArray.push(value);
                }
                onClientRestrictionsChanged("likes", newArray);
              }}
            />
          )}
          <DropdownMultiple
            title={translate("dislikes")}
            values={client.restrictions.dislikes}
            options={props.staffs.map((staff) => ({
              label: `${staff.user.firstName} ${staff.user.lastName}`,
              value: staff.user,
            }))}
            getDisplayName={(staff: IUser) =>
              `${staff.firstName} ${staff.lastName}`
            }
            onRemove={(value: IUser) => {
              if (client.restrictions) {
                const newArray = client.restrictions.dislikes.filter(
                  (staff) => staff.id !== value.id
                );
                onClientRestrictionsChanged("dislikes", newArray);
              }
            }}
            onSelect={(value: IUser) => {
              const newArray = [...(client.restrictions?.dislikes ?? [])];
              const isNotInArray =
                newArray.filter((user) => user.id === value.id).length === 0;
              if (isNotInArray) {
                newArray.push(value);
              }
              onClientRestrictionsChanged("dislikes", newArray);
            }}
          />
          <DropdownMultiple
            title={translate("allergens")}
            values={client.details.allergens}
            options={props.allergies.map((allergy) => ({
              label: allergy.name,
              value: allergy,
            }))}
            getDisplayName={(allergy: IUserAllergen) => allergy.name}
            onRemove={(value: IUserAllergen) => {
              if (client.details) {
                const newArray = client.details.allergens.filter(
                  (allergen) => allergen.id !== value.id
                );
                onClientDetailsChanged("allergens", newArray);
              }
            }}
            onSelect={(value: IUserAllergen) => {
              const newArray = [...(client.details?.allergens ?? [])];
              const isNotInArray =
                newArray.filter((allergen) => allergen.id === value.id)
                  .length === 0;
              if (isNotInArray) {
                newArray.push(value);
              }
              onClientDetailsChanged("allergens", newArray);
            }}
          />
          <DropdownMultiple
            title={translate("languages")}
            values={client.details.languages}
            options={props.languages.map((language) => ({
              label: language.name,
              value: language,
            }))}
            getDisplayName={(language: IUserLanguage) => language.name}
            onRemove={(value: IUserLanguage) => {
              if (client.details) {
                const newArray = client.details.languages.filter(
                  (language) => language.id !== value.id
                );
                onClientDetailsChanged("languages", newArray);
              }
            }}
            onSelect={(value: IUserLanguage) => {
              const newArray = [...(client.details?.languages ?? [])];
              const isNotInArray =
                newArray.filter((lang) => lang.id === value.id).length === 0;
              if (isNotInArray) {
                newArray.push(value);
              }
              onClientDetailsChanged("languages", newArray);
            }}
          />
        </>
      )}
      <div className="client-settings--buttons">
        <Button
          label={hasConfirmedDelete ? translate("areYouSure") : translate("deleteClient")}
          color={hasConfirmedDelete ? "red" : "grey"}
          onClick={onDelete}
          isLoading={isDeleting}
          onMouseLeave={() => setHasConfirmedDelete(false)}
        />
      </div>
    </div>
  );
};

export default ClientSettings;
