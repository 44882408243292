export default {
  translations: {
    generalWords: {
      monday: "Måndag",
      tuesday: "Tisdag",
      wednesday: "Onsdag",
      thursday: "Torsdag",
      friday: "Fredag",
      saturday: "Lördag",
      sunday: "Söndag",
      january: "Januari",
      february: "Februari",
      mars: "Mars",
      april: "April",
      may: "Maj",
      june: "Juni",
      july: "Juli",
      august: "Augusti",
      september: "September",
      october: "Oktober",
      november: "November",
      december: "December",
      schedule: "Schema",
      statistic: "Statistik",
      users: "Användare",
      settings: "Inställningar",
    },
    schedulePage: {
      title: "Schema",
      navigationCreateSchedule: "Skapa schema",
      navigationIndividualSchedule: "Individuelt schema",
      navigaionEditSchedule: "Redigera schema",
      navigationLive: "Aktuellt",
      navigationStaging: "Utkast",
      createScheduleButton: "Skapa schema",
      navigationStaff: "Personal",
      navigationClient: "Kunder",
      Week: "Vecka",
      fastAdjustment: "Snabbredigering",
      navigationEvent: "Insatser",
      Staff: "Personal",
      publishDraft: "Publicera utkast",
      missedEvents: "Uteblivna insatser",
      showMore: "Visa mer",
      showLess: "Visa mindre",
      emptyStateText: "Det finns ingen tillgänglig data",
      staff: "personal",
      client: "kund",
      available : "Tillgänglig",
      group: "Grupp",
      groups : "Grupper",
      email :  "E-post",
      name : "Namn",
      startWeek  : "Startvecka",
      repetition: "Repetition",
      start : "Start",
      end : "Slut",
      duration : "Tidsåtgång",
      dualStaffing : "Dubbelbemanning",
      connectedTo :  "Kopplad med",
      hasWorkshift: "Har arbetspass",
      phoneNumber : "Telefonnummer",
      language : "Språk",
      transportMethods   :  "Transportmetod",
      employement : "Anställning",
      allergies : "Allergier",
      allergens : "Allergener",
      address :  "Adress",
      postalCode : "Postkod",
      healthCenter : "Vårdcentral",
      priorityList :  "Prioritetslista",
      dislikes : "Ogillar",
      description : "Beskrivning",
      lock :  "Låst",
      numberOfStaff : "Antal personal",
      specificStaff :  "Specifik personal",
      Clients : "Kunder",
      couldNotFindClientAddress : "Kunde ej hitta kundens adress",
      missingName : "Saknar namn",
      priorities : "Prioriteringar",
      unexpectedError : "Ett oväntat fel inträffade",
      groupUpdated : "Grupp/Område uppdaterad",
      prioritylistOfStaff : "Prioritetslista av personal",
      nameMissing  :  "Namn saknas",
      clientAddress  : "Kundens adress",
      noNameFound :"Hittade inget namn",
      cancel : "Avbryt",
      Cancelled : "Avbryten",
      Done : "Klar",
      Failed : "Misslyckades",
      retry : "Försök igen",
      toSchedule : "Till schema",
      From : "Från",
      To : "Till",
      Started : "Startade",
      estimatedTime : "Estimerad tid",
      scheduleGenerator : "Schemagenerator",
      search : "Sök",
      Yes : "Ja",
      No : "Nej",
      Add : "Add",
      sick : "Sjuk",
      vacation : "Semester",
      reasonIsReuired : "Fyll i anledning",
      pleaseSelectAReason : "Vänligen välj en anledning",
      startTimeIsRequired : "Fyll i starttid",
      endTimeIsRequired : "Fyll i sluttid",
      reason : "Anledning",
      comment : "Kommentar",
      unavailable : "Otillgänglig",
      for : "för",
      otherReason : "Annan anledning",
      save : "Spara",
    },
    statisticsPage: {
      title: "Statistik",
      navigationOverview: "Översikt",
      navigationLive: "Aktuellt",
      navigationStaging: "Utkast",
      navigationIndividual: "Individuell",
      today: "Idag",
      monthly: "Månandsvis",
      weekly: "Veckovis",
      daily: "Dalig",
      agenda: "Agenda",
      name: "Namn",
      scheduledEventsForStaff: "Schemalagda pass",
      efficiency: "Beläggningsgrad",
      navigationStaff: "Personal",
      navigationClient: "Kunder",
      navigationContinuity: "Kontinuitet",
      scheduledEventsForClient: "Schemalagda besök",
      cancelledAtDoor: "Avbokad vid dörr",
      cancelledInAdvanced: "Avbokad i förväg",
      cancelledOtherReason: "Annan anledning",
      emptyStateText: "Det finns ingen data tillgänglig",
      numberOfEvents : "Antal insatser",
    },
    userPage: {
      title: "Användare",
      navigationStaff: "Personal",
      navigationClient: "Kunder",
      search: "Sök",
      name: "Namn",
      add: "Lägg till",
      addUser: "Lägg till användare",
      addStaff: "Lägg till personal",
      addClient: "Lägg till kund",
      addEvent: "Lägg till insats",
      personalInformation: "Personuppgifter",
      firstName: "Förnamn",
      lastName: "Efternamn",
      gender: "Kön",
      male: "Man",
      female: "Kvinna",
      other: "Annan",
      employementType: "Anställningstyp",
      travelWayAndGeographicPlace: "Färdmedel och geografisk plats",
      travelWay: "Färdmedel",
      chose_county_or_groups: "Ange områden/grupper",
      county_or_group: "Område/grupp",
      avialableWorkTimes: "Tillgängliga arbetstider",
      breaks: "Raster",
      unavailableDays: "Dagar personen inte kan jobba",
      generalConditions: "Generella villkor",
      allergies: "Allergier",
      language: "Språk",
      delete_staff: "Radera personal",
      localHospital: "Vårdcentral",
      contactInfo: "Kontaktuppgifter",
      email: "E-postaddress",
      phoneNumber: "Telefonnummer",
      password: "Lösenord",
      livingplace: "Boplats",
      address: "Adress",
      postalCode: "Postnummer",
      prioritize: "Prioritering",
      prioritizeList: "Prioriteringslista",
      prioritized: "Prioriterad",
      notPrioritized: "Inte prioriterad",
      staff: "Personal",
      acceptedTasks: "Beviljade insatser",
      dislikes: "Trivs inte med",
      allergens: "Allergener",
      deleteClient: "Radera kund",
      addPlannedEvent: "Lägg till insats",
      ifThePlannedEventOnlyOccursOnce:
        "Om insatsen bara inträffar vid ett tillfälle",
      nameOfPlannedEvent: "Namn på insatsen",
      startEarliest: "Börjar tidigast",
      stopsLatest: "Slutar senast",
      duration: "Tidsåtgång (min)",
      ifLocked: "Tryck på låset om insatsen måste inträffa på angiven tid",
      plannedEventOccuringFollowingDays: "insatsen inträffar följande dagar",
      ifEventCanBeTakenAnytimeOfTheWeek:
        "Om insatsen kan ske när som under en vecka",
      ifSpecificOrMultipleStaffNeeded:
        "Om insatsen kräver en specifik personal eller flera personal",
      description: "Beskrivning",
      save: "Spara",
      Bike: "Cykel",
      Car: "Bil",
      Walk: "Gång",
      yes: "yes",
      no: "no,",
      deleting: "raderar",
      areYouSure: "Är du säker?",
      nameIsrequired: "Namn är ett måste",
      minimumLengthNameText: "Namn får ej vara tomt",
      notEmpty: "Ej tom",
      startTimeCanNotBeEmpty: "Starttid får ej vara tom",
      everyWeek: "Varje vecka",
      everyTwoWeek: "Varannan vecka",
      everyThreeWeek: "Var tredje vecka",
      everyFourWeek: "Var fjärde vecka",
      endTimeMustBeAfterStartTime: "Sluttid måste vara efter starttid",
      durationBiggerThan1Text: "Tidsåtgång bör vara mer än 1 minut",
      thisIsRequired: "Detta måste fyllas i",
      succefullySavedPlannedEvent: "Insatsen är sparad",
      failedSavingPlannedEvent: "Misslyckades med att spara insatsen",
      dualStaff: "Dubbelbemmaning",
      anyone: "Alla",
      timeIntervalIsWrong: "Tidsintervallet är felaktigt",
      giveAtLeastOneDayOccuring: "Ange minst en dag som insatsen inträffar",
      mustGiveANameToEvent: "Du måste ange ett namn för insatnsen",
      updatedEvent: "Uppdaterade insatsen",
      createdEvent: "Skapade insatsen",
      pressIfEventOnlyOccursOnce:
        "Tryck om insatsen bara inträffar vid ett tillfälle",
      choseSingleDayEventOccurs: "Välj enskild dag då insatsen inträffar",
      howDoesEventrepeat: "Hur upprepas insatsen",
      startWeek: "Startvecka",
      eventType: "Insatstyp",
      enterEndTime: "Skriv sluttid",
      enterStartTime: "Skriv starttid",
      enterDuration: "Skriv tidsåtgång",
      doYouReallyWantToRemoveThisevent:
        "Vill du verkligen ta bort denna insats?",
      eventRemoved: "Insats borttagen",
      failedRemovingEvent: "Misslyckades med att ta bort insats",
      specificTimesOrHourBased: "Fasta tider eller timbaserat",
      canDoDualStaffing: "Kan utföra dubbelbemanningar",
      notCorrectFilledOutForm: "Alla uppgifter är inte ifyllda korrekt",
      languange: "Språk",
      somethingWentWrong : "Något gick  fel",
      min : "min",
      isNowUnavailable : "är nu otillgänglig",
      isNowAvailable : "är nu tillgänglig",
      missingName : "Saknar namn",
      nameInfoUpdated : "Namninformtion uppdaterad",
      unvalidEmail : "Ogiltig e-post",
      unknownErrorOccured : "Ett okänt fel inträffade",
      infoUpdated : "Information uppdaterad",
      conditionsUpdated : "Generella krav uppdaterad",
      Clients : "Kunder",
      Staff : "Personal",
  

    },
    settingsPage: {
      title: "Inställningar",
      schedule: "Schema",
      statistics: "Statistik",
      users: "Användare",
      myAccount: "Mitt konto",
      yourCompany: "Mitt foretag",
      howDoIUse: "Hur använder jag?",
      generatorSettings: "Generatorinställning",
      handlingOfYourData: "Hantering av min data",
      groups: "Områden/grupper",
      eventType: "Insatstyp",
      mySettings: "Mina inställningar",
      adminAccount: "Admin konton",
      yourPrenumeration: "Min prenumeration",
      customization: "Styling & företagsuppgifter",
      editGroup: "Redigera grupp",
      addGroup: "Lägg till grupp",
      name: "Namn",
      color: "Färg",
      deleteGroup: "Radera grupp",
      editGeneratorSettings: "Redigera generatorinställning",
      numberOfDays: "Antal dagar",
      addGeneratorSetting: "Lägg till generatorinställning",
      startTime: "Starttid",
      endTime: "Sluttid",
      conditionsForSchedule: "Krav på schemat",
      meetConditions: "Krav för att en personal ska kunna träffa en kund",
      dualStaffingCondition: "Krav för dubbelbemmaning",
      prioritizations: "Prioriteringar",
      algorithmPrioritization: "Algoritmprioriteringar",
      deleteGeneratorSetting: "Radera generatorinställning",
      nameIsRequired: "Namn måste fyllas i",
      minimumLengthNameText: "Namn får ej vara tomt",
      succesfullySavedGroup: "Gruppen är sparad",
      failedSavingGroup: "Misslyckades med att spara gruppen",
      succesfullyUpdatedGroup: "Gruppen är updaterad",
      failedUpdatingGroup: "Misslyckades med att updatera gruppen",
      succesfullyDeletedGroup: "Gruppen är raderad",
      failedDeletingGroup: "Misslyckades med att radera grupp",
      groupListIsEmptyButYouCanCreateOne:
        "Det finns inga grupper, men du kan skapa en",
      Yes: "Ja",
      No: "Nej",
      enterStartTime: "Ange starttid",
      enterEndTime: "Ange sluttid",
      Filter: "Filter",
      staffFilter: "Personalfilter",
      groupFilter: "Gruppfilter",
      enterGeneratorSettingName: "Ange generatorinställningens namn",
      enterNumberOfDays: "Ange antal dagar",
      continueDeletingGeneratorsetting: "Radera generatorinställning?",
      deleting: "Raderar",
      enterGroupName: "Ange gruppnamn",
      externalIDisRequired: "Externt id behövs fyllas i",
      externalIdCanNotBeEmpty: "External id får inte vara tom",
      canNotBeLessThan1Day : "Måste vara minst  1 dag",
      canNotBeMoreThan7Days : "Får ej vara fler än 7 dagar",
      nameCanNotBeEmpty : "Namn får inte vara tom",
      notEmpty : "Inte tom",
      startTimeCanNotBeEmpty  :  "Starttid får ej vara tom",
      enterTheStartTime : "Fyll i starttid",
      enterTheEndTime : "Fyll i sluttid",
      minimumRequirementsForStaffAndClientToMeet : "Minimumkrav för att personal och kund ska få träffas",
      requirementForDualStaffing : "Krav för dubbelbemanning",
      algorithmPrioritizations : "Algoritmrioriteringar",
      filter  : "Filter",
      generateSchedule : "Generera schema",
      selectADate :  "Välj en dag",
      selectAGeneratorsetting : "Välj en generatorinställning",
      thereAreNoGroupsPleaseSelectAGroup : "Ingen vald grupp, vänligen välj en grupp",
      anyone : "Alla",
      specific :  "Specifik",
      duaStaffing : "Dubbelbemanning",
      onlyFemales : "Bara kvinnor",
      onlyMales : "Bara män",
      enterEventTypeName : "Ange insatstypens namn",
      eventID : "Insats ID",
      enterDuration : "Ange tidsåtgång",
      conditions : "Krav",
      areYouSure : "Är dusäker?",
      addEventType : "Lägg till insatstyp",
      deleteEventType : "Radera insatstyp",
      thereAreNoEventTypesButYouCanCreateOne : "Det finns inga insatstyper, men du kan skapa en",
      nameMissing :"Namn saknas",
      repetition : "Upprepning",
      duration : "Tidsåtgång",
      sameGroup : "Samma grupp",
      noAllergies : "Inga allergier",
      sameLanguage : "Samma språk",
      specificStaff : "Specifik personal",
      connectedWorkShifts : "Kopplade arbetspass bara",
      availableForDualStaffing : "Kan utföra dubbelbemmaning",
      continuity : "Kontinuitet",
      efficiency : "Beläggningsgrad",
      distances : "Summa distanser",
      keepSame : "Så likt nuvarande som möjligt",
      numberOfEvents : "Antal insatser",
    },
  },
};
