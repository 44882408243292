type CheckboxProps = {
  checked: boolean;
  onCheck: () => void;
};

const Checkbox = ({ checked, onCheck }: CheckboxProps): JSX.Element => {
  return (
    <div
      onClick={onCheck}
      className={`h-4 w-4 rounded-lg hover:cursor-pointer border-[3px] ${
        checked ? "bg-[#CFFFF2] border-[#11FFBD]" : "bg-[#fff] border-[#838892]"
      } `}
    />
  );
};

export default Checkbox;
