import { useState } from "react";
import { connect } from "react-redux";
import Button from "../../components/UI/Button/Button";
import Input from "../../components/UI/Input/Input";
import { ReduxState } from "../../shared/Types";
import { checkValidity, updateObject } from "../../shared/utility";
import * as actions from "../../store/actions/index";
import "./Login.scss";

type LoginProps = {
  onLogin: (username: string, password: string) => void;
  loading: boolean;
  error: string | null;
  isAuthenticated: boolean;
};

const Login = (props: LoginProps) => {
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [controls, setControls]: any = useState({
    username: {
      value: "",
      invalid: true,
      rules: {
        required: true,
      },
    },
    password: {
      value: "",
      invalid: true,
      rules: {
        required: true,
      },
    },
  });

  const inputChangedHandler = (e: any, inputKey: string) => {
    const updatedControls = updateObject(controls, {
      [inputKey]: updateObject(controls[inputKey], {
        value: e.target.value,
        invalid: !checkValidity(e.target.value, controls[inputKey].rules),
      }),
    });
    setControls(updatedControls);
  };

  const loginHandler = (e: any) => {
    e.preventDefault();
    setHasSubmitted(true);
    if (!controls.username.invalid && !controls.password.invalid) {
      props.onLogin(controls.username.value, controls.password.value);
    }
  };

  return (
    <div className="login">
      <div className="login--content">
        <div className="inputs">
          <div className="inputs--title">Välkommen!</div>
          {props.error && (
            <div className="inputs--error">Felaktiga användaruppgifter</div>
          )}
          <form className="inputs--form" onSubmit={loginHandler}>
            <Input
              type="text"
              width="100%"
              onChange={(e) => inputChangedHandler(e, "username")}
              value={controls.username.value}
              label="Emailadress"
              errorMessage="Du måste ange din emailadress"
              invalid={controls.username.invalid}
              shouldValidate={hasSubmitted}
              white
            />
            <Input
              type="password"
              width="100%"
              onChange={(e) => inputChangedHandler(e, "password")}
              label="Lösenord"
              value={controls.password.value}
              errorMessage="Du måste ange ditt lösenord"
              invalid={controls.password.invalid}
              shouldValidate={hasSubmitted}
              white
            />
            <div className="inputs--form__buttons">
              <Button
                label="Logga in"
                color="green"
                onClick={loginHandler}
                isLoading={props.loading}
              />
            </div>
          </form>
        </div>
      </div>
      <div className="login--logo-wrapper">
        <div className="login--logo-wrapper__logo" />
      </div>
    </div>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  loading: state.auth.loading,
  error: state.auth.error,
  isAuthenticated: state.auth.token !== null,
});

const mapDispatchToProps = (dispatch: any) => ({
  onLogin: (email: string, password: string) =>
    dispatch(actions.login(email, password)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
