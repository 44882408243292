import { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
import { useFormik } from "formik";
import { object, string, number, array } from "yup";
import { useSnackbar } from "notistack";

import { getGroups } from "../../../../api/organization";
import { getAllUsers } from "../../../../api/user";
import {
  getSchedulePresettings,
  createSchedulePresetting,
  updateSchedulePresetting,
  deleteSchedulePresetting,
} from "../../../../api/schedule";
import { ReduxState } from "../../../../shared/Types";
import { compareHours } from "../../../../shared/utility";

import Switch from "../../../../components/UI/Switch/Switch";
import IconButton from "../../../../components/UI/NewIconButton";
import ItemSelect from "../../../../components/UI/ItemSelect";
import Input from "../../../../components/Form/Input";
import Select from "../../../../components/Form/Select";
import ColorPicker from "../../../../components/Form/ColorPicker";
import { IOrganizationUser } from "../../../../types/organization";
import { useTranslation } from "react-i18next";

const meetConditionOptions = [
  { label: "Same Group", value: "SAME_GROUP" },
  { label: "Same language", value: "SAME_LANGUAGE" },
  { label: "No agllergies", value: "NO_ALLERGIES" },
  { label: "Strict Specific", value: "STRICT_SPECIFIC" },
];
const meetConditionOptionsMapper = {
  SAME_GROUP: {
    label: "Same Group",
    value: "SAME_GROUP",
  },
  SAME_LANGUAGE: { label: "Same language", value: "SAME_LANGUAGE" },
  NO_ALLERGIES: { label: "No agllergies", value: "NO_ALLERGIES" },
  STRICT_SPECIFIC: { label: "Strict Specific", value: "STRICT_SPECIFIC" },
};
const dualStaffingConditionsOptions = [
  { label: "Connected work shifts", value: "CONNECTED_WORK_SHIFTS" },
  {
    label: "Available for dual staffing",
    value: "AVAILABLE_FOR_DUAL_STAFFING",
  },
  { label: "Anyone", value: "ANYONE" },
];
const algorithmPrioritizationOptions = [
  { label: "Contiunity", value: "CONTINUITY" },
  { label: "Same group", value: "SAME_GROUP" },
  { label: "Efficiency", value: "EFFICIENCY" },
  { label: "Distances", value: "DISTANCES" },
  { label: "Keep same", value: "KEEP_SAME" },
  { label: "Duration", value: "DURATION" },
  { label: "Events", value: "EVENTS" },
];
const algorithmPrioritizationOptionsMapper = {
  CONTINUITY: { label: "Contiunity", value: "CONTINUITY" },
  SAME_GROUP: { label: "Same group", value: "SAME_GROUP" },
  EFFICIENCY: { label: "Efficiency", value: "EFFICIENCY" },
  DISTANCES: { label: "Distances", value: "DISTANCES" },
  KEEP_SAME: { label: "Keep same", value: "KEEP_SAME" },
  DURATION: { label: "Duration", value: "DURATION" },
  EVENTS: { label: "Events", value: "EVENTS" },
};

const renderSectionName = (sectionName: string, customStyles?: string) => (
  <div className={customStyles}>
    <p className={`bold-text self-start`}>{sectionName}</p>
    <div className="divider-line" />
  </div>
);

const Presettings = (): JSX.Element => {
  const { t } = useTranslation();
  const translate = (key: string) => t(`translations:settingsPage.${key}`);
  const { enqueueSnackbar } = useSnackbar();

  const organizationId = useSelector(
    (state: ReduxState) => state.auth.organizationId
  );

  //presettings data
  const [loadingPresettings, setLoadingPresetings] = useState(true);
  const [presettings, setPresettings] = useState<SchedulePresetting[]>([]); //todo: use set presetting (when retrieving data from the API)
  const [selectedPresetting, setSelectedPresetting] =
    useState<SchedulePresetting | null>(null);

  //form
  const [isAddingNew, setIsAddingNew] = useState<boolean>(
    presettings.length === 0
  ); //immediatley show add new if there are no presettings
  const [colorPickerVisible, setColorPickerVisible] = useState<boolean>(false);
  // const [clientOptions, setClientOptions] = useState<any>([]);
  const [staffOptions, setStaffOptions] = useState<any>([]);
  const [groupOptions, setGroupOptions] = useState<any>([]);
  const [filterItemsVisible, setFilterItemsVisible] = useState(false);
  const [fetchPresettings, refetchPresettings] = useState(0);
  const [loadingDeleting, setLoadingDeleting] = useState(false);
  const [confirmingDeletion, setConfirmingDeletion] = useState(false);

  const callGetPresettings = () => {
    setLoadingPresetings(true);
    getSchedulePresettings(organizationId)
      .then((res: any) => {
        setPresettings(res.data);
        if (res.data[0]) {
          if (!selectedPresetting) {
            setSelectedPresetting(res.data[0]);
            setIsAddingNew(false);
          }
        }
        setLoadingPresetings(false);
      })
      .catch(() => {
        setLoadingPresetings(false);
      });
  };

  const callGetAllUsers = () => {
    getAllUsers(organizationId)
      .then((res) => {
        const staff = res.data.filter((user) => user.role === "STAFF");
        const newStaffOptions = staff.map((staff: IOrganizationUser) => {
          return {
            label: `${staff.user.firstName} ${staff.user.lastName}`,
            value: staff.user.id,
          };
        });
        setStaffOptions(newStaffOptions);
      })
      .catch(() => {});
  };

  const callGetAllGroups = () => {
    getGroups(organizationId)
      .then((res) => {
        const newGroupOptions = res.data.map((group) => {
          return {
            label: group.name,
            value: group.id,
          };
        });
        setGroupOptions(newGroupOptions);
      })
      .catch(() => {});
  };

  useEffect(() => {
    callGetPresettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchPresettings]);

  useEffect(() => {
    callGetAllUsers();
    callGetAllGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const emptyPresettings =
    !loadingPresettings && !presettings.length && !selectedPresetting;

  const renderPressetingsList = (): JSX.Element => {
    if (loadingPresettings) {
      return <div />; //loader
    } else if (emptyPresettings) {
      return <div />; //empty groups info
    } else {
      return (
        <>
          {presettings.map((item, index) => (
            <ItemSelect
              type="schedule"
              key={item.id}
              color={item.color}
              name={item.name}
              selected={item.id === selectedPresetting?.id}
              onClick={() => {
                setSelectedPresetting(item);
                setIsAddingNew(false);
              }}
              lastItem={index === presettings.length - 1}
            />
          ))}
        </>
      );
    }
  };

  const schema = object().shape({
    name: string()
      .required("Name is required")
      .min(2, "Name needs to be longer than 2 characters"),
    nDays: number()
      .min(1, "Cannot be less than 1 day")
      .max(7, "Cannot be more than 7 days"),
    color: string().optional(), //TODO: maybe add validation for the color, to be different from other presetting colors
    startTime: string().test(
      "not empty",
      "Start time cannot be empty",
      function (value) {
        return !!value;
      }
    ),
    endTime: string().test(
      "start time test",
      "End time must be after start time",
      function (end) {
        const { startTime } = this.parent;
        if (!end || !startTime) {
          return false;
        }
        return compareHours({ startHour: startTime, endHour: end });
      }
    ),
    meetConditions: array().min(1, "You need to select at least 1 condition"),
    dualStaffCondition: object().required(),
    algorithmPrioritization: array().min(
      1,
      "You need to select at least 1 condition"
    ),
    userFilter: array().optional(),
    groupFilter: array().optional(),
  });

  const callCreatePresetting = (body: SchedulePresettingsBody) => {
    createSchedulePresetting(organizationId, body)
      .then(() => {
        enqueueSnackbar("Successfully created setting", { variant: "success" });
        refetchPresettings(fetchPresettings + 1);
      })
      .catch(() => {
        enqueueSnackbar("Failed to create setting", { variant: "error" });
      });
  };

  const callUpdatePresetting = (body: SchedulePresettingsBody) => {
    updateSchedulePresetting(organizationId, selectedPresetting!.id, body)
      .then(() => {
        enqueueSnackbar("Successfully updated presetting", {
          variant: "success",
          autoHideDuration: 1000,
        });
        refetchPresettings(fetchPresettings + 1);
      })
      .catch(() => {
        enqueueSnackbar("Failed to update presetting", {
          variant: "error",
          autoHideDuration: 1000,
        });
      });
  };

  const callDeletePrestting = () => {
    deleteSchedulePresetting(organizationId, selectedPresetting!.id)
      .then(() => {
        setLoadingDeleting(false);
        setConfirmingDeletion(false);
        setSelectedPresetting(null);
        enqueueSnackbar("Successfully deleted presetting", {
          variant: "success",
          autoHideDuration: 1000,
        });
        refetchPresettings(fetchPresettings + 1);
      })
      .catch(() => {
        enqueueSnackbar("Failed to delete presetting", {
          variant: "error",
          autoHideDuration: 1000,
        });
      });
  };
  const getFormInitialValues = () => {
    return {
      name: selectedPresetting?.name || "",
      color: selectedPresetting?.color || "",
      userFilter: (function dynamicallySetInitialValues() {
        if (
          selectedPresetting?.userFilter &&
          selectedPresetting.userFilter.length
        ) {
          let keyedOptions: any = {};
          for (const option of staffOptions) {
            keyedOptions[option.value] = option;
          }
          return selectedPresetting!.userFilter.map((id) => keyedOptions[id]);
        } else {
          return [];
        }
      })(),
      groupFilter: (function dynamicallySetInitialValues() {
        if (
          selectedPresetting?.groupFilter &&
          selectedPresetting.groupFilter.length
        ) {
          let keyedOptions: any = {};
          for (const option of groupOptions) {
            keyedOptions[option.value] = option;
          }
          return selectedPresetting!.groupFilter.map((id) => keyedOptions[id]);
        } else {
          return [];
        }
      })(),
      startTime: selectedPresetting?.timeSpan.startTime
        ? selectedPresetting?.timeSpan.startTime.slice(0, 5)
        : "",
      endTime: selectedPresetting?.timeSpan.endTime
        ? selectedPresetting?.timeSpan.endTime.slice(0, 5)
        : "",
      meetConditions: selectedPresetting?.meetConditions
        ? selectedPresetting.meetConditions.map(
            (meetCondition) => meetConditionOptionsMapper[meetCondition]
          )
        : [],
      dualStaffCondition: selectedPresetting?.dualStaffCondition
        ? dualStaffingConditionsOptions.filter(
            (option: any) =>
              option.value === selectedPresetting.dualStaffCondition
          )[0]
        : null,
      algorithmPrioritization: selectedPresetting?.algorithmPrioritization
        ? selectedPresetting.algorithmPrioritization.map(
            (algPrior) => algorithmPrioritizationOptionsMapper[algPrior]
          )
        : [],
      nDays: selectedPresetting?.nDays || 0,
    };
  };

  const {
    // submitForm, this will be used to submit on any change
    handleBlur,
    handleSubmit,

    submitForm,
    setFieldValue,
    values,
    errors = {},
    touched,
  } = useFormik({
    validationSchema: schema,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true, //IMPORTANT: this allows to reset the form data when another presetting is selected
    initialValues: getFormInitialValues(),

    onSubmit: (values) => {
      const {
        algorithmPrioritization,
        color,
        dualStaffCondition,
        meetConditions,
        nDays,
        name,
        startTime,
        endTime,
        userFilter,
        groupFilter,
      } = values;

      const body: SchedulePresettingsBody = {
        algorithmPrioritization: algorithmPrioritization.map(
          ({ value }: any) => value
        ),
        color,
        dualStaffCondition: dualStaffCondition!
          .value! as ScheduleDualStaffingCondition,
        meetConditions: meetConditions.map(({ value }: any) => value),
        nDays,
        name,
        timeSpan: {
          startTime: `${startTime}:00Z`,
          endTime: `${endTime}:00Z`,
        },
        userFilter: userFilter.map((filter) => filter.value),
        groupFilter: groupFilter.map((filter) => filter.value),
      };
      if (isAddingNew) {
        callCreatePresetting(body);
      } else {
        callUpdatePresetting(body);
      }
    },
  });

  const debounceUpdatePresetting = useCallback(debounce(submitForm, 500), []); // eslint-disable-line react-hooks/exhaustive-deps

  const renderFilterFields = (): JSX.Element => (
    <>
      {renderSectionName(translate("Filter"), "mb-4 mt-8")}

      <Select
        id={"userFilter"}
        error={String(errors.userFilter || "")}
        touched={!!touched.userFilter}
        labelAbove={translate("staffFilter")}
        options={staffOptions}
        value={values.userFilter}
        selectType={"multiple"}
        onChange={(value: any) => {
          setFieldValue("userFilter", value);
          !isAddingNew && debounceUpdatePresetting();
        }}
      />
      <div className="mb-4" />
      <Select
        id={"groupFilter"}
        error={String(errors.groupFilter || "")}
        touched={!!touched.groupFilter}
        labelAbove={translate("groupFilter")}
        options={groupOptions}
        value={values.groupFilter}
        selectType={"multiple"}
        onChange={(value: any) => {
          setFieldValue("groupFilter", value);
          !isAddingNew && debounceUpdatePresetting();
        }}
      />
    </>
  );
  const renderEditPresseting = (): JSX.Element => (
    <>
      <div className="flex w-full flex-col self-start mt-4 ">
        <div className="flex flex-1 gap-10">
          <Input
            id="name"
            value={values.name}
            placeholder={translate("enterGeneratorSettingName")}
            labelAbove={translate("name")}
            type="text"
            autoCapitalize="none"
            onChange={(e: any) => {
              setFieldValue("name", e.target.value);
              !isAddingNew && debounceUpdatePresetting();
            }}
            onBlur={handleBlur("name")}
            error={errors.name || ""}
            touched={touched.name || false}
          />
          <Input
            id="nDays"
            placeholder={translate("enterNumberOfDays")}
            value={String(values.nDays || "")}
            labelAbove={translate("numberOfDays")}
            type="number"
            min={1}
            max={7}
            autoCapitalize="none"
            onChange={(e: any) => {
              setFieldValue("nDays", e.target.value);
              !isAddingNew && debounceUpdatePresetting();
            }}
            onBlur={handleBlur("nDays")}
            error={String(errors.nDays || "")}
            touched={!!touched.nDays || false}
          />

          <ColorPicker
            labelAbove={translate("color")}
            pickerVisible={colorPickerVisible}
            onPreviewClicked={() => {
              setColorPickerVisible(!colorPickerVisible);
            }}
            color={values.color}
            error={errors.color || ""}
            touched={!!touched.color}
            onChangeComplete={(hex) => {
              setFieldValue("color", hex);
              setColorPickerVisible(false);
              !isAddingNew && debounceUpdatePresetting();
            }}
          />
        </div>

        <div className="flex flex-1 gap-10 mt-4">
          <Input
            id="startTime"
            placeholder={translate("enterStartTime")}
            value={values.startTime}
            labelAbove={translate("startTime")}
            type="time"
            autoCapitalize="none"
            onChange={(e: any) => {
              setFieldValue("startTime", e.target.value);
              !isAddingNew && debounceUpdatePresetting();
            }}
            onBlur={handleBlur("startTime")}
            error={errors.startTime || ""}
            touched={touched.startTime || false}
          />
          <Input
            id="endTime"
            placeholder={translate("enterEndTime")}
            value={values.endTime}
            labelAbove={translate("endTime")}
            type="time"
            autoCapitalize="none"
            onChange={(e: any) => {
              setFieldValue("endTime", e.target.value);
              !isAddingNew && debounceUpdatePresetting();
            }}
            onBlur={handleBlur("endTime")}
            error={errors.endTime || ""}
            touched={touched.endTime || false}
          />
        </div>

        {renderSectionName(translate("conditionsForSchedule"), "mb-4 mt-8")}

        <>
          <Select
            id="meetConditions"
            error={String(errors.meetConditions || "")}
            touched={!!touched.meetConditions}
            labelAbove={translate("meetConditions")}
            options={meetConditionOptions}
            value={values.meetConditions}
            selectType={"multiple"}
            onChange={(value: any) => {
              setFieldValue("meetConditions", value);
              !isAddingNew && debounceUpdatePresetting();
            }}
          />
          <div className="mb-4" />
          <Select
            error={String(errors.dualStaffCondition || "")}
            touched={!!touched.dualStaffCondition}
            labelAbove={translate("dualStaffingCondition")}
            options={dualStaffingConditionsOptions}
            value={values.dualStaffCondition as any}
            selectType={"normal"}
            onChange={(value: any) => {
              setFieldValue("dualStaffCondition", value);
              !isAddingNew && debounceUpdatePresetting();
            }}
          />
        </>
        {renderSectionName(translate("prioritizations"), "mb-4 mt-8")}
        <Select
          error={String(errors.algorithmPrioritization || "")}
          touched={!!touched.algorithmPrioritization}
          labelAbove={translate("algorithmPrioritization")}
          options={algorithmPrioritizationOptions}
          value={values.algorithmPrioritization}
          selectType={"multiple"}
          onChange={(value: any) =>
            setFieldValue("algorithmPrioritization", value)
          }
        />

        <div className="mb-4" />

        <Switch
          value={filterItemsVisible}
          onClick={() => setFilterItemsVisible(!filterItemsVisible)}
        />

        {filterItemsVisible && renderFilterFields()}
      </div>

      <IconButton
        hasDoubleCheck={!isAddingNew} //double check only if the action is DELETE
        doubleCheckLabel={translate("continueDeletingGeneratorsetting")}
        confirming={confirmingDeletion}
        loadingText={translate("deleting")}
        isLoading={loadingDeleting}
        onConfirmClicked={() => {
          setLoadingDeleting(true);
          callDeletePrestting();
        }}
        onCancelClicked={() => {
          setConfirmingDeletion(false);
        }}
        continueLabel={translate("Yes")}
        cancelLabel={translate("No")}
        icon={isAddingNew ? "add" : "delete"}
        label={
          isAddingNew ? translate("addGeneratorSetting") : translate("deleteGeneratorSetting")
        }
        customStyle="mt-20 self-center"
        onClick={() => {
          if (isAddingNew) {
            handleSubmit();
          } else {
            setConfirmingDeletion(!confirmingDeletion);
          }
        }}
      />
    </>
  );

  return (
    <div className="flex flex-[4] gap-4">
      <div className="flex-[5]">
        <div className="card-container items-stretch">
          {renderSectionName(
            isAddingNew
              ? translate("addGeneratorSetting")
              : `${
                  selectedPresetting?.name
                    ? "Förinställningar"
                    : "Förinställningar"
                }`
          )}
          {emptyPresettings && !isAddingNew && (
            <p className="normal-text mt-2">
              There are no presettings, but you can create one
            </p>
          )}
          {(!!selectedPresetting || isAddingNew) && renderEditPresseting()}
        </div>
      </div>
      <div className="flex-[2]">
        <div className="card-container">
          <IconButton
            type="submit"
            customStyle="100% mb-4"
            icon="add"
            onClick={() => {
              setIsAddingNew(true);
              setSelectedPresetting(null);
            }}
            label={translate("addGeneratorSetting")}
          />
          {renderPressetingsList()}
        </div>
      </div>
    </div>
  );
};

export default Presettings;
