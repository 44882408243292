import "./DayPickerButton.scss";

type DayPickerButtonProps = {
  label: string;
  active?: boolean;
  onClick: () => void;
};

const DayPickerButton = (props: DayPickerButtonProps) => {
  const classes = ["day-picker-button", "waves-effect", "waves-dark"];
  if (props.active) {
    classes.push("active");
  }
  return (
    <div className={classes.join(" ")} onClick={props.onClick}>
      {props.label}
    </div>
  );
};

export default DayPickerButton;
