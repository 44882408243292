import { InputRules } from "./Types";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

export const updateObject = (oldObject: any, updatedProperties: any) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

export const checkValidity = (value: string | number, rules: InputRules) => {
  let isValid = true;
  if (rules.required) {
    isValid = (value as string).trim() !== "" && isValid;
  }
  if (rules.minLength) {
    isValid = (value as string).length >= rules.minLength && isValid;
  }
  if (rules.maxLength) {
    isValid = (value as string).length <= rules.maxLength && isValid;
  }
  if (rules.isEmail) {
    const pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    isValid = pattern.test(value as string) && isValid;
  }
  if (rules.isNumeric) {
    isValid = !isNaN(value as number) && isValid;
  }
  return isValid;
};

export const normalizeTime = (time: string) => {
  if (time === "") {
    return "-";
  }

  const [hours, minutes] = time.split(":").map(Number);

  const timeObject = DateTime.fromObject({
    hour: hours,
    minute: minutes,
    second: 0,
    millisecond: 0,
  });
  return timeObject.toFormat("HH:mm");
};

export const getWeekday = (dateString: string) => {
  const date = DateTime.fromISO(dateString);
  return date.weekday - 1;
};

export const convertTimeToNumber = (time: string) => {
  const a = time.split(":");
  return +a[0] * 60 + +a[1];
};

export const toIsoString = (date: Date) => {
  var tzo = -date.getTimezoneOffset(),
    dif = tzo >= 0 ? "+" : "-",
    pad = function (num: number) {
      return (num < 10 ? "0" : "") + num;
    };

  return (
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate()) +
    "T" +
    pad(date.getHours()) +
    ":" +
    pad(date.getMinutes()) +
    ":" +
    pad(date.getSeconds()) +
    dif +
    pad(Math.floor(Math.abs(tzo) / 60)) +
    ":" +
    pad(Math.abs(tzo) % 60)
  );
};

export const convertTimeToString = (time: number) => {
  return `${
    Math.floor((time || 1) / 60) > 9
      ? Math.floor((time || 1) / 60)
      : `0${Math.floor((time || 1) / 60)}`
  }:${(time || 1) % 60 > 9 ? (time || 1) % 60 : `0${(time || 1) % 60}`}`;
};

export const convertToDropdown = (
  list: Array<any>,
  nameKey: string,
  valueKey: string
) => {
  return list.map((item) => ({ label: item[nameKey], value: item[valueKey] }));
};

export const formatDate = (date: Date) => {
  const fullIsoStringDate = date.toISOString();
  const formattedDate = fullIsoStringDate.split("T")[0];
  return formattedDate;
};

export const compareHours = ({
  endHour, //format: hh:mm
  startHour, //format: hh:mm
}: {
  endHour: string;
  startHour: string;
}): boolean => {
  const startDt = new Date(`01/01/1970 ${startHour}`);
  const endDt = new Date(`01/01/1970 ${endHour}`);
  return endDt > startDt;
};

export const getDuration = (startHour: string, endHour: string) => {
  return (
    (new Date(`01/01/1970 ${endHour}`).getTime() -
      new Date(`01/01/1970 ${startHour}`).getTime()) /
    60000
  );
};

export const dateToString = (date: Date) => {
  return date.toISOString().substring(0, 10);
};

export const dateTimeToString = (date: DateTime) => {
  return dateToString(date.toJSDate());
};

export const dateHoursMinute = (date: Date) => {
  return `${date.getHours() >= 10 ? date.getHours() : `0${date.getHours()}`}:${
    date.getMinutes() >= 10 ? date.getMinutes() : `0${date.getMinutes()}`
  }`;
};

export const getFromAndToFromWeek = (date: DateTime) => {
  const startDate = date.startOf("week").plus({ days: 1 });
  const endDate = date.endOf("week");
  return [dateTimeToString(startDate), dateTimeToString(endDate)];
};

export const getWeekFromDate = (dateString: string) => {
  const date = DateTime.fromISO(dateString);
  return date.weekNumber;
};

export const useTranslatedDates = () => {
  const { t } = useTranslation();
  const translate = (key: string) => t(`translations:generalWords.${key}`);
  const days = [
    { label: translate("monday"), value: "MONDAY" },
    { label: translate("tuesday"), value: "TUESDAY" },
    { label: translate("wednesday"), value: "WEDNESDAY" },
    { label: translate("thursday"), value: "THURSDAY" },
    { label: translate("friday"), value: "FRIDAY" },
    { label: translate("saturday"), value: "SATURDAY" },
    { label: translate("sunday"), value: "SUNDAY" },
  ];
  const months = [
    translate("january"),
    translate("february"),
    translate("mars"),
    translate("april"),
    translate("may"),
    translate("june"),
    translate("july"),
    translate("august"),
    translate("september"),
    translate("october"),
    translate("november"),
    translate("december"),
  ];
  return { days, months };
};

export function dateAdd(
  date: Date,
  interval:
    | "year"
    | "quarter"
    | "month"
    | "week"
    | "day"
    | "hour"
    | "minute"
    | "second",
  units: number
) {
  if (!(date instanceof Date)) return undefined;
  let ret: Date | undefined = new Date(date); //don't change original date
  let checkRollover = function () {
    if (ret!.getDate() != date.getDate()) ret!.setDate(0);
  };
  switch (String(interval).toLowerCase()) {
    case "year":
      ret.setFullYear(ret.getFullYear() + units);
      checkRollover();
      break;
    case "quarter":
      ret.setMonth(ret.getMonth() + 3 * units);
      checkRollover();
      break;
    case "month":
      ret.setMonth(ret.getMonth() + units);
      checkRollover();
      break;
    case "week":
      ret.setDate(ret.getDate() + 7 * units);
      break;
    case "day":
      ret.setDate(ret.getDate() + units);
      break;
    case "hour":
      ret.setTime(ret.getTime() + units * 3600000);
      break;
    case "minute":
      ret.setTime(ret.getTime() + units * 60000);
      break;
    case "second":
      ret.setTime(ret.getTime() + units * 1000);
      break;
    default:
      ret = undefined;
      break;
  }
  return ret;
}

// export const days = [
//   { label: "Måndag", value: "MONDAY" },
//   { label: "Tisdag", value: "TUESDAY" },
//   { label: "Onsdag", value: "WEDNESDAY" },
//   { label: "Torsdag", value: "THURSDAY" },
//   { label: "Fredag", value: "FRIDAY" },
//   { label: "Lördag", value: "SATURDAY" },
//   { label: "Söndag", value: "SUNDAY" },
// ];

// export const months = [
//   "Januari",
//   "Februari",
//   "Mars",
//   "April",
//   "Maj",
//   "Juni",
//   "Juli",
//   "Augusti",
//   "September",
//   "Oktober",
//   "November",
//   "December",
// ];

export function shadeColor(color: string, percent: number) {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = parseInt(((R * (100 + percent)) / 100).toString());
  G = parseInt(((G * (100 + percent)) / 100).toString());
  B = parseInt(((B * (100 + percent)) / 100).toString());

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  // eslint-disable-next-line eqeqeq
  var RR = R.toString(16).length == 1 ? "0" + R.toString(16) : R.toString(16);
  // eslint-disable-next-line eqeqeq
  var GG = G.toString(16).length == 1 ? "0" + G.toString(16) : G.toString(16);
  // eslint-disable-next-line eqeqeq
  var BB = B.toString(16).length == 1 ? "0" + B.toString(16) : B.toString(16);

  return "#" + RR + GG + BB;
}
