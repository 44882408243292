import { useRef, useEffect, createRef, RefObject } from "react";

type TabOption = {
  label: string;
  value: string;
};

type TabSelectorProps = {
  options: TabOption[];
  onTabChange: (value: string) => void;
};
const TabSelector = ({ options, onTabChange }: TabSelectorProps) => {
  const widthRatio = Math.round(100 / options.length);
  const indicator = useRef<HTMLDivElement>(null);

  const optionRefs = useRef<RefObject<HTMLDivElement>[]>(
    (function addRefs() {
      return options.map(() => createRef() as RefObject<HTMLDivElement>);
    })()
  );

  useEffect(() => {
    if (optionRefs.current[0].current) {
      optionRefs.current[0].current.click();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-row items-center relative  justify-start r">
      {options.map(({ label, value }, index) => (
        <div
          ref={optionRefs.current[index]}
          onClick={() => {
            if (indicator.current)
              indicator.current.style.left = `${index * widthRatio}%`;
            optionRefs.current.forEach((ref: RefObject<HTMLDivElement>) => {
              if (ref.current) {
                ref.current.style.fontWeight = "400";
              }
            });
            const currentElement = optionRefs.current[index].current;
            if (currentElement) {
              currentElement.style.fontWeight = "600";
            }
            onTabChange(value);
          }}
          className={`items-center flex-1 mr-2 hover:cursor-pointer text-center`}
          key={`${index}.${value}`}
        >
          {label}
        </div>
      ))}
      <div
        ref={indicator}
        style={{ width: `${widthRatio}%` }}
        className={`absolute top-8 left-0 h-1 transform transition-translate duration-200 ease-in bg-mint`}
      />
    </div>
  );
};

export default TabSelector;
