import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import en from "./en";
import se from "./se";

i18n.use(LanguageDetector).init({
  resources: { "en-US": en, se },
  fallbackLng: "en-US",
  ns: ["translations"],
  defaultNS: "translations",
});

export default i18n;
