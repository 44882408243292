import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import authReducer from "./store/reducers/auth";
import { createTheme, ThemeProvider } from "@mui/material";
import SocketProvider from "./socket";
import { I18nextProvider } from "react-i18next";

import i18n from "./translations";
import "./styles/tailwind.css";

let composeEnhancers = null;
if (process.env.NODE_ENV === "development") {
  composeEnhancers =
    (window as any)["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"] || compose;
} else {
  composeEnhancers = compose;
}

const rootReducer = combineReducers({
  auth: authReducer,
});
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

const theme = createTheme({
  palette: { primary: { main: "#11ffbd" } },
});

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <SocketProvider>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </BrowserRouter>
      </SocketProvider>
    </I18nextProvider>
  </Provider>,
  document.getElementById("root")
);

reportWebVitals();
