import { ClickAwayListener } from "@mui/material";
import { Fragment, useState } from "react";
import { dateHoursMinute, shadeColor } from "../../../shared/utility";
import { IScheduledEvent } from "../../../types/event";
import OverviewEventPopup from "./OverviewEventPopup/OverviewEventPopup";
import "./OverviewRow.scss";
import { useTranslation } from "react-i18next";

type OverviewRowProps = {
  personName: string;
  events: IScheduledEvent[];
  index: number;
  onUpdated: () => void;
};

const OverviewRow = (props: OverviewRowProps) => {
  const { t } = useTranslation();
  const translate = (key: string) => t(`translations:schedulePage.${key}`);
  const [selected, setSelected] = useState("");
  const [hover, setHover] = useState("");

  const getDuration = (startDate: string, endDate: string) => {
    return (
      (new Date(endDate).getTime() - new Date(startDate).getTime()) / 60000
    );
  };

  const getMinutesFromMidnight = (dateString: string) => {
    const date = new Date(dateString);
    const minutes = date.getMinutes();
    const hours = date.getHours();
    return 60 * hours + minutes;
  };

  const sortedEvents = props.events.sort((a, b) => {
    const dateA = new Date(a.timeSpan.start);
    const dateB = new Date(b.timeSpan.start);
    return dateA.getTime() - dateB.getTime();
  });

  return (
    <div className="overview-row">
      <div className="overview-row--name">{props.personName}</div>
      {sortedEvents.map((ev, i) => {
        const color = (function getColorIfExistsOrReturnDefault() {
          if (
            ev.client &&
            ev.client.group &&
            ev.client.group[0] &&
            ev.client.group[0].color
          ) {
            return ev.client.group[0].color;
          } else return "#0099e9";
        })();

        return (
          <Fragment key={ev.id}>
            <div
              className={`overview-row--event ${
                selected === ev.id && "selected"
              }`}
              onMouseEnter={() => setHover(ev.id)}
              onMouseLeave={() => setHover("")}
              style={{
                backgroundColor:
                  hover === ev.id ? shadeColor(color!, -20) : color,
                borderLeft:
                  i === 0 ||
                  getDuration(
                    props.events[i - 1].timeSpan.end,
                    ev.timeSpan.start
                  ) > 0
                    ? "none"
                    : "1px solid white",
                minWidth: getDuration(ev.timeSpan.start, ev.timeSpan.end),
                maxWidth: getDuration(ev.timeSpan.start, ev.timeSpan.end),
                marginLeft:
                  i === 0
                    ? getMinutesFromMidnight(ev.timeSpan.start)
                    : getDuration(
                        props.events[i - 1].timeSpan.end,
                        ev.timeSpan.start
                      ) < 0
                    ? getDuration(
                        props.events[i - 1].timeSpan.end,
                        ev.timeSpan.start
                      )
                    : 0,
              }}
            >
              <ClickAwayListener
                onClickAway={() => selected === ev.id && setSelected("")}
              >
                <div>
                  <div
                    onClick={() => setSelected(selected === ev.id ? "" : ev.id)}
                    className="overview-row--event__name"
                  >
                    {`${
                      ev.client?.firstName ||
                      ev.staff?.[0]?.user?.firstName ||
                      translate("nameMissing")
                    } ${
                      ev.client?.lastName || ev.staff?.[0]?.user?.lastName || ""
                    }`}
                  </div>
                  <OverviewEventPopup
                    show={selected === ev.id}
                    under={props.index < 4}
                    description={ev.name}
                    time={`${dateHoursMinute(
                      new Date(ev.timeSpan.start)
                    )}-${dateHoursMinute(new Date(ev.timeSpan.end))}`}
                    address={
                      ev.client?.location?.address ||
                      translate("couldNotFindClientAddress")
                    }
                    person={`${
                      ev.client?.firstName ||
                      ev.staff?.[0]?.user?.firstName ||
                     translate("nameMissing")
                    } ${
                      ev.client?.lastName || ev.staff?.[0]?.user?.lastName || ""
                    }`}
                    onUpdated={props.onUpdated}
                    scheduledEvent={ev}
                  />
                </div>
              </ClickAwayListener>
            </div>
            <div
              className="overview-row--time-between"
              style={{
                minWidth:
                  i === props.events.length - 1
                    ? 0
                    : getDuration(
                        ev.timeSpan.end,
                        props.events[i + 1].timeSpan.start
                      ) < 0
                    ? 0
                    : getDuration(
                        ev.timeSpan.end,
                        props.events[i + 1].timeSpan.start
                      ),
              }}
            ></div>
          </Fragment>
        );
      })}
    </div>
  );
};

export default OverviewRow;
