import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  createWorkTime,
  updateWorkTime,
} from "../../../../../api/organization";
import { getAllUsers } from "../../../../../api/user";
import { ReduxState } from "../../../../../shared/Types";
import { useTranslatedDates } from "../../../../../shared/utility";
import {
  IWorkTime,
  IOrganizationUser,
} from "../../../../../types/organization";
import { ITimeSpan } from "../../../../../types/time";
import Button from "../../../../UI/Button/Button";
import Switch from "../../../../UI/Switch/Switch";
import "./AddWorkTime.scss";
import WorkTimeInputs from "./WorkTimeInputs/WorkTimeInputs";
import { useSnackbar } from "notistack";
import { DateTime } from "luxon";
import Dropdown from "../../../../UI/Dropdown/Dropdown";
import { Stack } from "@mui/material";
import Input from "../../../../UI/Input/Input";
import { useTranslation } from "react-i18next";

type AddWorkTimeProps = {
  day: string;
  userId: string;
  availableForDualStaffing?: boolean;
  onSaved: () => void;
  isWorkShift?: boolean;
  isEditing: boolean;
  editWorkTime: IWorkTime | null;
  onEditSave: () => void;
  organizationId: string;
};

export interface IWorkTimeEdit {
  type: "WORK_SHIFT" | "BREAK" | "TIME_OFF";
  timeSpan: ITimeSpan;
  duration?: number;
}

const AddWorkTime = (props: AddWorkTimeProps) => {
  const { t } = useTranslation();

  const { days } = useTranslatedDates();

  const translate = (key: string) => t(`translations:userPage.${key}`);
  const [addedWorkTime, setAddedWorkTime] = useState<IWorkTimeEdit>({
    type: props.isWorkShift ? "WORK_SHIFT" : "BREAK",
    timeSpan: {
      type: "WEEKDAY",
      weekday: props.day,
      startTime: props.editWorkTime
        ? props.editWorkTime.timeSpan.startTime
        : props.isWorkShift
        ? "07:00:00Z"
        : "12:00:00Z",
      endTime: props.editWorkTime
        ? props.editWorkTime.timeSpan.endTime
        : props.isWorkShift
        ? "16:00:00Z"
        : "13:00:00Z",
      repeatEveryNWeeks: props?.editWorkTime?.timeSpan.repeatEveryNWeeks || 1,
      startWeek:
        props?.editWorkTime?.timeSpan.startWeek || DateTime.now().weekNumber,
    },
    duration: Number(props.editWorkTime?.duration) / 60,
  });
  const [time, setTime] = useState<{ start: string; end: string; max: number }>(
    {
      start: addedWorkTime.timeSpan.startTime!,
      end: addedWorkTime.timeSpan.endTime!,
      max: addedWorkTime.duration!,
    }
  );
  const [partTime, setpartTime] = useState(!!props.editWorkTime?.duration);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [hasSubmitError, setHasSubmitError] = useState(false);
  const [repeatEveryNWeeks, setRepeatEveryNWeeks] = useState(
    addedWorkTime.timeSpan.repeatEveryNWeeks?.toString()!
  );
  const [startWeek, setStartWeek] = useState(
    addedWorkTime.timeSpan.startWeek?.toString()!
  );

  const [selectedCompanion, setSelectedCompanion] = useState<string>(
    props.editWorkTime?.connectedTo?.organizationUser?.user.id || "ingen"
  );
  const [staffMembers, setStaffMembers] = useState<IOrganizationUser[]>([]);

  const [hasCompanion, setHasCompanion] = useState(
    props.editWorkTime?.availableForDualStaffing
  );
  const { enqueueSnackbar } = useSnackbar();

  const onSave = () => {
    if (
      isStartbBeforeEnd(
        addedWorkTime.timeSpan.startTime!,
        addedWorkTime.timeSpan.endTime!
      )
    ) {
      setHasSubmitError(true);
      return;
    }
    setIsLoading(true);
    createWorkTime(
      props.userId,
      addedWorkTime,
      props.organizationId,
      partTime,
      hasCompanion,
      selectedCompanion
    )
      .then(() => {
        setIsLoading(false);
        props.onSaved();
        enqueueSnackbar(
          `Skapade ${props.isWorkShift ? "ett nytt skift" : "en ny rast"}`,
          { variant: "success" }
        );
      })
      .catch(() => {
        setHasError(true);
        setIsLoading(false);
        enqueueSnackbar(
          `Ett fel inträffade när ${
            props.isWorkShift ? "ett skift" : "en rast"
          } skulle skapas`,
          { variant: "error" }
        );
      });
  };

  const onEditSave = (workTimeId: string) => {
    if (
      isStartbBeforeEnd(
        addedWorkTime.timeSpan.startTime!,
        addedWorkTime.timeSpan.endTime!
      )
    ) {
      setHasSubmitError(true);
      return;
    }
    setIsLoading(true);
    updateWorkTime(
      props.userId,
      workTimeId,
      addedWorkTime,
      props.organizationId,
      hasCompanion,
      selectedCompanion
    )
      .then(() => {
        setIsLoading(false);
        props.onEditSave();
        enqueueSnackbar(`Uppdaterade ${props.isWorkShift ? "skift" : "rast"}`, {
          variant: "success",
        });
      })
      .catch(() => {
        setHasError(true);
        setIsLoading(false);
        enqueueSnackbar(
          `Ett fel inträffade när ${
            props.isWorkShift ? "skiftet" : "rasten"
          } skulle uppdateras`,
          { variant: "error" }
        );
      });
  };

  const isStartbBeforeEnd = (start: string, end: string) => {
    return start > end;
  };

  useEffect(() => {
    const { organizationId } = props;
    getAllUsers(organizationId)
      .then((res) => {
        setStaffMembers(res.data.filter((u) => u.role === "STAFF"));
      })
      .catch(() => {
        enqueueSnackbar(`Misslyckades med att hämta personal.`, {
          variant: "error",
        });
      });
  }, [enqueueSnackbar, props]);

  useEffect(() => {
    setAddedWorkTime({
      type: props.isWorkShift ? "WORK_SHIFT" : "BREAK",
      timeSpan: {
        type: "WEEKDAY",
        weekday: props.day,
        startTime: `${time.start.slice(0, 5)}:00Z`,
        endTime: `${time.end.slice(0, 5)}:00Z`,
        startWeek: +startWeek,
        repeatEveryNWeeks: +repeatEveryNWeeks,
      },
      duration: partTime ? time.max * 60 : 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    time,
    partTime,
    props.isWorkShift,
    props.day,
    startWeek,
    repeatEveryNWeeks,
  ]);

  useEffect(() => {
    if (partTime && !props.editWorkTime?.duration) {
      setTime((state) => {
        const duration = DateTime.fromISO(state.end).diff(
          DateTime.fromISO(state.start),
          ["hours"]
        );
        return { ...state, max: duration.hours };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partTime]);

  const timeChangedHandler = (start: string, end: string, max: number) => {
    setTime({ start, end, max });
  };

  const renderCompanionsDropdown = (): JSX.Element => {
    const options = staffMembers.map(({ user: { firstName, id } }) => ({
      label: firstName,
      value: id,
    }));
    options.unshift({ label: "ingen", value: "ingen" });
    return (
      <Dropdown
        showTitle
        style={{ marginTop: "1rem" }}
        options={options}
        value={selectedCompanion || ""}
        title="Koppla samman arbetspass med"
        onSelect={(val) => {
          setSelectedCompanion(val);
        }}
      />
    );
  };

  return (
    <form
      style={{ display: "flex", alignItems: "center", flexDirection: "column" }}
      className="add-wt"
      onSubmit={(e) => {
        e.preventDefault();
        if (props.isEditing) {
          onEditSave(props.editWorkTime!.id);
          return;
        }
        onSave();
      }}
    >
      <p className="add-wt--title">
        {translate("add")} {props.isWorkShift ? "skift" : "rast"} för{" "}
        {days.filter((day) => day.value === props.day)[0].label}
      </p>
      <Switch
        label={translate("specificTimesOrHourBased")}
        value={partTime}
        onClick={() => setpartTime((state) => !state)}
      />
      <p className="add-wt--error">
        {hasError
          ? "Tiden du försöker lägga till överlappar med en tillagd tid"
          : hasSubmitError && "Intervallet är felaktigt"}
      </p>
      <Stack spacing={2}>
        <WorkTimeInputs
          isPartTime={partTime}
          onChange={(time) =>
            timeChangedHandler(time.start, time.end, time.max)
          }
          startTime={addedWorkTime.timeSpan.startTime!}
          endTime={addedWorkTime.timeSpan.endTime!}
          max={addedWorkTime.duration || 0}
          isWorkShift={props.isWorkShift}
        />
        <Stack direction="row" alignItems="center" spacing={2}>
          <Dropdown
            showTitle
            title={translate("repetition")}
            options={[
              { label: translate("everyWeek"), value: "1" },
              { label: translate("everyTwoWeek"), value: "2" },
              { label: translate("everyThreeWeek"), value: "3" },
              { label: translate("everyFourWeek"), value: "4" },
            ]}
            value={repeatEveryNWeeks}
            onSelect={(value) => setRepeatEveryNWeeks(value)}
          />
          <Input
            type="number"
            value={startWeek}
            onChange={(e) => setStartWeek(e.target.value)}
            min={1}
            max={52}
            label={translate("startWeek")}
          />
        </Stack>
      </Stack>
      <div style={{ height: "1rem" }} />
      <Switch
        label={translate("canDoDualStaffing")}
        value={!!hasCompanion}
        onClick={() => setHasCompanion(!hasCompanion)}
      />

      {hasCompanion && renderCompanionsDropdown()}

      <div className="add-wt--save">
        <Button label="Spara" color="green" isLoading={isLoading} />
      </div>
    </form>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  organizationId: state.auth.organizationId,
});

export default connect(mapStateToProps)(AddWorkTime);
