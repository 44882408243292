import "./DropdownCreate.scss";
import CreatableSelect from "react-select/creatable";

interface IDropdownCreateProps {
  options: { label: string; value: string }[];
  title: string;
  onSelect: (value: any) => void;
  value: string;
  isLoading?: boolean;
  width?: number;
  borderRadius?: string;
  isSearchable?: boolean;
  showTitle?: boolean;
  white?: boolean;
  onCreateOption?: (value: string) => void | null;
}

const DropdownCreate = (props: IDropdownCreateProps) => {
  const dropdownStyles = {
    control: (styles: any, state: any) => ({
      ...styles,
      borderColor: "rgba(223, 223, 227, 0.7)",
      width: props.width ? props.width : 200,
      color: "#2B2B2B",
      borderRadius: props.borderRadius ? props.borderRadius : "40px",
      backgroundColor: props.white ? "#FFFFFF" : "#F3F3F3",
    }),
    option: (styles: any, state: any) => ({
      ...styles,
      backgroundColor: state.isFocused ? "#11ffbd" : "transparent",
      color: "black",
      zIndex: 1000,
    }),
    menu: (provided: any) => ({ ...provided, zIndex: 1000 }),
    dropdownIndicator: (base: any, state: any) => ({
      ...base,
      transition: "all 200ms ease",
      transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
    }),
  };

  return (
    <div className="dropdown-create-wrapper">
      {props.showTitle && (
        <div className="dropdown-create-title">{props.title}</div>
      )}
      <CreatableSelect
        options={props.options}
        value={
          props.value === ""
            ? null
            : props.options.find((obj) => obj.value === props.value)
        }
        placeholder={props.title}
        styles={dropdownStyles}
        loadingMessage={() => "Laddar..."}
        isLoading={props.isLoading}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: "black",
          },
        })}
        onChange={(value) => props.onSelect(value?.value || "")}
        noOptionsMessage={() => "Inga resultat"}
        components={{
          IndicatorSeparator: () => null,
        }}
        isSearchable={
          props.isSearchable === undefined ? false : props.isSearchable
        }
        isValidNewOption={() => !!props.onCreateOption}
        onCreateOption={props.onCreateOption}
      />
    </div>
  );
};

export default DropdownCreate;
