import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { FaTimesCircle } from "react-icons/fa";
import {
  createOverride,
  updateOverride,
  deleteOverride,
  listUserOverrides,
} from "../../../../../api/schedule";
import { ReduxState } from "../../../../../shared/Types";
import Button from "../../../../UI/Button/Button";
import Input from "../../../../UI/Input/Input";
import "./TimeOffSettings.scss";

const TimeOffSettings = () => {
  const [date, setDate] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const { organizationId, userId } = useSelector(
    (state: ReduxState) => state.auth
  );
  const [fetchOverrides, refetchOverrides] = useState(0);
  const [override, setOverride] = useState<Override>();

  useEffect(() => {
    callListUserOverrides();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchOverrides]);

  const callListUserOverrides = () => {
    listUserOverrides({
      organizationId,
      userId,
    }).then((res) => {
      if (res.data[0]) {
        setOverride(res.data[0]);
      }
    });
  };

  const getOverrideBody = () => {
    const today = new Date().toISOString().split("T")[0];
    const body = {
      timeSpanToOverride: {
        start: `${today}T00:00:00Z`,
        end: `${date}T23:59:59Z`,
      },
      available: false,
    };
    return body;
  };

  const callCreateOverride = () => {
    createOverride({
      organizationId,
      userId,
      ...getOverrideBody(),
    })
      .then(() => {
        enqueueSnackbar(`Pausad framtill ${date}`, {
          variant: "success",
          autoHideDuration: 100,
        });
        refetchOverrides(fetchOverrides + 1);
      })
      .catch(() => {
        enqueueSnackbar("Detta datumet finns redan.", {
          variant: "error",
          autoHideDuration: 1000,
        });
      });
  };

  const callUpdateOverride = () => {
    updateOverride({
      organizationId,
      organizationUserOverrideId: override!.id,
      userId,
      ...getOverrideBody(),
    })
      .then(() => {
        enqueueSnackbar(`Pausad framtill ${date}`, {
          variant: "success",
          autoHideDuration: 100,
        });
        refetchOverrides(fetchOverrides + 1);
      })
      .catch(() => {
        enqueueSnackbar("Detta datumet finns redan.", {
          variant: "error",
          autoHideDuration: 1000,
        });
      });
  };

  const callDeleteOverride = () => {
    deleteOverride({
      organizationId,
      organizationUserOverrideId: override!.id,
      userId,
    })
      .then(() => {
        setOverride(undefined);
        setDate("");
        enqueueSnackbar("Successfully deleted override", {
          variant: "success",
          autoHideDuration: 1000,
        });
      })
      .catch(() => {
        enqueueSnackbar("Failed to delete override", {
          variant: "error",
          autoHideDuration: 1000,
        });
      });
  };

  const saveHandler = () => {
    if (!!override) {
      callUpdateOverride();
    } else {
      callCreateOverride();
    }
  };

  const getOverrideDate = () => override!.timeSpanToOverride.end.split("T")[0];

  return (
    <div className="time-off-settings">
      {override && (
        <div className="group relative">
          <div className="bg-mint bold-text pt-[10px] pb-[10px] pl-[35px] pr-[35px] rounded-full">
            {getOverrideDate()}
          </div>
          <FaTimesCircle
            onClick={() => {
              callDeleteOverride();
            }}
            className={`absolute right-2 top-[10px] transform
                        transition-all 
                        duration-100 ease-in scale-0
                        group-hover:scale-100 group-hover:z-[1000]`}
          />
        </div>
      )}
      <Input
        type="date"
        value={date}
        onChange={(e) => setDate(e.target.value)}
      />
      {date && <Button label="Spara" color="green" onClick={saveHandler} />}
    </div>
  );
};

export default TimeOffSettings;
