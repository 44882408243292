import "./DropdownMultiple.scss";
import { useRef } from "react";
import Dropdown from "../Dropdown/Dropdown";
import { truncate } from "lodash";

type DropdownMultipleProps = {
  values: any[];
  onSelect: (value: any) => void;
  onRemove: (value: any) => void;
  options: { label: string; value: any }[];
  getDisplayName: (object: any) => string;
  getId?: (object: any) => string;
  title?: string;
  onDragOver?: (startElement: any, endElement: any) => void;
  showTitle?: boolean;
  isDraggable?: boolean;
};

const DropdownMultiple = (props: DropdownMultipleProps) => {
  const dragStartElement = useRef(null);

  return (
    <>
      {props.title && !props.showTitle && (
        <div className="dropdown-multiple-title">{props.title}</div>
      )}
      <div
        className="dropdown-multiple"
        style={{ alignItems: props.showTitle ? "flex-end" : "" }}
      >
        {props.values.map((value, i) => (
          <div
            key={i}
            draggable={
              !!props.isDraggable && !!props.getId!(value) && !!props.onDragOver
            }
            onDragOver={(e) => {
              e.preventDefault();
            }}
            onDragStart={() => {
              dragStartElement.current = value;
            }}
            onDrop={() => {
              props.onDragOver!(dragStartElement.current, value);
              dragStartElement.current = null;
            }}
            className="dropdown-multiple--value"
          >
            {props.getDisplayName(value)}
            <i
              className="fas fa-times-circle"
              onClick={() => props.onRemove(value)}
            />
          </div>
        ))}
        <Dropdown
          title={props.title ? truncate(props.title, { length: 22 }) : ""}
          value={""}
          options={props.options}
          onSelect={(value) => props.onSelect(value)}
          isSearchable
          showTitle={props.showTitle}
        />
      </div>
    </>
  );
};

export default DropdownMultiple;
