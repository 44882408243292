import { DateTime } from "luxon";
import axios from "../axios";
import { dateTimeToString } from "../shared/utility";
import { IScheduledEvent } from "../types/event";
import {
  CancelledReason,
  IScheduleEvent,
  IScheduleUser,
  IStatistics,
} from "../types/schedule";
import { ISpecificTimeSpan } from "../types/time";

export const generateSchedule = (
  organizationID: string,
  fromDate: DateTime,
  to: DateTime,
  maxTime: number
) => {
  return axios.post<{ estimatedTimeToSolve: number }>(
    `event/scheduled_event/generate`,
    {
      organizationID,
      solvingGoal: "OPTIMAL_CONTINUITY",
      from: dateTimeToString(fromDate),
      to: dateTimeToString(to),
      maxTime,
    }
  );
};

export const publishDraftSchedule = (
  organizationID: string,
  fromDate: DateTime,
  toDate: DateTime
) => {
  return axios.post<{ user: IScheduleUser; events: IScheduleEvent[] }[]>(
    `event/scheduled_event/publish`,
    {
      organizationID,
      scheduleType: "STAGING",
      from: dateTimeToString(fromDate),
      to: dateTimeToString(toDate),
    }
  );
};

export const getScheduleOverview = (
  organizationId: string,
  date: string,
  userType: "STAFF" | "CLIENT",
  scheduleType: "LIVE" | "STAGING"
) => {
  return axios.get<{ user: IScheduleUser; events: IScheduledEvent[] }[]>(
    `event/scheduled_event`,
    {
      params: {
        date: date ? date : undefined,
        userType,
        organizationID: organizationId,
        scheduleType,
      },
    }
  );
};

export const getScheduledEvents = (
  organizationId: string,
  from: string,
  to: string,
  scheduleType: "LIVE" | "STAGING"
) => {
  return axios.get<NewEvent[]>("event/scheduled_event_new", {
    params: {
      from,
      to,
      scheduleType,
      organizationID: organizationId,
    },
  });
};

export const getIndividualSchedule = (
  userId: string,
  from: string,
  to: string,
  scheduleType: "LIVE" | "STAGING"
) => {
  return axios.get<IScheduledEvent[]>(`event/scheduled_event/user/${userId}`, {
    params: {
      from: from ? from : undefined,
      to: to ? to : undefined,
      scheduleType,
    },
  });
};

export const downloadScheduleOverview = (
  date: string,
  userType: "STAFF" | "CLIENT",
  organizationId: string,
  scheduleType: "LIVE" | "STAGING"
) => {
  axios
    .get(`event/scheduled_event/download`, {
      responseType: "blob",
      params: { date, userType, organizationID: organizationId, scheduleType },
    })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `schedule-overview-${date}.csv`);
      document.body.appendChild(link);
      link.click();
    });
};

export const downloadIndividualSchedule = (
  userId: string,
  from: string,
  to: string,
  scheduleType: "LIVE" | "STAGING"
) => {
  axios
    .get<string>(`event/scheduled_event/user/${userId}/download`, {
      params: { from, to, scheduleType },
    })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `schedule-${userId}-${from}-${to}.csv`);
      document.body.appendChild(link);
      link.click();
    });
};

export const getScheduleStatistics = (scheduleId: string) => {
  return axios.get<IStatistics>(`statistic/schedule/${scheduleId}`);
};

export const createScheduledEvent = ({
  userId,
  organizationId,
  name,
  description,
  timeSpan,
  scheduleType,
  staff,
  failed = false,
}: {
  userId: string;
  organizationId: string;
  name: string;
  description: string;
  type?: string;
  timeSpan: {
    start: string;
    end: string;
  };
  plannedEventId?: string;
  scheduleType: string;
  staff: {
    userID: string;
    timeSpan: {
      start: string;
      end: string;
    };
  }[];
  failed: boolean;
}) => {
  return axios.post(`/event/scheduled_event`, {
    organizationID: organizationId,
    userID: userId,
    name,
    description,
    timeSpan,
    scheduleType,
    staff,
    failed,
  });
};

export const updateScheduleEvent = (
  scheduleEventId: string,
  updateData?: {
    staff?: {
      userID: string;
      timeSpan: ISpecificTimeSpan;
    }[];
    cancelled?: CancelledReason;
    failed?: boolean;
    timeSpan?: {
      start: string;
      end: string;
    };
    rebookAt?: {
      start: string;
      end: string;
    };
  }
) => {
  return axios.patch(`event/scheduled_event/${scheduleEventId}`, updateData);
};

export const updateScheduledEvent = (
  scheduleEventId: string,
  updateData: {
    description: string;
    timeSpan: ISpecificTimeSpan;
  }
) => {
  return axios.patch(`event/scheduled_event/${scheduleEventId}`, updateData);
};

export const getStatistics = (
  organizationID: string,
  from: string,
  to: string,
  scheduleType: "LIVE" | "STAGING"
) => {
  return axios.get<StatisticsResponse>(`statistic/schedule/${scheduleType}`, {
    params: {
      organizationID,
      from,
      to,
    },
  });
};

export const getSchedulePresettings = (organizationId: string) =>
  axios.get<SchedulePresetting[]>(
    `organization/${organizationId}/schedule_generator_setting`
  );

export const createSchedulePresetting = (
  organizationId: string,
  body: SchedulePresettingsBody
) =>
  axios.post<SchedulePresettingsBody>(
    `organization/${organizationId}/schedule_generator_setting`,
    {
      ...body,
    }
  );
export const updateSchedulePresetting = (
  organizationId: string,
  schedulePresettingId: string,
  body: any
) =>
  axios.patch(
    `organization/${organizationId}/schedule_generator_setting/${schedulePresettingId}`,
    body
  );

export const deleteSchedulePresetting = (
  organizationId: string,
  schedulePresettingId: string
) =>
  axios.delete(
    `organization/${organizationId}/schedule_generator_setting/${schedulePresettingId}`
  );

type OverrideRequestBody = {
  timeSpanToOverride: TimespanInEvent;
  comment?: string;
  available?: boolean;
  notAvailableReason: string;
  [x: string]: any;
};

export const createOverride = ({
  organizationId,
  userId,
  ...body
}: {
  organizationId: string;
  userId: string;
}) => {
  return axios.post(`organization/${organizationId}/user/${userId}/override`, {
    ...body,
  });
};

export const listUserOverrides = ({
  organizationId,
  userId,
}: {
  organizationId: string;
  userId: string;
}) => axios.get(`organization/${organizationId}/user/${userId}/override`);

export const updateOverride = ({
  organizationId,
  organizationUserOverrideId,
  userId,
  ...body
}: {
  organizationId: string;
  userId: string;
  organizationUserOverrideId: string;
}) =>
  axios.patch(
    `organization/${organizationId}/user/${userId}/override/${organizationUserOverrideId}`,
    { ...body }
  );

export const deleteOverride = ({
  organizationId,
  organizationUserOverrideId,
  userId,
}: {
  organizationId: string;
  userId: string;
  organizationUserOverrideId: string;
}) =>
  axios.delete(
    `organization/${organizationId}/user/${userId}/override/${organizationUserOverrideId}`
  );

export const createOverrideForEvent = ({
  eventId,
  ...body
}: {
  eventId: string;
  body: OverrideRequestBody;
  organizationUserOverrideId: string;
}) => axios.post(`event/planned_event/${eventId}/override`, { ...body });

export const updateOverrideForEvent = ({
  plannedEventOverrideId,
  eventId,
  ...body
}: {
  eventId: string;
  body: OverrideRequestBody;
  plannedEventOverrideId: string;
}) =>
  axios.patch(
    `event/planned_event/${eventId}/override/${plannedEventOverrideId}`,
    { ...body }
  );

export const listEventTypes = (organzationId: string) =>
  axios.get<EventType[]>("event/type", {
    params: {
      organizationID: organzationId,
    },
  });

export const createEventType = (organizationId: string, event: EventType) =>
  axios.post("event/type", { organizationID: organizationId, ...event });

export const updateEventType = (eventTypeId: string, event: EventType) =>
  axios.patch(`event/type/${eventTypeId}`, event);

export const deleteEventType = (eventTypeId: string) =>
  axios.delete(`event/type/${eventTypeId}`);
