const Input = ({
  labelAbove,
  error,
  touched,
  LeftIcon,
  RightIcon,
  ...rest
}: DefaultFieldProps & InputProps) => {
  const { isTextArea, ...props } = rest;

  const renderInput = () => (
    <input
      className={
        rest.plain
          ? ""
          : `rounded-3xl outline-none h-10 bold-text bg-[#F3F3F3] self-stretch border-1 w-full px-4 py-2 focus:border-mint transition-all duration-200 ease-out`
      }
      {...props}
    />
  );

  const renderTextArea = () => (
    <textarea
      className={
        rest.plain
          ? ""
          : `rounded-3xl outline-none h-20 bold-text bg-[#F3F3F3] self-stretch w-full border-1 px-4 py-2 focus:border-mint transition-all duration-200 ease-out`
      }
      {...props}
    />
  );

  return (
    <div className="flex flex-col relative w-[200px]">
      {labelAbove && (
        <label className="bold-text text-xs mb-2">{labelAbove}</label>
      )}
      <div className="flex flex-row items-center w-full">
        {LeftIcon && LeftIcon}
        {isTextArea ? renderTextArea() : renderInput()}
      </div>
      {RightIcon && RightIcon}
      {error && touched && (
        <label className="normal-text text-red-500 text-right pt-1">
          {error}
        </label>
      )}
    </div>
  );
};

export default Input;
