import { useState } from "react";
import Button from "../../UI/Button/Button";
import "./AddUser.scss";
import ContactInfo from "../ContactInfo/ContactInfo";
import NameSex from "../NameSex/NameSex";
import Location from "../Location/Location";
import { useTranslation } from "react-i18next";

type AddUserProps = {
  onAdd: (
    firstName: string,
    lastName: string,
    email: string,
    phonenNumber: string,
    gender: string,
    address: string,
    postalcode: string
  ) => void;
  addError: boolean;
};

const AddUser = (props: AddUserProps) => {
  const { t } = useTranslation();
  const translate = (key: string) => t(`translations:userPage.${key}`);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [gender, setGender] = useState("OTHER");
  const [postalCode, setPostalCode] = useState("");
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        props.onAdd(firstName, lastName, email, phoneNumber, gender, address, postalCode);
      }}
      className="add-user"
    >
      {props.addError && <div className="add-user--error">{translate("notCorrectFilledOutForm")}</div>}
      <div className="add-user--sections">
        <NameSex
          firstName={firstName}
          onFirstNameChange={(value: string) => setFirstName(value)}
          lastName={lastName}
          onLastNameChange={(value: string) => setLastName(value)}
          gender={gender}
          onGenderChange={(gender: string) => setGender(gender)}
        />
        <ContactInfo
          email={email}
          phoneNumber={phoneNumber}
          onEmailChange={(email: string) => setEmail(email)}
          onPhoneNumberChange={(phoneNumber: string) => setPhoneNumber(phoneNumber)}
        />
        <Location
          address={address}
          onAddressChange={(value) => setAddress(value)}
          postalCode={postalCode}
          onPostalCodeChange={(value) => setPostalCode(value)}
        />
      </div>
      <Button color="green" label={translate("addUser")} />
    </form>
  );
};

export default AddUser;
