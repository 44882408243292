import "./ToolbarButtons.scss";
import * as actions from "../../../store/actions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const ToolbarButtons = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <div className="toolbar-buttons">
      <div
        className="toolbar-buttons__logout waves-effect waves-dark"
        onClick={() => {
          dispatch(actions.logout());
          navigate("/");
        }}
      >
        <i className="far fa-sign-out-alt" />
      </div>
    </div>
  );
};

export default ToolbarButtons;
