import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { getScheduledEvents } from "../../../api/schedule";
import { ReduxState } from "../../../shared/Types";

// import events from "./mock";
import MissedEvent from "./MissedEvent";
import { useTranslation } from "react-i18next";

const scheduleTypes = ["LIVE", "STAGING"];

const MissedEvents = ({
  date,
  scheduleType,
  onEventDrag = (ev: Event) => {},
  draggableEvents = false,
}: any) => {
  const { t } = useTranslation();
  const translate = (key: string) => t(`translations:schedulePage.${key}`);
  const organizationId = useSelector(
    (state: ReduxState) => state.auth.organizationId
  );
  const [events, setEvents] = useState<NewEvent[]>([]);

  const [foldedEvents, setFoldedEvents] = useState(true);
  const eventsToDisplay = foldedEvents ? events.slice(0, 7) : events;

  const transformEvents = (events: NewEvent[]) => {
    const userObj: any = {};
    const clientObj: any = {};
    for (const event of events) {
      if (typeof event.user === "object") {
        userObj[event.user.id] = event.user;
      }
      if (typeof event.client === "object") {
        clientObj[event.client.id] = event.client;
      }
    }
    const eventsWithUserAndClient = events.map((event) => ({
      ...event,
      user: typeof event.user === "object" ? event.user : userObj[event.user],
      client:
        typeof event.client === "object"
          ? event.client
          : clientObj[event.client],
    }));
    const missedEvents = eventsWithUserAndClient.filter(
      (event) => !!event.failed
    );
    return missedEvents.map((event) => ({
      ...event,
      user: typeof event.user === "object" ? event.user : userObj[event.user],
      client:
        typeof event.client === "object"
          ? event.client
          : clientObj[event.client],
    }));
  };

  useEffect(() => {
    const fromDate = date.toJSDate();
    const toDate = date.toJSDate();
    fromDate.setUTCHours(0, 0, 0, 0);
    toDate.setUTCHours(23, 59, 59, 999);
    getScheduledEvents(
      organizationId,
      fromDate.toISOString(),
      toDate.toISOString(),
      scheduleTypes[scheduleType] as "LIVE" | "STAGING"
    )
      .then((res) => {
        const transformedEvents = transformEvents(res.data);
        setEvents(transformedEvents);
      })
      .catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, scheduleType]);

  return (
    <div className="card-container mt-4">
      <div className="flex self-stretch flex-row justify-between items-center">
        <div className="self-start bold-text text-xl mr-8">
          {`${translate("missedEvents")} (${events.length} st)`}
        </div>
        {events.length > 7 && (
          <div
            onClick={() => setFoldedEvents(!foldedEvents)}
            className="bold-text text-slate-500 hover:cursor-pointer"
          >
            {foldedEvents ? translate("showMore") : translate("showLess")}
          </div>
        )}
      </div>

      <div className="mt-3 flex flex-row gap-3 flex-wrap self-stretch items-start">
        {eventsToDisplay.map((event, index) => {
          let firstName = "";
          let lastName = "";
          let color = "#8ed1fc";
          if (typeof event.user === "object") {
            firstName = event.user.firstName || "";
            lastName = event.user.lastName || "";
            if (
              event.user.group &&
              event.user.group[0] &&
              event.user.group[0].color
            ) {
              color = event.user.group[0].color;
            }
          }
          const start = event.timeSpan.start.split("T")[1].slice(0, 5);
          const end = event.timeSpan.end.split("T")[1].slice(0, 5);
          const duration = event?.plannedEvent?.duration || 1;
          // const duration = (function getMaxDuration() {
          //   const duration = DateTime.fromISO(event.timeSpan.end).diff(
          //     DateTime.fromISO(event.timeSpan.start),
          //     ["hours"]
          //   );
          //   return duration.hours * 60;
          // })();

          return (
            <MissedEvent
              draggableEvents={draggableEvents}
              key={index}
              event={event}
              eventName={event.name || ""}
              clientName={`${firstName} ${lastName}`}
              color={color}
              start={start}
              end={end}
              duration={duration}
              onDragStart={onEventDrag}
            />
          );
        })}
      </div>
    </div>
  );
};

export default MissedEvents;
