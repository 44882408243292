import Input from "../../UI/Input/Input";
import "./ContactInfo.scss";
import { useTranslation } from "react-i18next";
type ContactInfoProps = {
  email: string;
  phoneNumber: string;
  password?: string;
  onEmailChange: (email: string) => void;
  onPhoneNumberChange: (phoneNumber: string) => void;
  onPasswordChange?: (password: string) => void;
};

const ContactInfo = (props: ContactInfoProps) => {
  const { t } = useTranslation();
  const translate = (key: string) => t(`translations:userPage.${key}`);
  return (
    <div className="contact-info">
      <div className="contact-info--title">{translate("contactInfo")}</div>
      <div className="contact-info--inputs">
        <Input
          label={translate("email")}
          type="text"
          value={props.email}
          onChange={(e) => props.onEmailChange(e.target.value)}
        />
        <Input
          label={translate("phoneNumber")}
          type="text"
          value={props.phoneNumber}
          onChange={(e) => props.onPhoneNumberChange(e.target.value)}
        />
        {props.password !== undefined && (
          <Input
            label={translate("password")}
            type="password"
            value={props.password}
            onChange={(e) => props.onPasswordChange?.(e.target.value)}
            autoComplete="new-password"
          />
        )}
      </div>
    </div>
  );
};

export default ContactInfo;
