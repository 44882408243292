import Logo from "../Navigation/Logo/Logo";
import NavigationItems from "../Navigation/NavigationItems/NavigationItems";
import "./Toolbar.scss";
import ToolbarButtons from "./ToolbarButtons/ToolbarButtons";

type ToolbarProps = {
  isAdmin: boolean;
};

const Toolbar = (props: ToolbarProps) => {
  return (
    <div className="toolbar">
      <div className="toolbar--logo">
        <Logo />
      </div>
      <NavigationItems isAdmin={props.isAdmin} />
      <div className="toolbar--buttons">
        <ToolbarButtons />
      </div>
    </div>
  );
};

export default Toolbar;
