import { truncate } from "lodash";
import { IWorkTime } from "../../../types/organization";
import { EventOverride } from "./types";

const weekdays = [
  "SUNDAY",
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
] as const;

const getCommaSeparatedValuesIfPossibe = (
  possibleArray: any | undefined,
  arrayKey?: string
) => {
  let values = "";
  if (!!possibleArray && possibleArray.length) {
    const namesArray = possibleArray.map((val: any) => {
      if (!arrayKey) {
        return val;
      } else if (val[arrayKey]) {
        return val[arrayKey];
      } else {
        return "";
      }
    });
    values = namesArray.join(", ");
    values = truncate(values, { length: 20 });
  }
  return values;
};

const getHealthCenter = (healthCenter: any) => {
  if (healthCenter) {
    if (healthCenter.name) {
      return healthCenter.name;
    } else {
      return healthCenter;
    }
  } else {
    return "";
  }
};

const getNumberOfWeek = (date: Date): number => {
  const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
  const pastDaysOfYear = (date.valueOf() - firstDayOfYear.valueOf()) / 86400000;
  return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
};

const getStartEndFromTimeSpan = (
  timeSpans: OverrideTimeSpanResponse[],
  date: Date
) => {
  let start: string | boolean;
  let end: string | boolean;
  const weekOfTheYear = getNumberOfWeek(date) - 1;
  const currentWeekIndex = date.getDay();
  const currentWeekDay = weekdays[currentWeekIndex];
  const todayTimeSpan = timeSpans.filter(
    (timespan) => currentWeekDay === timespan.weekday
  )[0];

  if (todayTimeSpan) {
    let startWeek = todayTimeSpan.startWeek;
    if (startWeek <= weekOfTheYear) {
      if (
        weekOfTheYear % todayTimeSpan.repeatEveryNWeeks ===
        startWeek % todayTimeSpan.repeatEveryNWeeks
      ) {
        start = todayTimeSpan.startTime || false;
        end = todayTimeSpan.endTime || false;
      } else {
        start = false;
        end = false;
      }
    } else {
      start = false;
      end = false;
    }
  } else {
    start = false;
    end = false;
  }
  return { start, end };
};

const getWorktimeFields = (
  worktimes: IWorkTime[] | OverrideTimeSpanResponse[],
  date: Date
): WorktimeFields => {
  const weekOfTheYear = getNumberOfWeek(date) - 1;
  const currentWeekIndex = date.getDay();
  const currentWeekDay = weekdays[currentWeekIndex];
  let repetiton: any;
  let startWeek: any;
  let hasWorkshiftThisDate: boolean;
  let start: string;
  let end: string;
  let duration: any;
  let availableForDualStaffing: any;
  let connectedTo: any;

  const todayWorkTime = worktimes.filter((worktime) => {
    let weekDayToCompare: any;
    if ("weekday" in worktime) {
      weekDayToCompare = worktime.weekday;
    } else {
      weekDayToCompare = worktime.timeSpan.weekday;
    }
    return currentWeekDay === weekDayToCompare;
  })[0];

  if (todayWorkTime) {
    repetiton = todayWorkTime.timeSpan.repeatEveryNWeeks;
    startWeek = todayWorkTime.timeSpan.startWeek;
    duration = todayWorkTime.duration;
    availableForDualStaffing = todayWorkTime.availableForDualStaffing;
    connectedTo = todayWorkTime.connectedTo?.organizationUser?.user?.firstName;

    if (startWeek <= weekOfTheYear) {
      if (weekOfTheYear % repetiton === startWeek % repetiton) {
        hasWorkshiftThisDate = true;
        start = todayWorkTime.timeSpan.startTime || "";
        end = todayWorkTime.timeSpan.endTime || "";
      } else {
        hasWorkshiftThisDate = false;
        start = "-";
        end = "-";
        availableForDualStaffing = "-";
        connectedTo = "-";
        duration = "-";
      }
    } else {
      hasWorkshiftThisDate = false;
      start = "-";
      end = "-";
      repetiton = "-";
      startWeek = "-";
      availableForDualStaffing = "-";
      connectedTo = "-";
      duration = "-";
    }
  } else {
    hasWorkshiftThisDate = false;
    start = "-";
    end = "-";
    repetiton = "-";
    startWeek = "-";
    duration = "-";
    availableForDualStaffing = "-";
    connectedTo = "-";
  }

  return {
    duration,
    repetiton,
    startWeek,
    hasWorkshiftThisDate,
    start,
    end,
    availableForDualStaffing,
    connectedTo,
  };
};

const getOverrideFields = (override: Override[]) => {
  let available: boolean = true;
  let notAvailableReason: string = "-";
  let overrideStart: string = "-";
  let overrideEnd: string = "-";
  let overrideObject: Override | undefined;
  let comment: string = "";
  let overrideId: string | null = null;

  for (const obj of override) {
    const startDay = obj.timeSpanToOverride.start.split("T")[0];
    const endDay = obj.timeSpanToOverride.end.split("T")[0];
    if (startDay === endDay) {
      overrideObject = obj;
    } else {
      available = obj.available;
    }
  }

  if (!!overrideObject) {
    available = overrideObject.available;
    overrideId = overrideObject.id;
    if (!available) {
      notAvailableReason = overrideObject.notAvailableReason;
      overrideStart = overrideObject.timeSpanToOverride.start;
      overrideEnd = overrideObject.timeSpanToOverride.end;
      comment = overrideObject.comment;
    }
  }

  return {
    available,
    notAvailableReason,
    overrideStart,
    overrideEnd,
    comment,
    overrideId,
  };
};

const getOverrideFieldsForEvent = (override: EventOverride[]) => {
  let available: boolean = true;
  let notAvailableReason: string = "-";
  let overrideStart: string = "-";
  let overrideEnd: string = "-";
  let overrideObject: EventOverride | undefined;
  let comment: string = "";
  let overrideId: string | null = null;

  for (const obj of override) {
    const startDay = obj.timeSpanToOverride.start.split("T")[0];
    const endDay = obj.timeSpanToOverride.end.split("T")[0];
    if (startDay === endDay) {
      overrideObject = obj;
    } else {
      available = obj.occur;
    }
  }

  if (!!overrideObject) {
    available = overrideObject.occur;
    overrideId = overrideObject.id;
    if (!available) {
      // notAvailableReason = overrideObject.notAvailableReason;
      notAvailableReason = "";

      overrideStart = overrideObject.timeSpanToOverride.start;
      overrideEnd = overrideObject.timeSpanToOverride.end;
      // comment = overrideObject.comment;
      comment = "";
    }
  }

  return {
    available,
    notAvailableReason,
    overrideStart,
    overrideEnd,
    comment,
    overrideId,
  };
};

const getDuration = (startDate: string, endDate: string) => {
  return (new Date(endDate).getTime() - new Date(startDate).getTime()) / 60000;
};

const transformOverride = (
  data: OverrideResponse,
  selectedDate = new Date()
): ScheduleOverrideTransformedData => {
  const { users, plannedEvents } = data;
  //client
  const clientData = users.filter((user) => user.role === "CLIENT");
  const clientRows = clientData.map((client) => {
    const displayLanguages = getCommaSeparatedValuesIfPossibe(
      client.user.details?.languages,
      "name"
    );
    const displayAllergies = getCommaSeparatedValuesIfPossibe(
      client.user.details?.allergens,
      "name"
    );

    const displayHealthCenter = getHealthCenter(
      client.user.details?.healthCenter
    );
    const displayPriorites = getCommaSeparatedValuesIfPossibe(
      client.user.restrictions?.likes,
      "firstName"
    );
    const displayDislikes = getCommaSeparatedValuesIfPossibe(
      client.user.restrictions?.dislikes,
      "firstName"
    );

    const {
      comment,
      available,
      notAvailableReason,
      overrideStart,
      overrideEnd,
      overrideId,
    } = getOverrideFields(client.override);

    return {
      id: client.user.id,
      groupName: client.group[0]?.name || "",
      groupColor: client.group[0]?.color || "#fff",
      email: truncate(client.user.email || "", { length: 20 }),
      name: `${client.user.firstName} ${client.user.lastName}`,
      phoneNumber: client.user.details?.phoneNumber || "",
      languages: displayLanguages,
      allergies: displayAllergies,
      address: client.user.details?.location?.address || "",
      postalCode: client.user.details?.location?.postalCode || "",
      healthCenter: displayHealthCenter,
      priorities: displayPriorites,
      dislikes: displayDislikes,
      available,
      notAvailableReason,
      overrideStart,
      overrideEnd,
      comment,
      overrideId,
    };
  });

  //staff
  const staffData = users.filter((user) => user.role === "STAFF");
  const staffRows = staffData.map((staff) => {
    const displayLanguages = getCommaSeparatedValuesIfPossibe(
      staff.user.details?.languages,
      "name"
    );
    const displayTransportMethods = getCommaSeparatedValuesIfPossibe(
      staff.user.details?.transportMethods
    );

    const displayAllergies = getCommaSeparatedValuesIfPossibe(
      staff.user.restrictions?.allergies,
      "name"
    );
    const {
      repetiton,
      startWeek,
      start,
      duration,
      availableForDualStaffing,
      connectedTo,
      end,
      hasWorkshiftThisDate,
    } = getWorktimeFields(staff.workTime, selectedDate);

    const {
      comment,
      available,
      notAvailableReason,
      overrideStart,
      overrideEnd,
      overrideId,
    } = getOverrideFields(staff.override);

    return {
      id: staff.user!.id,
      email: truncate(staff.user.email || "", { length: 20 }),
      groupName: staff.group[0]?.name || "",
      groupColor: staff.group[0]?.color || "#fff",
      name: `${staff.user.firstName} ${staff.user.lastName}`,
      phoneNumber: staff.user.details?.phoneNumber || "",
      languages: displayLanguages,
      transportMethods: displayTransportMethods,
      employmentType: staff.user.details?.employmentType || "",
      allergies: displayAllergies,
      hasWorkshiftThisDate,
      repetiton,
      startWeek,
      start,
      end,
      duration,
      availableForDualStaffing,
      connectedTo,
      available,
      notAvailableReason,
      overrideStart,
      overrideEnd,
      comment,
      overrideId,
    };
  });

  //event
  const eventData = plannedEvents.map((event) => {
    const getStaffDisplayName = () => {
      if (event.staff) {
        let displayName = "";
        for (let staffPerson of staffData) {
          let newName = "";
          if (staffPerson.user.firstName) {
            newName = staffPerson.user.firstName + ", ";
          }
          displayName = displayName + newName;
        }
        return truncate(displayName, { length: 20 });
      } else {
        return "";
      }
    };

    const {
      available,
      notAvailableReason,
      overrideStart,
      overrideEnd,
      comment,
      overrideId,
    } = getOverrideFieldsForEvent(event.override || []);

    const { start, end } = getStartEndFromTimeSpan(
      event.timeSpan,
      selectedDate
    );

    return {
      id: event.id,
      name: event.name,
      clientName: event.user?.firstName || "-",
      duration: event.duration || getDuration(end as string, start as string),
      description: event.description,
      lock: event.prioritized,
      staffNeeded: String(event.staffNeeded || "-"),
      staff: getStaffDisplayName(),
      start,
      end,
      available,
      notAvailableReason,
      overrideStart,
      overrideEnd,
      comment,
      overrideId,
    };
  });

  const eventRows = eventData.filter((event) => event.start !== false);

  return { clientRows, staffRows, eventRows: eventRows };
};

export default transformOverride;
