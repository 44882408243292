import RoleChanger from "./RoleChanger/RoleChanger";
import IconButton from "../UI/IconButton/IconButton";
import Input from "../UI/Input/Input";
import "./UserSelector.scss";
import ItemSelect from "../UI/ItemSelect";
import { IOrganizationUser } from "../../types/organization";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

type UserSelectorProps = {
  users: IOrganizationUser[];
  isStaff?: boolean;
  selectedUserId: string;
  onSelectUser: (userId: string) => void;
  search: (searchQuery: string) => void;
  onPopupShow?: (shouldShow: boolean) => void;
  onRoleChange?: (role: "STAFF" | "CLIENT") => void;
};

const UserSelector = ({
  users,
  isStaff,
  selectedUserId,
  onSelectUser,
  onPopupShow,
  search,
  onRoleChange,
}: UserSelectorProps) => {
  const [searchQuery, setSearchQuery] = useState("");
  const { t } = useTranslation();
  const translate = (key: string) => t(`translations:userPage.${key}`);
  useEffect(() => {
    search(searchQuery);
  }, [searchQuery, search]);

  return (
    <div className="user-selector shadow-md">
      <RoleChanger isStaff={isStaff} onRoleChange={onRoleChange} />
      <div className="user-selector--user-section">
        <Input
          label={translate("search")}
          type="text"
          width="100%"
          placeHolder={translate("name")}
          style={{ marginBottom: "0.8rem" }}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        {onPopupShow && (
          <IconButton
            icon="plus"
            label={`${translate("add")} ${isStaff ? translate("staff") : translate("client")}`}
            width="100%"
            color="grey"
            onClick={() => onPopupShow && onPopupShow(true)}
            className="add-button"
          />
        )}
        <div className="user-selector--user-section__users">
          {users
            .filter((user) => user.role === (isStaff ? "STAFF" : "CLIENT"))
            .map((user) => (
              <ItemSelect
                key={user.user.id}
                name={`${user.user.firstName} ${user.user.lastName}`}
                selected={selectedUserId === user.user.id}
                onClick={() => onSelectUser(user.user.id)}
                color={(function () {
                  if (user.group && user.group[0] && user.group[0].color) {
                    return user.group[0].color;
                  } else {
                    return "#000";
                  }
                })()}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default UserSelector;
