import { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { object, string, array, boolean } from "yup";
import { debounce } from "lodash";

import { useSnackbar } from "notistack";
import { FaLock, FaUnlock } from "react-icons/fa";

import {
  listEventTypes,
  createEventType,
  deleteEventType,
  updateEventType,
} from "../../../../api/schedule";
// import { getAllUsers } from "../../../../api/user";
import { ReduxState } from "../../../../shared/Types";
import { compareHours, getDuration, useTranslatedDates } from "../../../../shared/utility";

import Switch from "../../../../components/UI/Switch/Switch";
import IconButton from "../../../../components/UI/NewIconButton";
import DayPicker from "../../../../components/UI/DayPicker/DayPicker";
import ItemSelect from "../../../../components/UI/ItemSelect";
import Input from "../../../../components/Form/Input";
import Select from "../../../../components/Form/Select";
import ColorPicker from "../../../../components/Form/ColorPicker";
import { useTranslation } from "react-i18next";

const renderSectionName = (sectionName: string, customStyles?: string) => (
  <div className={customStyles}>
    <p className={`bold-text self-start`}>{sectionName}</p>
    <div className="divider-line" />
  </div>
);

const EventTypes = (): JSX.Element => {
  const { t } = useTranslation();
  const translate = (key: string) => t(`translations:settingsPage.${key}`);
  const translateUserPage = (key: string) => t(`translations:userPage.${key}`);
  const { enqueueSnackbar } = useSnackbar();

  const organizationId = useSelector(
    (state: ReduxState) => state.auth.organizationId
  );

  //eventTypes data
  const [loadingEventTypes, setLoadingEventTypes] = useState(true);
  const [eventTypes, setEventTypes] = useState<EventType[]>([]); //todo: use set presetting (when retrieving data from the API)
  const [selectedEventType, setSelectedEventType] = useState<EventType | null>(
    null
  );
  //form
  const [isAddingNew, setIsAddingNew] = useState<boolean>(
    eventTypes.length === 0
  ); //immediatley show add new if there are no eventTypes
  const [colorPickerVisible, setColorPickerVisible] = useState<boolean>(false);
  const [loadingDeleting, setLoadingDeleting] = useState(false);
  const [confirmingDeletion, setConfirmingDeletion] = useState(false);
  const [fetchEventTypes, refetchEventTypes] = useState(0);
  // const [requiresExtraStaff, setRequiresExtraStaff] = useState(true);
  // const [staff, setStaff] = useState<IOrganizationUser[]>([]);

  // const callGetAllUsers = () => {
  //   getAllUsers(organizationId).then(() =>
  //     // res
  //     {
  //       // const staffUsers = res.data.filter((user) => user.role === "STAFF");
  //       // setStaff(staffUsers);
  //     }
  //   );
  // };

  const callGetEventTypes = () => {
    setLoadingEventTypes(true);
    listEventTypes(organizationId)
      .then((res: any) => {
        setLoadingEventTypes(false);
        setEventTypes(res.data);
        if (res.data.length > 0) {
          if (selectedEventType === null) {
            setIsAddingNew(false);
            setSelectedEventType(res.data[0]);
          }
        } else {
          setIsAddingNew(true);
        }
      })
      .catch(() => {
        setLoadingEventTypes(false);
      });
  };

  useEffect(() => {
    // callGetAllUsers();
    callGetEventTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchEventTypes]);

  const emptyEventTypes =
    !loadingEventTypes && !eventTypes.length && !selectedEventType;

  const renderEventTypesList = (): JSX.Element => {
    if (loadingEventTypes) {
      return <div />; //loader
    } else if (emptyEventTypes) {
      return <div />; //empty groups info
    } else {
      return (
        <>
          {eventTypes.map((item, index) => (
            <ItemSelect
              type="schedule"
              key={item.id}
              color={item.color}
              name={item.name}
              selected={item.id === selectedEventType?.id}
              onClick={() => {
                setSelectedEventType(item);
                setIsAddingNew(false);
              }}
              lastItem={index === eventTypes.length - 1}
            />
          ))}
        </>
      );
    }
  };

  const schema = object().shape({
    name: string()
      .required(translate("nameIsRequired"))
      .min(1, translate("nameCanNotBeEmpty")),
    externalID: string()
      .required(translate("externalIDisRequired"))
      .min(1, "Name needs to be longer than 6 characters"),

    color: string().optional(),
    start: string().test(
      translate("notEmpty"),
      translate("startTimeCanNotBeEmpty"),
      function (value) {
        return !!value;
      }
    ),
    end: string().test(
      translate("startTimeTest"),
      translate("endTimeMustBeBiggerThanStarttime"),
      function (end) {
        const { start } = this.parent;
        if (!end || !start) {
          return false;
        }
        return compareHours({ startHour: start, endHour: end });
      }
    ),
    // duration: number().min(10, "Duration should be a least than 10 min"),
    conditions: array().min(1, "You need to select at least 1 condition"),
    repeated: object().required("This is required"),
    anyWeekday: boolean().optional(),
    days: object().test(
      "has values",
      "You need to select at least one day",
      function (days) {
        const { anyWeekday } = this.parent;

        if (anyWeekday) {
          return true;
        }

        return !!days && !!Object.values(days).filter((day) => !!day).length;
      }
    ),
  });

  const callCreateEvenType = (body: any) => {
    createEventType(organizationId, body)
      .then(() => {
        enqueueSnackbar("Successfully created event tyoe", {
          variant: "success",
        });
        refetchEventTypes(fetchEventTypes + 1);
      })
      .catch(() => {
        enqueueSnackbar("Failed to create event type", { variant: "error" });
      });
  };

  const callUpdateEventType = (eventTypeId: string, body: any) => {
    updateEventType(eventTypeId, body)
      .then(() => {
        enqueueSnackbar("Successfully updated event type", {
          variant: "success",
          autoHideDuration: 1000,
        });
        refetchEventTypes(fetchEventTypes + 1);
      })
      .catch(() => {
        enqueueSnackbar("Successfully updated event type", {
          variant: "success",
          autoHideDuration: 1000,
        });
      });
  };

  const callDeleteEventType = () => {
    deleteEventType(selectedEventType!.id!)
      .then(() => {
        setSelectedEventType(null);
        setLoadingDeleting(false);
        setConfirmingDeletion(false);
        enqueueSnackbar("Successfully deleted event type", {
          variant: "success",
          autoHideDuration: 1000,
        });
        refetchEventTypes(fetchEventTypes + 1);
      })
      .catch(() => {
        enqueueSnackbar("Failed to delete event type", {
          variant: "error",
          autoHideDuration: 1000,
        });
      });
  };

  const conditionOptionMapper = {
    ANYONE: {
      label: translate("anyone"),
      value: "ANYONE",
    },
    SPECIFIC: {
      label: translate("specific"),
      value: "SPECIFIC",
    },
    DUAL_STAFFING: {
      label: translate("dualStaffing"),
      value: "DUAL_STAFFING",
    },
    ONLY_FEMALES: {
      label: translate("onlyFemales"),
      value: "ONLY_FEMALES",
    },
    ONLY_MALES: {
      label: translate("onlyMales"),
      value: "ONLY_MALES",
    },
  };

  const conditionOptions = [
    {
      label: translate("Anyone"),
      value: "ANYONE",
    },
    {
      label: translate("specific"),
      value: "SPECIFIC",
    },
    {
      label: translate("dualStaffing"),
      value: "DUAL_STAFFING",
    },
    {
      label: translate("onlyFemales"),
      value: "ONLY_FEMALES",
    },
    {
      label: translate("onlyMales"),
      value: "ONLY_MALES",
    },
  ];

  const repetitionOptions = [
    { label: translateUserPage("everyWeek"), value: "1" },
    { label: translateUserPage("everyTwoWeek"), value: "2" },
    { label: translateUserPage("everyThreeWeek"), value: "3" },
    { label: translateUserPage("everyFourWeek"), value: "4" },
  ];

  const emptyDays = {
    monday: "",
    tuesday: "",
    wednesday: "",
    thursday: "",
    friday: "",
    saturday: "",
    sunday: "",
  };

  const calculateFieldDuration = (
    start: string | undefined,
    end: string | undefined
  ) => {
    if (start && end) {
      if (compareHours({ endHour: end, startHour: start })) {
        return getDuration(start, end);
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };

  const getFormInitialValues = () => {
    return {
      name: selectedEventType?.name || "",
      color: selectedEventType?.color || "",
      description: selectedEventType?.description || "",
      externalID: selectedEventType?.externalID || "",
      start: selectedEventType?.timeSpan[0]?.startTime
        ? selectedEventType?.timeSpan[0].startTime.slice(0, 5)
        : "",
      end: selectedEventType?.timeSpan[0]?.endTime
        ? selectedEventType?.timeSpan[0].endTime.slice(0, 5)
        : "",
      days: selectedEventType?.timeSpan
        ? (function getDays() {
            const eventDays = selectedEventType.timeSpan.map(
              (ts) => ts.weekday
            );
            const daysObj: any = { ...emptyDays };
            for (const day of eventDays) {
              if (day) {
                daysObj[day.toLowerCase()] = day;
              }
            }
            return daysObj;
          })()
        : emptyDays,
      duration: (function calculateDuration() {
        if (selectedEventType?.duration) {
          return selectedEventType.duration;
        } else {
          const start = selectedEventType?.timeSpan[0].startTime;
          const end = selectedEventType?.timeSpan[0].endTime;
          calculateFieldDuration(start, end);
        }
      })(),
      prioritized: !!selectedEventType?.prioritized,
      conditions: selectedEventType?.conditions
        ? selectedEventType.conditions.map(
            (conditionKey) => conditionOptionMapper[conditionKey]
          )
        : [],
      repeated: (function getRepeatedValue() {
        if (selectedEventType?.timeSpan[0]?.repeatEveryNWeeks) {
          return repetitionOptions.filter(
            (option) =>
              option.value ===
              String(selectedEventType!.timeSpan[0].repeatEveryNWeeks)
          )[0];
        } else return repetitionOptions[0];
      })(),
      anyWeekday: selectedEventType?.timeSpan[0]?.anyWeekday,
    };
  };

  const {
    // submitForm, this will be used to submit on any change
    handleBlur,
    handleSubmit,
    setFieldValue,
    submitForm,
    values,
    errors = {},
    touched,
  } = useFormik({
    validationSchema: schema,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true, //IMPORTANT: this allows to reset the form data when another presetting is selected
    initialValues: getFormInitialValues(),
    onSubmit: (values) => {
      const {
        days,
        color,
        conditions,
        prioritized,
        description,
        duration,
        start,
        end,
        externalID,
        name,
        repeated,
        anyWeekday,
      } = values;

      let durationToSend = duration;

      if (!durationToSend) {
        durationToSend = getDuration(start, end);
      }
      const selectedDays = Object.values(days).filter((day) => !!day);
      const conditionsToSend = conditions.map((condition) => condition.value);

      let timeSpan = [];

      if (anyWeekday) {
        timeSpan = [
          {
            startTime: `${start}:00Z`,
            endTime: `${end}:00Z`,
            duration: durationToSend,
            type: "WEEKDAY",
            anyWeekday: true,
          },
        ];
      } else {
        timeSpan = selectedDays.map((day) => {
          return {
            startTime: `${start}:00Z`,
            endTime: `${end}:00Z`,
            duration: durationToSend,
            type: "WEEKDAY",
            weekday: day,
            repeatEveryNWeeks: parseInt(repeated.value),
            startWeek: 0,
            anyWeekday,
          };
        });
      }

      const body = {
        organizationID: organizationId,
        externalID,
        color,
        name,
        description,
        timeSpan,
        duration: durationToSend,
        prioritized,
        conditions: conditionsToSend,
      };

      if (isAddingNew) {
        callCreateEvenType(body);
      } else {
        callUpdateEventType(selectedEventType!.id!, body);
      }
    },
  });

  console.log("ERRORS: ", errors);

  const debounceUpdateEventType = useCallback(debounce(submitForm, 500), []); // eslint-disable-line react-hooks/exhaustive-deps

  const renderEditEventType = (): JSX.Element => (
    <>
      <div className="flex w-full flex-col self-start mt-4 ">
        <div className="flex flex-1 gap-10">
          <Input
            id="name"
            value={values.name}
            placeholder={translate("enterEventTypeName")}
            labelAbove={translate("name")}
            type="text"
            autoCapitalize="none"
            onChange={(e: any) => {
              setFieldValue("name", e.target.value);
              if (!isAddingNew) debounceUpdateEventType();
            }}
            onBlur={handleBlur("name")}
            error={errors.name || ""}
            touched={touched.name || false}
          />
          <Input
            id="externalID"
            placeholder="Enter the external id"
            value={String(values.externalID || "")}
            labelAbove={translate("eventID")}
            type="text"
            min={1}
            max={7}
            autoCapitalize="none"
            onChange={(e: any) => {
              setFieldValue("externalID", e.target.value);
              if (!isAddingNew) debounceUpdateEventType();
            }}
            onBlur={handleBlur("externalID")}
            error={String(errors.externalID || "")}
            touched={!!touched.externalID || false}
          />

          <ColorPicker
            id="color"
            labelAbove={translate("color")}
            pickerVisible={colorPickerVisible}
            onPreviewClicked={() => {
              setColorPickerVisible(!colorPickerVisible);
            }}
            color={values.color}
            error={errors.color || ""}
            touched={!!touched.color}
            onChangeComplete={(hex) => {
              setFieldValue("color", hex);
              if (!isAddingNew) debounceUpdateEventType();
              setColorPickerVisible(false);
            }}
          />
        </div>

        <div className="flex flex-1 gap-10 mt-4">
          <Input
            id="start"
            placeholder={translate("enterStartTime")}
            value={values.start}
            labelAbove={translate("startTime")}
            type="time"
            max={values.end}
            autoCapitalize="none"
            onChange={(e: any) => {
              setFieldValue("start", e.target.value);
              if (!isAddingNew) debounceUpdateEventType();
            }}
            onBlur={handleBlur("start")}
            error={errors.start || ""}
            touched={touched.start || false}
          />
          <Input
            id="end"
            placeholder={translate("enterEndTime")}
            value={values.end}
            labelAbove={translate("endTime")}
            type="time"
            min={values.start}
            autoCapitalize="none"
            onChange={(e: any) => {
              setFieldValue("end", e.target.value);
              if (!isAddingNew) debounceUpdateEventType();
            }}
            onBlur={handleBlur("end")}
            error={errors.end || ""}
            touched={touched.end || false}
          />

          <Input
            id="duration"
            defaultValue={calculateFieldDuration(values.start, values.end)}
            min={0}
            max={calculateFieldDuration(values.start, values.end)}
            placeholder={translate("enterDuration")}
            value={`${
              values.duration ??
              calculateFieldDuration(values.start, values.end)
            }`}
            labelAbove={translate("duration")}
            type="number"
            autoCapitalize="none"
            onChange={(e: any) => {
              setFieldValue("duration", e.target.value);
              if (!isAddingNew) debounceUpdateEventType();
            }}
            onBlur={handleBlur("duration")}
            error={errors.duration || ""}
            touched={touched.duration || false}
          />
        </div>

        {!values.anyWeekday && (
          <>
            <div className="mt-8 mb-8 flex flex-row w-[150px] self-center items-center  gap-2">
              <div
                onClick={() => {
                  setFieldValue("prioritized", !values.prioritized);
                  if (!isAddingNew) debounceUpdateEventType();
                }}
                className={`w-8 h-8 rounded-full flex justify-center items-center items hover:cursor-pointer ${
                  values.prioritized ? "bg-mint" : "bg-[#f3f3f3]"
                }`}
              >
                {values.prioritized ? <FaLock /> : <FaUnlock />}
              </div>
              <p className="bold-text">
                {values.prioritized ? "Prioritized" : "Not prioritized"}
              </p>
            </div>
            <DayPicker
              value={values.days}
              onChange={(days) => {
                setFieldValue("days", days);
                debounceUpdateEventType();
              }}
              label={translateUserPage("plannedEventOccuringFollowingDays")}
              error={String(errors.days || "")}
              touched={!!touched.days}
            />
          </>
        )}

        <div className="mb-4 mt-4" />

        <Select
          id="conditions"
          error={String(errors.conditions || "")}
          touched={!!touched.conditions}
          labelAbove={translate("conditions")}
          options={conditionOptions}
          value={values.conditions}
          selectType={"multiple"}
          onChange={(value: any) => {
            setFieldValue("conditions", value);
            if (!isAddingNew) debounceUpdateEventType();
          }}
        />

        <div className="mt-4" />

        <Switch
          label={translateUserPage("ifEventCanBeTakenAnytimeOfTheWeek")}
          value={values.anyWeekday || false}
          onClick={() => {
            setFieldValue("anyWeekday", !values.anyWeekday);
            if (!isAddingNew) debounceUpdateEventType();
          }}
        />
        <div className="mt-4" />

        <Select
          labelAbove={translate("repetition")}
          error={String(errors.repeated || "")}
          touched={!!touched.repeated}
          value={values.repeated as any}
          selectType="normal"
          options={repetitionOptions}
          onChange={(selection: any) => {
            setFieldValue("repeated", selection);
            if (!isAddingNew) debounceUpdateEventType();
          }}
        />

        {/* <div className="mt-4" />
        <Switch
          label="Om tjänsten kräver en specifik personal eller flera personal"
          value={requiresExtraStaff}
          onClick={() => setRequiresExtraStaff(!requiresExtraStaff)}
        />

        {requiresExtraStaff && <></>} */}
      </div>

      <IconButton
        hasDoubleCheck={!isAddingNew} //double check only if the action is DELETE
        doubleCheckLabel={translate("areYouSure")}
        confirming={confirmingDeletion}
        loadingText={translate("deleting")}
        isLoading={loadingDeleting}
        onConfirmClicked={() => {
          setLoadingDeleting(true);
          callDeleteEventType();
        }}
        onCancelClicked={() => {
          setConfirmingDeletion(false);
        }}
        continueLabel={translate("Yes")}
        cancelLabel={translate("No")}
        icon={isAddingNew ? "add" : "delete"}
        label={isAddingNew ? translate("addEventType") : translate("deleteEventType")}
        customStyle="mt-20 self-center"
        onClick={() => {
          if (isAddingNew) {
            handleSubmit();
          } else {
            setConfirmingDeletion(!confirmingDeletion);
          }
        }}
      />
    </>
  );

  return (
    <div className="flex flex-[4] gap-4">
      <div className="flex-[5]">
        <div className="card-container items-stretch">
          {renderSectionName(
            isAddingNew
              ? translate("addEventType")
              : `${
                  selectedEventType?.name
                    ? translate("eventType")
                    :  translate("eventType")
                }`
          )}
          {emptyEventTypes && !isAddingNew && (
            <p className="normal-text mt-2">
              {translate("thereAreNoEventTypesButYouCanCreateOne")}
            </p>
          )}
          {(!!selectedEventType || isAddingNew) && renderEditEventType()}
        </div>
      </div>
      <div className="flex-[2]">
        <div className="card-container mb-4">
          <IconButton
            type="submit"
            customStyle="w-full mb-4"
            icon="add"
            onClick={() => {
              setIsAddingNew(true);
              setSelectedEventType(null);
            }}
            label={translate("addEventType")}
          />
          {renderEventTypesList()}
        </div>
      </div>
    </div>
  );
};

export default EventTypes;
